import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

type DeleteProviderDialogProps = {
  handleDeleteProvider: any;
  name?: string;
  open: boolean;
  onClose: any;
};

const DeleteProviderDialog = ({
  handleDeleteProvider,
  name,
  onClose,
  open,
}: DeleteProviderDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Are you sure you want to delete {name ? `"${name}"` : "this provider"}?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>This action cannot be undone.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            onClose();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            handleDeleteProvider.mutate();
            onClose();
          }}
          color="primary"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteProviderDialog;
