import React from "react";
import { Link } from "react-router-dom";
import { Link as MUILink, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { downloadFile } from "../../helpers/downloadFile";

import { CellFormatters } from "../../helpers/TableHelpers";
import {
  amxPalette,
  Table,
  SelectColumnFilter,
  DateColumnFilter,
  TableActionsToolbar,
  HtmlTooltip,
  InfoIcon,
} from "@amx/component-library";

import { useAuth } from "@amx/common-frontend";
import { FundClassType } from "./types";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  link: {
    color: amxPalette.blue,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline !important",
    },
  },

  rowStyle: {
    "&:hover": {
      background: "white !important",
    },
  },

  tooltip: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
  },
}));

const FundTable = (props: { data: Array<FundClassType>; tab: string }) => {
  const { data, tab } = props;
  const classes = useStyles();

  const { axiosWithAuth } = useAuth();

  const commonCols = React.useMemo(() => {
    return [
      {
        Header: "Fund Name",
        accessor: "FULL_FUND_NAME",
        width: 300,
        minWidth: 300,
      },
      {
        Header: "Class",
        accessor: "SHARE_CLASS_NAME",
        width: 300,
        minWidth: 300,
        Cell: ({ value, row }: { value: any; row: any }) => {
          const fundName = value;
          const fundClassSk = row.original.FUND_CLASS_SK;
          const loiFund = row.original.loiFund;
          return loiFund === undefined ? (
            <Link
              className={classes.link}
              to={`/funds/detail/${fundClassSk}`}
              style={{
                minWidth: "250px",
                cursor: "pointer",
                color: amxPalette.blue,
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {fundName}
            </Link>
          ) : (
            <Typography
              style={{
                fontSize: "1vw",
                minWidth: "250px",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {fundName}
              <Tooltip title="This product is pending launch please contact client services (amx.service@carnegroup.com) for more details">
                <InfoOutlinedIcon
                  fontSize="small"
                  style={{ marginLeft: "0.260vw", fontSize: "0.700vw" }}
                />
              </Tooltip>
            </Typography>
          );
        },
      },
      {
        Header: "ISIN",
        accessor: "CLASS_ISIN",
        tooltip: (
          <HtmlTooltip title="International Securities Identification Number">
            <Box className={classes.tooltip}>
              <InfoIcon color="grey" fontSize="small" />
            </Box>
          </HtmlTooltip>
        ),
      },
    ];
  }, []);

  const navCols = React.useMemo(() => {
    return [
      {
        Header: "Strategy",
        accessor: "FUND_STRATEGY_NAME",
        filter: "includes",
        Filter: SelectColumnFilter,
        toolip: (
          <Tooltip title="name">
            <Typography>This is the name</Typography>
          </Tooltip>
        ),
      },
      {
        Header: "CCY",
        accessor: "CLASS_CURRENCY_CODE",

        filter: "includes",
        Filter: SelectColumnFilter,
        tooltip: (
          <HtmlTooltip title="Currency">
            <Box className={classes.tooltip}>
              <InfoIcon color="grey" fontSize="small" />
            </Box>
          </HtmlTooltip>
        ),
      },
      {
        Header: "Valuation Date",
        accessor: "FUND_VAL_DATE",
        filter: "dateBetween",
        Filter: DateColumnFilter,
        Cell: CellFormatters.asDate,
        toolip: (
          <Tooltip title="name">
            <Typography>This is the name</Typography>
          </Tooltip>
        ),
      },
      {
        Header: "NAV",
        accessor: (row: any) => {
          const value = JSON.parse(row.ClassPrice);
          return CellFormatters.asFloatNumber({
            value: value.NAV_PER_UNIT,
            decimalPlaces: 4,
          });
        },
        disableFilters: true,
        tooltip: (
          <HtmlTooltip title="Net Asset Value">
            <Box className={classes.tooltip}>
              <InfoIcon color="grey" fontSize="small" />
            </Box>
          </HtmlTooltip>
        ),
      },
      {
        Header: "Prev NAV",
        accessor: (row: any) => {
          const value = JSON.parse(row.ClassPrice);
          return value.PRIOR_NAV_PER_UNIT ?? "-";
        },
        disableFilters: true,
        Cell: ({ row }: { row: any }) => {
          const value = JSON.parse(row.original.ClassPrice);
          const classPriorNav = value.PRIOR_NAV_PER_UNIT;
          if (classPriorNav === null) return "-";
          const formattedNumber = new Intl.NumberFormat("en-EN", {
            currency: "GBP",
            style: "decimal",
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          }).format(classPriorNav);

          const fundClassSk = row.original.fundClassSk;
          const fundSk = row.original.fundSk;
          const url = "/fundDetail/download/history";
          const fileName = "nav_history.csv";
          return (
            <MUILink
              className={classes.link}
              style={{ cursor: "pointer", textDecoration: "underlined" }}
              onClick={() => {
                if (axiosWithAuth === undefined) return;
                axiosWithAuth({
                  url,
                  method: "GET",
                  responseType: "blob",
                  params: {
                    fundSk,
                    fundClassSk,
                  },
                }).then((data: any) => {
                  downloadFile(data, fileName);
                });
              }}
            >
              {formattedNumber}
            </MUILink>
          );
        },
        tooltip: (
          <HtmlTooltip title="Previous Net Asset Value">
            <Box className={classes.tooltip}>
              <InfoIcon color="grey" fontSize="small" />
            </Box>
          </HtmlTooltip>
        ),
      },
      {
        Header: "Change (%)",
        accessor: (row: any) => {
          const value = JSON.parse(row.ClassPrice);
          return CellFormatters.asPercentString({
            value: value.NAV_CHANGE_PERCENTAGE,
          });
        },
        disableFilters: true,
      },
      {
        Header: "Frequency",
        accessor: "PRICE_FREQUENCY_NAME",
        filter: "includes",
        Filter: SelectColumnFilter,
        Cell: ({ value, row }: { value: any; row: any }) => {
          const priceFrequency = value;
          if (priceFrequency === null) return "-";
          const fundSk = row.original.fundSk;
          const url = "/fundDetail/download/calendar";
          const fileName = "calendar.csv";
          return (
            <MUILink
              className={classes.link}
              style={{ cursor: "pointer", textDecoration: "underlined" }}
              onClick={() => {
                if (axiosWithAuth === undefined) return;
                axiosWithAuth({
                  url,
                  method: "GET",
                  responseType: "blob",
                  params: {
                    fundSk,
                  },
                }).then((data: any) => {
                  downloadFile(data, fileName);
                });
              }}
            >
              {priceFrequency}
            </MUILink>
          );
        },
      },
      {
        Header: "Umbrella",
        accessor: "AMXPRODUCT_NAME",
        show: false,
      },
      {
        Header: "Sub-Fund Category",
        accessor: "SUB_FUND_CATEGORY",
        filter: "includes",
        Filter: SelectColumnFilter,
        show: false,
      },
      {
        Header: "Price Type",
        accessor: "NAV_TYPE_NAME",
        filter: "includes",
        Filter: SelectColumnFilter,
        show: false,
      },
    ];
  }, [axiosWithAuth, classes.link]);

  const performanceCols = React.useMemo(() => {
    return [
      {
        Header: "CCY",
        accessor: "CLASS_CURRENCY_CODE",
        filter: "includes",
        Filter: SelectColumnFilter,
        tooltip: (
          <HtmlTooltip title="Currency">
            <Box className={classes.tooltip}>
              <InfoIcon color="grey" fontSize="small" />
            </Box>
          </HtmlTooltip>
        ),
      },
      {
        Header: "Inception Date",
        accessor: "CLASS_LAUNCH_DATE",
        filter: "dateBetween",
        Filter: DateColumnFilter,
        Cell: CellFormatters.asDate,
      },
      {
        Header: "Valuation Date",
        accessor: "FUND_VAL_DATE",
        filter: "dateBetween",
        Filter: DateColumnFilter,
        Cell: CellFormatters.asDate,
      },
      {
        Header: "MTD (%)",
        accessor: (row: any) => {
          const value = JSON.parse(row.ClassPerformance);
          return CellFormatters.asPercentString({
            value: value.MTD_PL_PERCENTAGE,
          });
        },
        disableFilters: true,
        tooltip: (
          <HtmlTooltip title="Month to Date">
            <Box className={classes.tooltip}>
              <InfoIcon color="grey" fontSize="small" />
            </Box>
          </HtmlTooltip>
        ),
      },
      {
        Header: "QTD (%)",
        accessor: (row: any) => {
          const value = JSON.parse(row.ClassPerformance);
          return CellFormatters.asPercentString({
            value: value.QTD_PL_PERCENTAGE,
          });
        },
        disableFilters: true,
        tooltip: (
          <HtmlTooltip title="Quarter to Date">
            <Box className={classes.tooltip}>
              <InfoIcon color="grey" fontSize="small" />
            </Box>
          </HtmlTooltip>
        ),
      },
      {
        Header: "YTD (%)",
        accessor: (row: any) => {
          const value = JSON.parse(row.ClassPerformance);
          return CellFormatters.asPercentString({
            value: value.YTD_PL_PERCENTAGE,
          });
        },
        disableFilters: true,
        tooltip: (
          <HtmlTooltip title="Year to Date">
            <Box className={classes.tooltip}>
              <InfoIcon color="grey" fontSize="small" />
            </Box>
          </HtmlTooltip>
        ),
      },
      {
        Header: "ITD (%)",
        accessor: (row: any) => {
          const value = JSON.parse(row.ClassPerformance);
          return CellFormatters.asPercentString({
            value: value.ITD_PL_PERCENTAGE,
          });
        },
        disableFilters: true,
        tooltip: (
          <HtmlTooltip title="Inception to Date">
            <Box className={classes.tooltip}>
              <InfoIcon color="grey" fontSize="small" />
            </Box>
          </HtmlTooltip>
        ),
      },
      {
        Header: "Umbrella",
        accessor: "AMXPRODUCT_NAME",
        show: false,
      },
      {
        Header: "Sub-Fund Category",
        accessor: "SUB_FUND_CATEGORY",
        filter: "includes",
        Filter: SelectColumnFilter,
        show: false,
      },
      {
        Header: "Price Type",
        accessor: "NAV_TYPE_NAME",
        filter: "includes",
        Filter: SelectColumnFilter,
        show: false,
      },
    ];
  }, []);

  const fundPerformanceColumns = [...commonCols, ...performanceCols];
  const fundNavColumns = [...commonCols, ...navCols];

  return (
    <Table
      tableData={data}
      tableColumns={tab === "nav" ? fundNavColumns : fundPerformanceColumns}
      enableFilters
      enableSortBy
      enableResizeColumns
      enableDraggableColumns
      expandAllRowsByDefault
      groupBy={["FULL_FUND_NAME"]}
      TableActionsComponent={<TableActionsToolbar />}
    />
  );
};

export default FundTable;
