import React, { useEffect, useState, Suspense } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import {
  AppInsightsContext,
  ReactPlugin,
} from "@microsoft/applicationinsights-react-js";
import { Disclaimer } from "@amx/web-components";
import { setupAppInsights, useAuth } from "@amx/common-frontend";
import { Box, LinearProgress } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import { StylesProvider, createGenerateClassName } from "@mui/styles";

import {
  HEADER_HEIGHT,
  SIDEBAR_MIN_WIDTH,
  Header,
  Sidebar,
  ThemeProvider,
  amxPalette,
  Footer,
} from "@amx/component-library";

import { UserBehaviour, StatusPermissionsType } from "../common/types";
import { UserProfileType } from "./dashboard/types";
import Timeout from "../components/Modals/Timeout";
import { MyDocuments } from "./my-documents/MyDocuments";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";

import "./App.css";

import Error404 from "./Error404";
import ContactUs from "./contact-us/ContactUs";
import StandardDashboard from "./dashboard/Dashboard";
import AdvisorDashboard from "./dashboard/AdvisorDashboard";
import ManagerDashboard from "../components/Dashboard/ManagerDashboard";
import UserSelectDropdown from "./UserSelectDropdown";
import FundDetail from "./funds/detail/FundDetail";
import Funds from "./funds/Funds";
import Xchange from "./store/Xchange";

const BLANK_PERMISSIONS = /(^\s?$|undefined)/;

export type EnvTypes = "development" | "uat" | "production";

export const ViewContainer: SxProps<Theme> = {
  background: amxPalette.grey_02,
  display: "flex",
  minHeight: `Calc(100vh - ${HEADER_HEIGHT}px)`,
  paddingLeft: `${SIDEBAR_MIN_WIDTH}px`,
};

export const HeaderMiddleStyles: SxProps<Theme> = {
  display: "flex",
  flex: 1,
  justifyContent: "flex-end",
};

const generateClassName = createGenerateClassName({
  productionPrefix: "c",
});

const App = () => {
  const [user, setUser] = useState<UserProfileType>({});
  const [appInsightsReactPlugin, setAppInsightsReactPlugin] =
    useState<ReactPlugin>();
  const { authState, logout, axiosWithAuth } = useAuth();
  const history = useHistory();

  const [showTimeout, setShowTimeout] = useState(false);
  const [filteringFunds, setFilteringFunds] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState("<AMX GLOBAL USER>");
  const [loadingAccountsData, setLoadingAccountsData] = useState(false);
  // TODO: Add this back in when we have a new ESG provider.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showEsg, setShowEsg] = useState(false);
  const [disclaimerClosed, setDisclaimerClosed] = useState(
    sessionStorage.getItem(Disclaimer.storageKey) === "true"
  );

  const currenEnv = process.env.REACT_APP_ENVIRONMENT as EnvTypes;

  useEffect(() => {
    if (authState.appInsightsKey) {
      const { reactPlugin } = setupAppInsights(authState.appInsightsKey, {
        application: "AMX Connect",
      });
      setAppInsightsReactPlugin(reactPlugin);
    }
  }, [authState]);

  const handleInvestorSelected = (investorId: number, ids: string) => {
    setFilteringFunds(true);
    setUser({
      ...user,
      selectedAccountId:
        user.isGlobalReader && !investorId
          ? authState.user.extension_investors
          : investorId,
      selectedTpaIds: ids,
    });
  };

  history.listen((location: any) => {
    setFilteringFunds(
      /^\/dashboard\/(available|last-dealing)/.test(location.pathname)
    );
  });

  const warningTime = 6300000;
  let warnTimeout: any;

  const calculateUserType = (statusPermissions: StatusPermissionsType) => {
    if (statusPermissions["service-provider"]) {
      return UserBehaviour.serviceProvider;
    }

    if (
      statusPermissions["investor"] ||
      statusPermissions["global-reader"] ||
      statusPermissions["investor-manager"]
    ) {
      return UserBehaviour.investor;
    }

    if (statusPermissions["advisor"]) {
      return UserBehaviour.advisor;
    }

    if (statusPermissions["manager"]) {
      return UserBehaviour.manager;
    }

    if (statusPermissions["third-party"]) {
      return UserBehaviour.thirdParty;
    }

    if (statusPermissions["factsheet-qir"]) {
      return UserBehaviour.factsheetQir;
    }

    if (statusPermissions["operations"]) {
      return UserBehaviour.support;
    }

    return UserBehaviour.anonymous;
  };

  const getAccountsData = async (
    refresh: boolean = false,
    permissions: any
  ) => {
    setLoadingAccountsData(true);
    let accountsResponse = {
      investors: [],
      advisors: [],
      managers: [],
      thirdParties: [],
      factsheetQirs: [],
      investorUsers: [],
      managerUsers: [],
      serviceProviders: [],
    };

    if (
      axiosWithAuth !== undefined &&
      permissions &&
      permissions.indexOf("global-reader") !== -1
    ) {
      accountsResponse = await axiosWithAuth({
        url: `/admin/accounts${refresh ? "?refresh=true" : ""}`,
      });
    }

    setLoadingAccountsData(false);
    return accountsResponse;
  };

  const handleAccountsDataRefresh = async () => {
    const accountsResponse = await getAccountsData(true, authState.permissions);

    if (accountsResponse) {
      setUser({
        ...user,
        accounts: {
          investors: accountsResponse.investors,
          managers: accountsResponse.managers,
          investorUsers: accountsResponse.investorUsers,
          advisors: accountsResponse.advisors,
          managerUsers: accountsResponse.managerUsers,
          thirdParties: accountsResponse.thirdParties,
          factsheetQirs: accountsResponse.factsheetQirs,
          serviceProviders: accountsResponse.serviceProviders,
        },
      });
    }
  };

  useEffect(() => {
    if (authState.user) {
      const handlePermissions = async () => {
        const formattedPermissions: any = authState.permissions
          .filter((permission) => !BLANK_PERMISSIONS.test(permission))
          .reduce(
            (accumulated, permission) => ({
              ...accumulated,
              [permission]: true,
            }),
            {}
          );

        const permissionCount = Object.keys(formattedPermissions).length;

        if (permissionCount && user.accounts === undefined) {
          const globalReader = formattedPermissions["global-reader"];
          const accountsResponse = await getAccountsData(
            false,
            authState.permissions
          );
          const { advisor, manager } = formattedPermissions;
          const {
            extension_investors,
            extension_advisors,
            extension_managers,
            extension_thirdparties,
            extension_factsheetqirs,
          } = authState.user;

          setUser({
            isGlobalReader: globalReader,
            accounts: {
              investors: accountsResponse.investors,
              managers: accountsResponse.managers,
              investorUsers: accountsResponse.investorUsers,
              advisors: accountsResponse.advisors,
              managerUsers: accountsResponse.managerUsers,
              thirdParties: accountsResponse.thirdParties,
              factsheetQirs: accountsResponse.factsheetQirs,
              serviceProviders: accountsResponse.serviceProviders,
            },
            selectedAccountId:
              formattedPermissions.investor ||
              formattedPermissions["global-reader"] ||
              formattedPermissions["investor-manager"]
                ? extension_investors
                : extension_thirdparties,
            selectedTpaIds:
              !globalReader && advisor ? extension_advisors : undefined,
            selectedFundIds:
              (!globalReader && manager) ||
              formattedPermissions["investor-manager"]
                ? extension_managers
                : formattedPermissions["factsheet-qir"]
                ? extension_factsheetqirs
                : undefined,
            userType: calculateUserType(formattedPermissions),
            formattedPermissions,
          });
        } else {
          setUser({
            userType: UserBehaviour.anonymous,
            isGlobalReader: false,
          });
        }
      };
      handlePermissions();
    }

    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    if (warnTimeout !== undefined) {
      warnTimeout = setTimeout(() => setShowTimeout(true), warningTime);
    }

    const resetTimeout = () => {
      clearTimeout(warnTimeout);
      warnTimeout = setTimeout(() => setShowTimeout(true), warningTime);
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeout(warnTimeout);
      }
    };
  }, [authState, axiosWithAuth]);

  const { userType } = user;

  const renderDashboard = () => {
    if (userType === undefined) {
      return <LinearProgress />;
    }

    if (userType === UserBehaviour.thirdParty) return null;

    if (
      userType === UserBehaviour.manager ||
      userType === UserBehaviour.managerUser
    ) {
      return (
        <ManagerDashboard
          fundIds={user.selectedFundIds!}
          fundManagerSk={user.isGlobalReader ? user.selectedFundIds : undefined}
          userType={userType}
        />
      );
    }

    if (userType === UserBehaviour.advisor) {
      if (filteringFunds) {
        // TODO: Add this back in when we have a new ESG provider. i.e.: Change `esgReportStatus` back to "enabled"
        return (
          <StandardDashboard
            investorName={authState.user?.name}
            investorId={user.selectedAccountId}
            advisorTpaIds={user.selectedTpaIds}
            filteringFunds={filteringFunds}
            esgReportStatus={"disabled"}
          />
        );
      } else {
        return (
          <AdvisorDashboard
            profileType={userType ? userType : UserBehaviour.investor}
            onInvestorSelect={handleInvestorSelected}
            portfolioTpaIds={
              user.isGlobalReader
                ? user.selectedTpaIds
                : authState.user?.extension_advisors
            }
          />
        );
      }
    }
    // TODO: Add this back in when we have a new ESG provider. i.e.: Change `esgReportStatus` back to "enabled"
    return (
      <StandardDashboard
        investorName={authState.user?.name}
        investorId={user.selectedAccountId}
        esgReportStatus={"disabled"}
      />
    );
  };

  const renderedApp = (
    <div className="App">
      {authState.accessToken === "" ? (
        <LinearProgress />
      ) : (
        <>
          <Header
            user={{
              name: authState.user?.name,
              jobTitle: authState.user?.jobTitle,
            }}
            onLogout={logout}
          >
            <Box sx={HeaderMiddleStyles}>
              <UserSelectDropdown
                isGlobalUser={user?.isGlobalReader}
                accounts={user.accounts}
                selectedProfile={selectedProfile}
                loadingAccountsData={loadingAccountsData}
                refreshAccountsData={handleAccountsDataRefresh}
                permissions={authState.permissions}
                updateUser={(userState: Partial<UserProfileType>) => {
                  setUser((prev) => {
                    return {
                      ...prev,
                      ...userState,
                    };
                  });
                }}
                onProfileChange={(
                  accountType,
                  id,
                  selectedTpaIds,
                  selectedReaderProfile
                ) => {
                  if (
                    accountType === UserBehaviour.investor &&
                    user.isGlobalReader &&
                    !id
                  ) {
                    id = authState.user.extension_investors;
                  }

                  setUser({
                    ...user,
                    userType: accountType,
                    selectedAccountId: id,
                    selectedTpaIds,
                    selectedFundIds: selectedTpaIds,
                  });

                  setSelectedProfile(
                    selectedReaderProfile || "<AMX GLOBAL USER>"
                  );
                }}
              />
            </Box>
          </Header>
          <Box sx={ViewContainer}>
            <Sidebar
              permissions={authState.permissions}
              currentEnv={currenEnv}
              currentApp="connect"
            />
            <Switch>
              <Route
                path={[
                  "/",
                  "/dashboard/",
                  "/dashboard/available",
                  "/dashboard/last-dealing",
                ]}
                exact={true}
              >
                {renderDashboard()}
              </Route>
              <Route path="/funds/detail/">
                <FundDetail
                  esgReportStatus={showEsg ? "enabled" : "disabled"}
                  isAMXUser={
                    user.isGlobalReader !== undefined &&
                    user.isGlobalReader === true &&
                    (selectedProfile === "<AMX GLOBAL USER>" ||
                      selectedProfile === "i--<NO IDENTITY SELECTED>")
                  }
                />
              </Route>
              <Route path="/funds">
                <Funds showEsg={showEsg} />
              </Route>
              <Route path="/documents">
                <MyDocuments
                  userType={userType}
                  associatedInvestorId={
                    user.isGlobalReader && userType === UserBehaviour.thirdParty
                      ? user.selectedTpaIds
                      : user.selectedAccountId
                  }
                  advisorIds={user.selectedTpaIds}
                  fundIds={user.selectedFundIds}
                  fundManagerSk={
                    user.isGlobalReader ? user.selectedFundIds : undefined
                  }
                  userGroups={authState.permissions}
                />
              </Route>
              {/* TODO: Tidy up/remove user.formattedPermissions as it's only used to prop this up until it's gone. */}
              {userType !== undefined &&
              !user.formattedPermissions?.["factsheet-qir"] &&
              !user.formattedPermissions?.["third-party"] ? (
                <Route path="/store">
                  <Xchange
                    showMyAMX={
                      userType === UserBehaviour.investor ||
                      userType === UserBehaviour.investorUser ||
                      userType === UserBehaviour.manager ||
                      userType === UserBehaviour.managerUser
                    }
                    user={user}
                  />
                </Route>
              ) : null}
              <Route path="/contact-us">
                <ContactUs />
              </Route>
              <Route path="/error">
                <Error404 />
              </Route>
            </Switch>
          </Box>
          <Disclaimer
            onClosed={() => {
              setDisclaimerClosed(true);
            }}
          />
          <Footer />
        </>
      )}
      {showTimeout && (
        <Timeout logout={logout} setShowTimeout={setShowTimeout} />
      )}
    </div>
  );

  // Render a loading screen while waiting for user permissions to load
  if (authState.permissions.length === 0) return <LoadingScreen />;

  // Check permission subset of user. Used to determine where to flow users to.
  const hasPermissionsSubset = (groups: string[], permissions: string[]) => {
    return groups.every((group) => {
      return group === "undefined" || permissions.indexOf(group) !== -1;
    });
  };

  // Check destination for redirect. Maps target to source: local => local, dev => dev etc.
  const getAMXZeroDestination = (currentLocation: string) => {
    if (currentLocation.includes("localhost")) {
      return "http://localhost:3006";
    }

    if (currentLocation.includes("dev")) {
      return "https://dev-amxzero.amxconnect.com";
    }

    if (currentLocation.includes("uat")) {
      return "https://uat-amxzero.amxconnect.com";
    }

    return "https://amxzero.amxconnect.com";
  };

  // Check if user has ONLY amxzero-user permissions. If so, flow them into zero.
  // Elsewise leave them in amxconnect.
  if (
    hasPermissionsSubset(authState.permissions, [
      "amxzero-consultant",
      "amxzero-consultant-admin",
      "amxzero-super-user",
      "amxzero-user",
      "amxzero-user-admin",
    ])
  ) {
    window.location.href = getAMXZeroDestination(window.location.href);
    return <LoadingScreen />;
  } else {
    return (
      <Suspense fallback={<div>Loading App...</div>}>
        <StylesProvider generateClassName={generateClassName}>
          <ThemeProvider>
            {appInsightsReactPlugin ? (
              <AppInsightsContext.Provider value={appInsightsReactPlugin}>
                {renderedApp}
              </AppInsightsContext.Provider>
            ) : (
              renderedApp
            )}
          </ThemeProvider>
        </StylesProvider>
      </Suspense>
    );
  }
};

export default App;
