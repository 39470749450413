import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

type AddProviderDialogProps = {
  handleAddProvider: any;
  open: boolean;
  onClose: any;
};

const AddProviderDialog = ({
  handleAddProvider,
  onClose,
  open,
}: AddProviderDialogProps) => {
  const [providerFormState, setProviderFormState] = useState<any>({
    interactionType: "info",
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add Provider</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please provide the following information for the new Provider
        </DialogContentText>
        <TextField
          autoFocus
          required
          variant="outlined"
          margin="dense"
          id="name"
          label="Provider Name"
          type="text"
          fullWidth
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setProviderFormState({
              ...providerFormState,
              itemName: event.target.value,
            });
          }}
        />
        <TextField
          autoFocus
          variant="outlined"
          margin="dense"
          id="name"
          label="Logo"
          type="text"
          fullWidth
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setProviderFormState({
              ...providerFormState,
              image: event.target.value,
            });
          }}
        />
        <RadioGroup
          row
          aria-label="position"
          name="position"
          value={providerFormState.interactionType}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setProviderFormState({
              ...providerFormState,
              interactionType: event.target.value,
            });
          }}
        >
          <FormControlLabel
            value="info"
            control={<Radio color="primary" />}
            label="This is part of the AMX package"
          />
          <FormControlLabel
            value="enquire"
            control={<Radio color="primary" />}
            label="Optional Service"
          />
          <FormControlLabel
            value="activate"
            control={<Radio color="primary" />}
            label="Integrated Service"
            disabled={true}
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            onClose();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            handleAddProvider.mutate(providerFormState);
            onClose();
          }}
          color="primary"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProviderDialog;
