import React, { useEffect, useState } from "react";
import { Grid, Box, LinearProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useAuth } from "@amx/common-frontend";

import myAmxConfig from "./my-amx-config.json";

import {
  StorePanel,
  StorePanelBasic,
  StorePanelSector,
  StorePanelFeature,
} from "../../components/StorePanel";
import { UserBehaviour } from "../../common/types";
import { PortfolioFundType, UserProfileType } from "../dashboard/types";
import { FundDataType } from "../funds/types";

const useStyles = makeStyles((theme) => ({
  base: {
    backgroundColor: "#fff",
    width: "500px",
    maxWidth: "500px",
  },

  baseContainer: {
    alignContent: "flex-start",
    justifyContent: "center",
    padding: "0 1em",
    [theme.breakpoints.up("md")]: {
      padding: "0 2em",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0 3em",
    },
  },

  topContainer: {
    padding: "2em 2vw 0",
  },

  panelWrap: {
    padding: "2em 2vw 0",
  },
  outerWrap: {
    display: "flex",
    padding: "0 0.5em 0 0",
    backgroundColor: "#fff",
  },
  tabPanelHeading: {
    marginBottom: "1em",
  },
  infoBox: {
    marginLeft: "-1.5em",

    "& em": {
      color: "#1B84CF",
      fontStyle: "normal",
    },
  },
  footnote: {
    fontSize: "0.8rem",
  },
  dialog: {
    position: "absolute",
    bottom: "0",
    margin: "0",
  },

  panelGroup: {
    backgroundColor: "#f7f7f7",
    margin: "0 0 1em 0",
    padding: "0.5em",

    "&:last-child": {
      margin: 0,
    },
  },

  panelGroupCol: {
    "&:last-child": {
      margin: 0,
    },
  },

  panelSectorContainer: {
    display: "flex",
  },
  panelGroupRight: {
    height: "100%",
  },

  panelSectorOuter: {
    height: "100%",
  },

  panelSectorInner: { alignItems: "stretch" },

  panelSectors: {
    alignSelf: "flex-end",
  },
}));

const MyAMX = (props: {
  categories: Array<any>;
  summary: { totalAUM: string; investments: string; ecosystemUsage: string };
  user?: UserProfileType;
}) => {
  const classes = useStyles();
  const { axiosWithAuth } = useAuth();
  const { summary, user } = props;
  const [storePanelConfig, setStorePanelConfig] = useState<any>();
  // const [serviceStats, setServiceStats] = useState<any>({});
  const [fundStats, setFundStats] = useState<any>({});
  const [totalFundCount, setTotalFundCount] = useState<FundDataType>();

  useEffect(() => {
    if (axiosWithAuth === undefined) return;

    const getFundCount = async () => {
      const response = await axiosWithAuth({
        url: "/get-dimfund-count",
      });

      if (response !== undefined) {
        setTotalFundCount(response?.count);
      }
    };

    getFundCount();
  }, [axiosWithAuth]);

  useEffect(() => {
    setStorePanelConfig(
      user?.userType === UserBehaviour.investor ||
        user?.userType === UserBehaviour.investorUser
        ? myAmxConfig.investor
        : myAmxConfig.assetManager
    );
  }, [user]);

  // const countServices = (categoryArr: Array<any>, key: string) => {
  //   return categoryArr.reduce(
  //     (count: number, category: any) => count + category[key],
  //     0
  //   );
  // };

  // useEffect(() => {
  //   setServiceStats({
  //     active: countServices(categories, "using"),
  //     available: countServices(categories, "available"),
  //   });
  // }, [categories]);

  useEffect(() => {
    const updateFundStats = async () => {
      if (!axiosWithAuth) return;

      let [invested] = summary.investments.split("/");
      let userInvested;

      if (
        user?.userType === UserBehaviour.manager ||
        user?.userType === UserBehaviour.managerUser
      ) {
        userInvested = user?.selectedFundIds?.split(",").length;
      } else if (
        user?.userType === UserBehaviour.investor ||
        user?.userType === UserBehaviour.investorUser
      ) {
        const investorPortfolio = await axiosWithAuth!({
          url: "/investorPortfolio",
          params: {
            navType: "All",
            investorId: user?.selectedAccountId,
          },
        });
        const fundManagers = new Set(
          investorPortfolio?.investments
            ?.filter(
              (record: PortfolioFundType) => record.systemCurrencyAum > 0
            )
            .map((fund: PortfolioFundType) => fund.fundManagers)
        );
        userInvested = fundManagers.size;
      }

      setFundStats({
        invested: userInvested || invested,
      });
    };

    updateFundStats();
  }, [axiosWithAuth, summary, user]);

  return (
    <Box data-tour="store-my-amx" className={classes.outerWrap}>
      {!storePanelConfig ? (
        <LinearProgress />
      ) : (
        <Grid container spacing={3} className={classes.panelGroupCol}>
          <Grid item xs={12} md={9}>
            {storePanelConfig.map((panel: any) => (
              <Grid
                container
                spacing={1}
                className={classes.panelGroup}
                key={panel.type}
              >
                <Grid item xs={12} className={classes.panelSectorContainer}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} className={classes.panelSectorInner}>
                      <StorePanelSector
                        type={panel.type}
                        logo={
                          panel.logo ? `/images/my-amx/${panel.logo}` : null
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  className={classes.panelSectors}
                >
                  <Grid item container spacing={1} alignItems="stretch" xs={9}>
                    {panel.columns.map((column: any, i: number) => (
                      <Grid item container xs={4} key={`${i}-${column.title}`}>
                        <StorePanelBasic
                          title={column.title}
                          link={column.link}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item container xs={3}>
                    {panel.thingsYouMightLike ? (
                      <StorePanelFeature
                        title={panel.thingsYouMightLike.title}
                        link={panel.thingsYouMightLike.link}
                      />
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12} md={3}>
            <Grid
              container
              spacing={1}
              alignContent="flex-start"
              className={classes.panelGroup + " " + classes.panelGroupRight}
            >
              <Grid item xs={12}>
                <StorePanel
                  type="funds"
                  status={
                    user?.userType === UserBehaviour.manager ||
                    user?.userType === UserBehaviour.managerUser
                      ? "ACTIVE"
                      : "INVESTED"
                  }
                  amount={fundStats.invested}
                  available={
                    user?.userType === UserBehaviour.manager ||
                    user?.userType === UserBehaviour.managerUser
                      ? null
                      : totalFundCount
                  }
                  themeColor="#CD9057"
                  actionLabel="AVAILABLE"
                  url="funds"
                />
              </Grid>

              {/* <Grid item xs={12}>
                <StorePanel
                  type="services"
                  status="ACTIVE"
                  amount={serviceStats.active}
                  available={serviceStats.available}
                  themeColor="#6590B3"
                  actionLabel="AVAILABLE"
                  url="apps-and-services"
                />
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default MyAMX;
