import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { SxProps, Theme } from "@mui/system";
import { useAuth } from "@amx/common-frontend";
import { View } from "@amx/component-library";

import { DocumentsMain, Documents } from "./Documents";
import { DocumentMetadataType, UserBehaviour } from "../../common/types";

import {
  amxPalette,
  ThingamabobOptions,
  ViewHeader,
} from "@amx/component-library";

const colourScheme = {
  backgroundColor: amxPalette.green,
  foregroundColor: amxPalette.white_100,
};
const colourSchemeInverse = {
  backgroundColor: amxPalette.white_100,
  foregroundColor: amxPalette.green,
};

const thingamabobsConfig = [
  { type: ThingamabobOptions.head, ...colourScheme },
  { type: ThingamabobOptions.chest, ...colourScheme },
  { type: ThingamabobOptions.legs, ...colourSchemeInverse },
  { type: ThingamabobOptions.bum, ...colourSchemeInverse },
];

export const MyDocuments = (props: {
  userType: UserBehaviour | undefined;
  associatedInvestorId: string | number | undefined;
  fundIds: string | undefined;
  fundManagerSk?: string;
  advisorIds: string | undefined;
  userGroups: string[];
}) => {
  const { axiosWithAuth } = useAuth();
  const {
    userType,
    associatedInvestorId,
    fundIds,
    fundManagerSk,
    advisorIds,
    userGroups,
  } = props;

  const [status, setStatus] = useState<string>("idle");

  const [docTypes, setDocTypes] = useState<string[]>([]);
  const [docs, setDocs] = useState<DocumentMetadataType[]>([]);

  const investorId =
    userType === UserBehaviour.investor ||
    userType === UserBehaviour.investorUser ||
    userType === UserBehaviour.thirdParty
      ? associatedInvestorId
      : userType === UserBehaviour.advisor
      ? advisorIds
      : fundIds;

  useEffect(() => {
    const getDocuments = async () => {
      if (axiosWithAuth === undefined) return;
      if (userType === undefined) return;

      setStatus("pending");

      const params: {
        userType: number;
        investorId: string | number | undefined;
        fundIds?: string;
        fundManagerSk?: string;
      } = {
        userType,
        investorId,
      };

      // Prefer Manager SK if available and the user is a manager (note: *not* managerUser).
      if (userType === UserBehaviour.manager && fundManagerSk) {
        params.fundManagerSk = fundManagerSk;
      } else {
        params.fundIds =
          userType === UserBehaviour.manager ||
          userType === UserBehaviour.managerUser ||
          userType === UserBehaviour.factsheetQir
            ? fundIds
            : undefined;
      }

      const documents: DocumentMetadataType[] = await axiosWithAuth({
        method: "POST",
        url: "/documents",
        data: params,
      });

      // Filter unique doc types
      const filteredDocTypes = [
        ...new Set(
          documents.map((doc: DocumentMetadataType) => doc.documentTypeName)
        ),
      ];

      // The preferred order of the sidebar doctypes.
      // Check if doctypes exist in the unique filteredDoctypes above
      const docTypesOrder = [
        "Factsheet",
        "Contract Notes",
        "QIR",
        "Investment Statement",
      ].filter((docType) => filteredDocTypes.includes(docType));

      const sortedFilteredDocTypes = [
        ...docTypesOrder,
        ...filteredDocTypes
          .filter((docType) => !docTypesOrder.includes(docType))
          .sort(),
      ];

      const filteredDocs = documents.map((doc: DocumentMetadataType) => ({
        ...doc,
        documentCreatedDatetime: format(
          new Date(doc.documentActiveFromDate),
          "dd-MMM-yyyy"
        ),
      }));

      setDocTypes(sortedFilteredDocTypes);
      setDocs(filteredDocs);

      setStatus("resolved");
    };
    getDocuments();
  }, [investorId, userType, axiosWithAuth, fundIds, fundManagerSk]);

  return (
    <View
      isLoading={status !== "resolved"}
      sx={{ paddingBottom: "100px" }}
      data-trackable-context="My Documents"
    >
      <ViewHeader
        title="My Documents"
        thingamabobsConfig={thingamabobsConfig}
        style={{ marginBottom: "40px", background: amxPalette.green }}
        height={138}
        smallText={true}
      />

      <Documents docs={docs} setDocs={setDocs} userGroups={userGroups}>
        <DocumentsMain
          investorId={investorId}
          userType={userType}
          fundManagerSk={fundManagerSk}
          docTypes={docTypes}
        />
      </Documents>
    </View>
  );
};
