import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useAuth } from "@amx/common-frontend";

import PieChart from "../../../components/Dashboard/PieChart";
import DashboardTableWrapper from "../../../components/Dashboard/DashboardTableWrapper";
import { PortfolioFundType } from "../types";

const LatestAvailable = (props: {
  handleFetchInvestorData: (nav?: string) => void;
  investorId?: number | string;
  advisorTpaIds?: string;
  showZeroBalance: boolean;
  esgReportStatus?: string; // "enabled" | "disabled"
  dataState: any[];
}) => {
  const { axiosWithAuth } = useAuth();

  const {
    dataState,
    handleFetchInvestorData,
    investorId,
    advisorTpaIds,
    showZeroBalance,
  } = props;
  const [data] = dataState;

  const [selectedFilter, setSelectedFilter] = useState({
    strategy: "",
    currency: "",
    manager: "",
  });

  useEffect(() => {
    const updateData = async () => {
      await handleFetchInvestorData();
    };

    updateData();

    // TODO: Implement cleanup function
    // https://reactjs.org/docs/hooks-effect.html
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axiosWithAuth, investorId, advisorTpaIds, showZeroBalance]);

  useEffect(() => {
    setSelectedFilter({
      strategy: "",
      currency: "",
      manager: "",
    });
  }, [data]);

  const handleStrategyFilter = (selectedFund: string) => {
    setSelectedFilter({
      ...selectedFilter,
      strategy: selectedFund === selectedFilter.strategy ? "" : selectedFund,
    });
  };

  const handleCurrencyFilter = (selectedCurrency: string) => {
    setSelectedFilter({
      ...selectedFilter,
      currency:
        selectedCurrency === selectedFilter.currency ? "" : selectedCurrency,
    });
  };

  const handleManagerFilter = (selectedManager: string) => {
    setSelectedFilter({
      ...selectedFilter,
      manager:
        selectedManager === selectedFilter.manager ? "" : selectedManager,
    });
  };

  return (
    <Grid container>
      <Grid item xs={4}>
        <PieChart
          title="Funds by Strategy"
          data={data}
          category="fundStrategy"
          onSegmentClick={handleStrategyFilter}
        />
      </Grid>
      <Grid item xs={4}>
        <PieChart
          title="Funds by Currency"
          data={data}
          category="fundCurrency"
          onSegmentClick={handleCurrencyFilter}
        />
      </Grid>
      <Grid item xs={4}>
        <PieChart
          title="Funds by Manager"
          data={data}
          category="fundManagers"
          onSegmentClick={handleManagerFilter}
        />
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 20 }}>
        <DashboardTableWrapper
          esgReportStatus={props.esgReportStatus}
          data={data
            .filter(
              (fund: PortfolioFundType) =>
                selectedFilter.strategy === "" ||
                selectedFilter.strategy === fund.fundStrategy
            )
            .filter(
              (fund: PortfolioFundType) =>
                selectedFilter.currency === "" ||
                selectedFilter.currency === fund.classCurrency
            )
            .filter(
              (fund: PortfolioFundType) =>
                selectedFilter.manager === "" ||
                fund.fundManagers.indexOf(selectedFilter.manager) !== -1
            )}
        />
      </Grid>
    </Grid>
  );
};

export default LatestAvailable;
