import React, { useMemo, useState } from "react";

import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Add, ExpandLess, ExpandMore, Loop } from "@mui/icons-material";

import { DropzoneDialog } from "material-ui-dropzone";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuth } from "@amx/common-frontend";

import { StoreImage } from "../../../common/types";
import { CopyToClipboard } from "../../../helpers/CopyToClipboard";
import AddCategoryDialog from "./AddCategoryDialog";
import Domain from "./Domain";

const useStyles = makeStyles((theme) => ({
  "@keyframes spin": {
    "0%": { transform: "rotate(360deg)" },
    "100%": { transform: "rotate(0deg)" },
  },
  spinner: {
    animation: "$spin 1s linear infinite",
  },
  addCategory: {
    border: "1px solid black",
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
    padding: theme.spacing(1),
  },
  imageTile: {
    width: 160,
    height: 160,
    border: "2px dotted black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
      display: "block",
    },
  },
  thumbnail: {
    marginLeft: 15,
    marginRight: 25,
    width: 50,
    height: 50,
    padding: 5,
    border: "1px solid black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
      display: "block",
    },
  },
  markdownContent: {
    "& ul": {
      listStyleType: "none",
      margin: 0,
      padding: 0,
    },
  },
}));

const Admin = ({
  serviceDomains,
  serviceDomainsIsFetching,
}: {
  serviceDomains: any;
  serviceDomainsIsFetching: boolean;
}) => {
  const classes = useStyles();
  const { axiosWithAuth } = useAuth();

  const queryClient = useQueryClient();

  const [open, setOpen] = useState({ images: false, mappings: false });
  const [imageMappingPath, setImageMappingPath] = useState<any>(undefined);
  const [dropZoneDialog, setDropZoneDialog] = useState<{
    files: File[];
    open: boolean;
  }>({
    files: [],
    open: false,
  });

  const [markdownCheatSheet, setMarkdownCheatSheet] = useState(false);
  const [addCategoryDialog, setAddCategoryDialog] = useState(false);

  const filteredServiceDomains = useMemo(() => {
    return serviceDomains.filter(
      ({ serviceDomainName }: { serviceDomainName: string }) =>
        ["Irish Fund Vehicles"].includes(serviceDomainName)
    );
  }, [serviceDomains]);

  const providers = useMemo(() => {
    return filteredServiceDomains.reduce((prev: any, curr: any) => {
      const allProviders = curr.subServiceDomains.reduce(
        (prev: any, curr: any) => {
          return [...prev, ...curr.providers];
        },
        []
      );

      return [...prev, ...allProviders];
    }, []);
  }, [filteredServiceDomains]);

  const uniqueProviders = useMemo(() => {
    return [
      ...new Set(providers.map((provider: any) => provider.itemName)),
    ].map((itemName) =>
      providers.find((provider: any) => provider.itemName === itemName)
    );
  }, [providers]);

  const serviceConfigDomains = useMemo(() => {
    return serviceDomains.filter(
      ({ serviceDomainName }: { serviceDomainName: string }) =>
        !["Irish Fund Vehicles"].includes(serviceDomainName)
    );
  }, [serviceDomains]);

  const { data: images, isLoading: imagesIsLoading } = useQuery(
    "images",
    () => {
      if (axiosWithAuth === undefined) return;
      return axiosWithAuth({
        url: "/store/images",
      }).then((data) => data);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000 * 60,
    }
  );

  const handleAddCategory = useMutation(
    (name: string) => {
      return axiosWithAuth!({
        url: `/xchange/category`,
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        data: { name },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("serviceDomains");
      },
    }
  );

  const handleImageUpload = useMutation(
    (file: File) => {
      let formData = new FormData();
      formData.append("fileData", file);
      return axiosWithAuth!({
        url: `/store/images`,
        method: "POST",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: formData,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("images");
      },
    }
  );

  const handleImageMappingUpdate = useMutation(
    (imageName: string) => {
      return axiosWithAuth!({
        url: `/store/images`,
        method: "PATCH",
        data: {
          path: imageMappingPath[imageName],
          name: imageName.trim(),
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("serviceDomains");
      },
    }
  );

  return (
    <>
      <Paper style={{ padding: 30 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Button
                style={{ padding: 0, textTransform: "unset" }}
                onClick={() => {
                  setOpen((prev) => ({
                    ...prev,
                    images: !prev.images,
                  }));
                }}
                endIcon={open.images ? <ExpandLess /> : <ExpandMore />}
              >
                <Typography variant="h5">Image Library</Typography>
              </Button>
            </Box>

            <Collapse mountOnEnter in={open.images} timeout="auto">
              <Button
                variant="outlined"
                startIcon={<Add />}
                onClick={() =>
                  setDropZoneDialog((prev) => ({ ...prev, open: true }))
                }
                style={{ marginLeft: 20, marginTop: 15 }}
              >
                Add Logo
              </Button>
              <DropzoneDialog
                open={dropZoneDialog.open}
                onSave={(files: File[]) => {
                  setDropZoneDialog({ files, open: false });
                  handleImageUpload.mutate(files[0]);
                }}
                filesLimit={1}
                acceptedFiles={[
                  "image/jpeg",
                  "image/png",
                  "image/bmp",
                  "image/svg",
                ]}
                showPreviews={true}
                maxFileSize={500000}
                onClose={() =>
                  setDropZoneDialog((prev) => ({
                    ...prev,
                    open: false,
                  }))
                }
              />
              <Box display="flex" flexWrap="wrap">
                {!imagesIsLoading &&
                  images.map((image: StoreImage, index: number) => {
                    return (
                      <Box
                        style={{ cursor: "pointer" }}
                        m={2}
                        key={index}
                        onClick={() => CopyToClipboard(image.path)}
                      >
                        <div className={classes.imageTile}>
                          <img src={image.path} alt="" />
                        </div>
                        <Typography
                          style={{
                            width: 150,
                            display: "block",
                            margin: "auto auto",
                            textAlign: "center",
                          }}
                          variant="caption"
                        >
                          {image.name}
                        </Typography>
                      </Box>
                    );
                  })}
              </Box>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{ padding: 0, textTransform: "unset" }}
              onClick={() => {
                setOpen((prev) => ({
                  ...prev,
                  mappings: !prev.mappings,
                }));
              }}
              endIcon={open.mappings ? <ExpandLess /> : <ExpandMore />}
            >
              <Typography variant="h5">
                Fund to Logo Mappings (Irish Fund Vehicles)
              </Typography>
            </Button>
            <Collapse mountOnEnter in={open.mappings} timeout="auto">
              <Grid container spacing={2} style={{ marginTop: 15 }}>
                {uniqueProviders.map((provider: any, index: number) => {
                  return (
                    <Grid key={index} item xs={6}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography>{provider.itemName}</Typography>
                        <Box display="flex" alignItems="center">
                          <TextField
                            variant="outlined"
                            size="small"
                            value={
                              imageMappingPath?.[provider.itemName] ??
                              (provider.image || "")
                            }
                            onBlur={() =>
                              imageMappingPath?.[provider.itemName] !==
                              undefined
                                ? handleImageMappingUpdate.mutate(
                                    provider.itemName
                                  )
                                : null
                            }
                            onChange={(event) => {
                              setImageMappingPath({
                                ...imageMappingPath,
                                [provider.itemName]: event.target.value,
                              });
                            }}
                          />
                          <div
                            className={classes.thumbnail}
                            style={{
                              visibility:
                                provider.image === null ? "hidden" : "unset",
                            }}
                          >
                            <img src={provider.image} alt="" />
                          </div>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Service Configuration
            </Typography>
            <Grid item xs={12} className={classes.addCategory}>
              <Button
                variant="outlined"
                size="small"
                startIcon={<Add />}
                disabled={handleAddCategory.isLoading}
                onClick={() => setAddCategoryDialog(true)}
              >
                {handleAddCategory.isLoading ? (
                  <Loop className={classes.spinner} />
                ) : (
                  "Add Category"
                )}
              </Button>
            </Grid>
            <Grid container spacing={4}>
              {serviceConfigDomains.map((domain: any) => {
                return (
                  <Domain
                    key={domain._id}
                    setMarkdownCheatSheet={setMarkdownCheatSheet}
                    domain={domain}
                    serviceDomainsIsFetching={serviceDomainsIsFetching}
                  />
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <AddCategoryDialog
        handleAddCategory={handleAddCategory}
        onClose={() => {
          setAddCategoryDialog(false);
        }}
        open={addCategoryDialog}
      />
      <Dialog maxWidth="md" fullWidth open={markdownCheatSheet}>
        <DialogTitle>Markdown Cheatsheet</DialogTitle>
        <DialogContent className={classes.markdownContent}>
          <Typography>Headers</Typography>
          <Divider />
          <ul>
            <li># H1</li>
            <li>## H2</li>
            <li>### H3</li>
            <li>#### H4</li>
            <li>##### H5</li>
            <li>###### H6</li>
          </ul>
          <br />
          <Typography>Emphasis</Typography>
          <Divider />
          <ul>
            <li>Emphasis, aka italics, with *asterisks* or _underscores_.</li>
            <li>
              Strong emphasis, aka bold, with **asterisks** or __underscores__.
            </li>
            <li>Combined emphasis with **asterisks and _underscores_**.</li>
            <li>Strikethrough uses two tildes. ~~Scratch this.~~</li>
          </ul>
          <br />
          <Typography>Lists</Typography>
          <Divider />
          <ul>
            <li>1. First ordered list item</li>
            <li>2. Another item</li>
            <li>* Unordered list can use asterisk</li>
          </ul>
          <br />
          <Typography>Links</Typography>
          <Divider />
          <ul>
            <li>"[I'm an inline-style link](https://www.google.com)"</li>
            <li>
              [I'm an inline-style link with title](https://www.google.com
              "Google's Homepage")
            </li>
          </ul>
          <br />
          <Typography>Images</Typography>
          <Divider />
          <ul>
            <li>
              ![alt
              text](https://github.com/adam-p/markdown-here/raw/master/src/common/images/icon48.png
              "Logo Title Text 1")
            </li>
          </ul>
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setMarkdownCheatSheet(false)}
            variant="outlined"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Admin;
