import React, { createContext, useState, useContext } from "react";

interface Store {
  cardId: any;
  cardItem: any;
}

type StoreContextType = {
  storeData: Store;
  setStoreData: (value: Store) => void;
  signedOut?: Boolean;
};

const StoreContext = createContext<StoreContextType>({} as StoreContextType);

export const StoreContextProvider = (props: any) => {
  const [storeData, setStoreData] = useState<Store>({
    cardId: null,
    cardItem: null,
  });

  const [signedOut] = useState<Boolean>(props.signedOut);

  return (
    <StoreContext.Provider value={{ storeData, setStoreData, signedOut }}>
      {props.children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext<StoreContextType>(StoreContext);
