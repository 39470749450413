import React from "react";
import { Alert, AlertTitle, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const NoFundInfoError = (props: { fundId: string }) => {
  const { fundId } = props;

  return (
    <Alert severity="warning">
      <AlertTitle>
        Fund Data will be available soon for fund with ID: {fundId}.
      </AlertTitle>
      <Typography variant="body1">
        In the meantime, you may be able to find the information you need on the{" "}
        <Link to={encodeURI("/documents")}>documents page</Link>.
      </Typography>
      <Typography variant="body1">
        Please contact Client Services (amx.service@carnegroup.com), if you need
        an update on when this fund information will be available.
      </Typography>
    </Alert>
  );
};

export default NoFundInfoError;
