export type FilterDictionary = {
  [strategy: string]: boolean;
};

export enum UserBehaviour {
  investor,
  advisor,
  manager,
  support,
  thirdParty,
  factsheetQir,
  anonymous,
  serviceProvider,
  investorManager,
  managerUser,
  investorUser,
}

export type StatusPermissionsKeyType =
  | "global-reader"
  | "operations"
  | "investor"
  | "manager"
  | "advisor"
  | "third-party"
  | "factsheet-qir"
  | "service-provider"
  | "investor-manager";

export type StatusPermissionsType = {
  [permission in StatusPermissionsKeyType]: boolean;
};

export type DocumentMetadataType = {
  documentSk: number;
  documentTypeName: string;
  documentName: string;
  documentVersion: number;
  documentCreatedDatetime: Date | string;
  documentActiveFromDate: Date | string;
  documentNameUi: string;
  portfolioManagerShortName: string;
  investorName: string;
  customDocumentName: string;
  visibleFlag: string;
};

export type FilteredDocumentType = {
  sk: number;
  version: number;
  name: string;
  category: string;
  createdDate: Date;
  filtered: boolean;
};

export type CategorySelectedDictionaryType = {
  [category: string]: boolean;
};

export type CategoryGroupType = {
  documents: FilteredDocumentType[];
  filtered: boolean;
};
export type CategoryDictionaryType = {
  [category: string]: CategoryGroupType;
};

export type CategorizedDocumentsType = {
  top3: FilteredDocumentType[];
  categories: CategoryDictionaryType;
};

export interface FundPerformanceChartData {
  id: string;
  fundStrategy?: string;
  expanded?: boolean;
  fundName?: string;
  fundClassSk?: number;
  classValue?: string;
  classCurrency?: string;
  classLaunchDate?: string;
  classMtdPl?: number;
  classQtdPl?: number;
  classYtdPl?: number;
  classItdPl?: number;
}

export interface FundNavChartData {
  id: string;
  fundClassSk?: number;
  fundStrategy?: string;
  expanded?: boolean;
  fundName?: string;
  classValue?: string;
  classCurrency?: string;
  fundValDate?: string;
  classNavPs?: number;
  classPriorNav?: number;
  percentChange?: number;
  priceFrequency?: string;
}

export type StoreImage = {
  _id: string;
  path: string;
  name: string;
};
