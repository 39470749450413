import React from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Typography, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

/**
 * AMX Connect AppPanel Component
 * @component
 */

export const StorePanelBasic = (props: any) => {
  const { link, title } = props;

  const useStyles = makeStyles((theme) => ({
    card: {
      boxShadow: "0 2px 2px -2px #555",
      position: "relative",
      padding: "0.8em 0.8em 3em",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      "&:hover": {
        background: "#f5f5f5",
      },

      textDecoration: "none",
    },

    type: {
      fontWeight: "bold",
      marginBottom: "0.2em",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    company: {
      color: "#919191",
    },

    cardContent: {
      overflow: "auto",
      position: "relative",
    },

    arrow: {
      color: "#8b8e94",
      position: "absolute",
      bottom: "0.5em",
      right: "0.5em",
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  return (
    <Card
      className={classes.card}
      variant="outlined"
      style={link ? { cursor: "pointer" } : {}}
      onClick={link ? () => history.push(link) : () => {}}
    >
      <Typography component="h4" variant="caption" className={classes.type}>
        {title}
      </Typography>

      {link ? (
        <React.Fragment>
          <Typography component="div" variant="caption">
            See all
          </Typography>
          <ArrowForwardIosIcon className={classes.arrow} />
        </React.Fragment>
      ) : null}
    </Card>
  );
};
