import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

type AddCategoryDialogProps = {
  handleAddCategory: any;
  open: boolean;
  onClose: any;
};

const AddCategoryDialog = ({
  handleAddCategory,
  onClose,
  open,
}: AddCategoryDialogProps) => {
  const [categoryName, setCategoryName] = useState<string>("");

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add Category Domain</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please provide the following information for the new Category Domain
        </DialogContentText>
        <TextField
          autoFocus
          required
          variant="outlined"
          margin="dense"
          id="name"
          label="Category Domain Name"
          type="text"
          fullWidth
          value={categoryName ?? ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCategoryName(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            onClose();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            handleAddCategory.mutate(categoryName);
            onClose();
          }}
          color="primary"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCategoryDialog;
