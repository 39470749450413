import React from "react";
import {
  Link as MUILink,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useAuth } from "@amx/common-frontend";
import axios from "axios";

export enum LinkType {
  download,
  investor,
  fund,
  none,
}

type KeyDictionary = {
  [keyItem: string]: string | number;
};

export interface Column {
  id: string;
  label: string;
  minWidth?: string | number | undefined;
  align?: "right" | "center";
  format?: (value: any) => string;
  link?: LinkType;
  linkKey?: string[];
  tooltip?: string;
  linkBaseUrl?: string;
  filename?: string;
}

const renderHeader = (headings: string[], metadata?: Column[]) => {
  return headings.map((columnName: string, index: number) => (
    <TableCell
      key={columnName}
      className="branded-header"
      style={
        metadata !== undefined &&
        metadata[index] &&
        metadata[index].minWidth !== undefined
          ? { minWidth: metadata[index].minWidth }
          : {}
      }
    >
      {columnName}

      {metadata !== undefined && metadata[index].tooltip !== undefined ? (
        <Tooltip title={metadata[index].tooltip!}>
          <InfoOutlinedIcon
            style={{ marginLeft: "0.260vw", fontSize: "0.700vw" }}
          />
        </Tooltip>
      ) : null}
    </TableCell>
  ));
};

const renderBodyRow = (
  row: any,
  headings: string[],
  index: number,
  columnMeta?: Column[]
) => {
  return (
    <TableRow key={`${index}-${row[headings[index]]}`}>
      {headings.map((item: any, index: number) => {
        const currentMeta =
          columnMeta === undefined ? undefined : columnMeta[index];
        const rawValue = row[headings[index]];
        let value = "";
        if (currentMeta !== undefined && currentMeta.link !== undefined) {
          const { authState } = useAuth();
          if (currentMeta.link === LinkType.download) {
            const handleDownload = async (url: string, filename: string) => {
              const params =
                currentMeta.linkKey === undefined
                  ? {}
                  : currentMeta.linkKey.reduce(
                      (previousKey: KeyDictionary, currentKey: string) => {
                        return {
                          ...previousKey,
                          [currentKey]: row[currentKey],
                        };
                      },
                      {}
                    );

              const response = await axios({
                url,
                method: "GET",
                responseType: "blob",
                headers: {
                  Authorization: `Bearer ${authState.accessToken}`,
                },
                params,
              });

              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(
                  new Blob([response.data]),
                  filename
                );
              } else {
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("target", "_blank");
                link.setAttribute("rel", "noopener noreferrer");
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
              }
            };

            return (
              <TableCell key={headings[index]}>
                <MUILink
                  style={{ cursor: "pointer" }}
                  onClick={async () => {
                    await handleDownload(
                      currentMeta.linkBaseUrl!,
                      currentMeta.filename || "file.csv"
                    );
                  }}
                >
                  {rawValue}
                </MUILink>
              </TableCell>
            );
          }
          if (!row.skipLink && currentMeta.link === LinkType.fund) {
            const key =
              currentMeta.linkKey === undefined
                ? rawValue
                : row[currentMeta.linkKey[0]];

            return (
              <TableCell key={headings[index]}>
                <Link to={`/funds/detail/${key}`}>{rawValue}</Link>
              </TableCell>
            );
          }
        }
        if (currentMeta && currentMeta.format) {
          const formatFunc = currentMeta.format as Function;
          value = formatFunc(rawValue);
        } else {
          value = rawValue;
        }
        return <TableCell key={headings[index]}>{value}</TableCell>;
      })}
    </TableRow>
  );
};

const GenericTable = (props: {
  data: any[];
  metadata?: Column[];
  className?: string;
}) => {
  const { data, metadata, className } = props;

  if (!data || data.length === 0) return null;

  let headings: string[] = [];
  let dataProps: string[];

  if (metadata && metadata.length > 0) {
    headings = metadata.map((columnDef: Column) => columnDef.label);
    dataProps = metadata.map((columnDef: Column) => columnDef.id);
  } else {
    headings = Object.keys(data[0]);
    dataProps = headings;
  }

  return (
    <Paper className={className}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>{renderHeader(headings, metadata)}</TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: any, index: number) =>
              renderBodyRow(row, dataProps, index, metadata)
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default GenericTable;
