import React, { useEffect } from "react";
import { useQuery } from "react-query";
import axios from "axios";

import { reSizeIframe } from "../helpers/iframeResizer";
import { sortByKey } from "../helpers/sortingHelpers";
import { useLocation } from "react-router-dom";
import { queryStringToDictionary } from "../helpers/QueryString";
import StoreContainer from "./store/StoreContainer";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  spinnerContainer: {
    height: "400px",
  },

  spinnerWrap: {
    margin: "auto",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    top: "100px",
  },
  spinner: {
    position: "relative",
    height: "100px",
    width: "100px",
    display: "inline-block",
    margin: "-50px auto 0",
  },
}));

const SignedOutStore = () => {
  const location = useLocation();
  const { fundId } = queryStringToDictionary(location.search);
  const fundSelected = fundId !== undefined;
  const classes = useStyles();

  const storeRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    reSizeIframe();
  }, []);

  const { data: serviceDomains, isFetching: serviceDomainsIsFetching } =
    useQuery(
      "serviceDomains",
      async () => {
        const response = await axios({
          url: "/api/publicXchange",
          method: "GET",
        });

        return response.data.domains
          .sort((a: any, b: any) => sortByKey(a, b, "serviceDomainName"))
          .map((domain: any) => ({
            ...domain,
            subServiceDomains: domain.subServiceDomains.sort((a: any, b: any) =>
              sortByKey(a, b, "subServiceDomainName")
            ),
            isExpanded: domain.isExpanded,
          }));
      },
      {
        refetchOnWindowFocus: false,
      }
    );

  if (serviceDomainsIsFetching) {
    return (
      <div className={classes.spinnerContainer}>
        <div className={classes.spinnerWrap}>
          <div className={classes.spinner}>
            <img src="/amx-loader.svg" alt="loading..." />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div ref={storeRef}>
      {serviceDomains?.length > 0 && (
        <>
          <StoreContainer
            serviceDomains={serviceDomains}
            fundSelected={fundSelected}
            storeRef={storeRef}
          />
        </>
      )}
    </div>
  );
};

export default SignedOutStore;
