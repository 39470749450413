import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

type AddSubDomainDialogProps = {
  handleAddSubDomain: any;
  open: boolean;
  onClose: any;
};

const AddSubDomainDialog = ({
  handleAddSubDomain,
  onClose,
  open,
}: AddSubDomainDialogProps) => {
  const [subDomainFormState, setSubDomainFormState] = useState<any>({});

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add Sub Service Domain</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please provide the following information for the new Sub Service
          Domain
        </DialogContentText>
        <TextField
          autoFocus
          required
          variant="outlined"
          margin="dense"
          id="name"
          label="Sub Service Name"
          type="text"
          fullWidth
          value={subDomainFormState.subServiceDomainName ?? ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSubDomainFormState({
              ...subDomainFormState,
              subServiceDomainName: event.target.value,
            });
          }}
        />
        <Divider style={{ margin: "20px 0" }} />
        <Typography>Add Provider</Typography>
        <TextField
          autoFocus
          required
          variant="outlined"
          margin="dense"
          id="name"
          label="Provider Name"
          type="text"
          fullWidth
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSubDomainFormState({
              ...subDomainFormState,
              itemName: event.target.value,
            });
          }}
        />
        <TextField
          autoFocus
          variant="outlined"
          margin="dense"
          id="name"
          label="Logo"
          type="text"
          fullWidth
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSubDomainFormState({
              ...subDomainFormState,
              image: event.target.value,
            });
          }}
        />
        <RadioGroup
          row
          aria-label="position"
          name="position"
          value={subDomainFormState.interactionType ?? "info"}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSubDomainFormState({
              ...subDomainFormState,
              interactionType: event.target.value,
            });
          }}
        >
          <FormControlLabel
            value="info"
            control={<Radio color="primary" />}
            label="This is part of the AMX package"
          />
          <FormControlLabel
            value="enquire"
            control={<Radio color="primary" />}
            label="Optional Service"
          />
          <FormControlLabel
            value="activate"
            control={<Radio color="primary" />}
            label="Integrated Service"
            disabled={true}
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            onClose();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            handleAddSubDomain.mutate(subDomainFormState);
            onClose();
          }}
          color="primary"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSubDomainDialog;
