import React, { useState } from "react";

import {
  Box,
  ListItemIcon,
  MenuItem,
  MenuList,
  Popover,
  TextField,
  IconButton,
  ButtonGroup,
} from "@mui/material";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useAuth } from "@amx/common-frontend";
import { ActionDotsIcon, DownloadIcon, GearIcon } from "@amx/component-library";


import { useDocuments } from "../../Documents";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import * as styles from "./ActionsCell.styles";

interface ActionsCellPropTypes {
  row?: any;
  downloadOnClick?: () => void;
  handleDocVisible?: () => void;
}

export const ActionsCell = ({
  row,
  downloadOnClick,
  handleDocVisible,
}: ActionsCellPropTypes) => {
  const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);

  const handleActionDotsOnClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { axiosWithAuth } = useAuth();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { selectedDocType, updateDocument, userGroups, documentDownloading } =
    useDocuments();
  const initialDocName =
    row.customDocumentName !== ""
      ? row.customDocumentName
      : selectedDocType === "All Documents" ||
        selectedDocType === "Recent Documents" ||
        selectedDocType === "Contract Notes" ||
        selectedDocType === "Investment Statement"
      ? row.documentName
      : row.documentNameUi;
  const [isDocEdit, setIsDocEdit] = useState(false);
  const [docName, setDocName] = useState(initialDocName);

  const handleEditDocConfirm = async (event: any, confirm: boolean) => {
    event.stopPropagation();

    if (confirm) {
      if (axiosWithAuth === undefined) return;
      const { documentSk, visibleFlag } = row;
      await axiosWithAuth({
        url: "/documents/update",
        method: "POST",
        data: {
          documentSk,
          visibleFlag,
          customDocName: docName,
        },
      });

      updateDocument({ ...row, customDocumentName: docName }, selectedDocType);

      setIsDocEdit(false);
    } else {
      setDocName(initialDocName);
      setIsDocEdit(false);
    }
  };

  return (
    <Box display="flex" alignItems="center" position="relative">
      {documentDownloading === row.documentSk ? (
        <GearIcon sx={styles.gearIcon} />
      ) : (
        <DownloadIcon
          sx={styles.downloadIcon}
          onClick={() => downloadOnClick && downloadOnClick()}
        />
      )}

      {userGroups.includes("documents-admin") ? (
        <ActionDotsIcon
          sx={{ ...styles.actionIcon, ...styles.actionDots }}
          aria-label="more"
          id="long-button"
          aria-controls="long-menu"
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleActionDotsOnClick}
        />
      ) : null}

      <Popover
        elevation={0}
        PaperProps={{
          sx: styles.popover,
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={styles.innerWrap}>
          <MenuList>
            <MenuItem onClick={() => handleDocVisible && handleDocVisible()}>
              <ListItemIcon sx={styles.listItemIcon}>
                <IconButton size="small">
                  {row.visibleFlag !== "T" ? (
                    <VisibilityOffIcon fontSize="small" />
                  ) : (
                    <VisibilityIcon fontSize="small" />
                  )}
                </IconButton>
              </ListItemIcon>
              {row.visibleFlag === "T" ? (
                <span>Hide Document</span>
              ) : (
                <span>Show Document</span>
              )}
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                event.stopPropagation();

                setIsDocEdit((prevState) => !prevState);
              }}
            >
              <ListItemIcon sx={styles.listItemIcon}>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              {isDocEdit ? (
                <ButtonGroup size="small">
                  <IconButton
                    size="small"
                    onClick={(event) => handleEditDocConfirm(event, true)}
                    disabled={docName ? false : true}
                  >
                    <DoneIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={(event) => handleEditDocConfirm(event, false)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </ButtonGroup>
              ) : (
                <span>Rename Document</span>
              )}
            </MenuItem>
            <MenuItem>
              {isDocEdit ? (
                <TextField
                  required
                  multiline
                  autoFocus
                  size="small"
                  InputProps={{
                    style: {
                      marginLeft: "10px",
                      marginBottom: "5px",
                      fontSize: "1rem",
                    },
                  }}
                  value={docName}
                  onChange={(event) => {
                    setDocName(event.target.value);
                  }}
                />
              ) : (
                <span>
                  {row.customDocumentName !== ""
                    ? row.customDocumentName
                    : selectedDocType === "All Documents" ||
                      selectedDocType === "Recent Documents" ||
                      selectedDocType === "Contract Notes" ||
                      selectedDocType === "Investment Statement"
                    ? row.documentName
                    : row.documentNameUi}
                </span>
              )}
            </MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </Box>
  );
};
