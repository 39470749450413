import { SxProps, Theme } from "@mui/system";
import { amxPalette } from "@amx/component-library";

export const Container: SxProps<Theme> = {
  display: "flex",
};

export const FlexColumn: SxProps<Theme> = {
  flexGrow: 1,
};

export const TitleText: SxProps<Theme> = {
  fontWeight: "bold",
  marginBottom: "16px",
};

export const OfficeLocation: SxProps<Theme> = {
  display: "flex",
  width: "100%",
  marginBottom: "26px",
};

export const OfficeText: SxProps<Theme> = {
  fontSize: "14px",
};

export const LinkContainer: SxProps<Theme> = {
  marginBottom: "14px",
};

export const LinkText: SxProps<Theme> = {
  fontWeight: 500,
  fontSize: "14px",
};
