import React, { useState } from "react";

import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { Add, Close, Delete, Done, Edit, ExpandMore } from "@mui/icons-material";

import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "@amx/common-frontend";

import Provider from "./Provider";
import AddProviderDialog from "./AddProvider";
import DeleteSubCategoryDialog from "./DeleteSubCategoryDialog";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const SubDomain = ({
  subServiceDomain,
  expanded,
  handleChange,
  domainId,
  serviceDomainsIsFetching,
  setMarkdownCheatSheet,
}: {
  subServiceDomain: any;
  expanded: boolean | string;
  handleChange: Function;
  domainId: string;
  serviceDomainsIsFetching: boolean;
  setMarkdownCheatSheet: Function;
}) => {
  const [addProviderDialog, setAddProviderDialog] = useState<boolean>(false);
  const [subServiceDomainName, setSubServiceDomainName] = useState(
    subServiceDomain.subServiceDomainName
  );
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const queryClient = useQueryClient();

  const { axiosWithAuth } = useAuth();
  const handleAddProvider = useMutation(
    (providerFormState: any) => {
      return axiosWithAuth!({
        url: `/store/${domainId}/${subServiceDomain._id}/provider`,
        method: "POST",
        data: {
          ...providerFormState,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("serviceDomains");
      },
    }
  );

  const handleDeleteSubCategory = useMutation(
    () => {
      return axiosWithAuth!({
        url: `/xchange/category/${domainId}/${subServiceDomain._id}`,
        method: "DELETE",
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("serviceDomains");
      },
    }
  );

  const handleSubServiceDomainUpdate = useMutation(
    () => {
      return axiosWithAuth!({
        url: `/store/${domainId}/${subServiceDomain._id}`,
        method: "PATCH",
        data: {
          subServiceDomainName,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("serviceDomains");
      },
    }
  );

  return (
    <>
      <Accordion
        expanded={expanded === subServiceDomain._id}
        onChange={handleChange(subServiceDomain._id)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Box display="flex" alignItems="center">
            {editing ? (
              <TextField
                required
                multiline
                autoFocus
                size="small"
                maxRows={2}
                InputProps={{
                  style: {
                    marginLeft: "10px",
                    marginBottom: "5px",
                    fontSize: "1rem",
                  },
                }}
                value={subServiceDomainName}
                onChange={(event) => {
                  event.stopPropagation();
                  setSubServiceDomainName(event.target.value);
                }}
                onClick={(event) => event.stopPropagation()}
              />
            ) : (
              <Typography variant="body1" style={{ marginRight: 5 }}>
                {subServiceDomainName}
              </Typography>
            )}
            {editing ? (
              <ButtonGroup size="small" style={{ marginLeft: 10 }}>
                <Button
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleSubServiceDomainUpdate.mutate();
                    setEditing(false);
                  }}
                >
                  <Done fontSize="small" />
                </Button>
                <Button
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    setSubServiceDomainName(
                      subServiceDomain.subServiceDomainName
                    );
                    setEditing(false);
                  }}
                >
                  <Close fontSize="small" />
                </Button>
              </ButtonGroup>
            ) : (
              <Edit
                fontSize="small"
                onClick={(event) => {
                  event.stopPropagation();
                  setEditing(true);
                }}
                style={{ cursor: "pointer" }}
              />
            )}
            <Delete
              fontSize="small"
              onClick={(event) => {
                event.stopPropagation();
                setDeleting(true);
              }}
              style={{ cursor: "pointer" }}
            />
            <Button
              style={{ marginLeft: 10 }}
              variant="outlined"
              size="small"
              startIcon={<Add />}
              onClick={(event) => {
                event.stopPropagation();
                setAddProviderDialog(true);
              }}
            >
              Add Provider
            </Button>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={4}>
            {subServiceDomain.providers.map((provider: any, index: number) => {
              return (
                <React.Fragment key={provider._id}>
                  <Provider
                    setMarkdownCheatSheet={setMarkdownCheatSheet}
                    serviceDomainsIsFetching={serviceDomainsIsFetching}
                    provider={provider}
                    subServiceDomainId={subServiceDomain._id}
                    domainId={domainId}
                  />
                  {index !== subServiceDomain.providers.length - 1 ? (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  ) : null}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <AddProviderDialog
        handleAddProvider={handleAddProvider}
        open={addProviderDialog}
        onClose={() => {
          setAddProviderDialog(false);
        }}
      />
      <DeleteSubCategoryDialog
        handleDeleteSubCategory={handleDeleteSubCategory}
        name={subServiceDomain.subServiceDomainName}
        open={deleting}
        onClose={() => setDeleting(false)}
      />
    </>
  );
};

export default SubDomain;
