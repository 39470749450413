import React, { useEffect } from "react";
import { Box, Fade, Grid, Tabs, Tab, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { sortByKey } from "../../helpers/sortingHelpers";

import StoreCard from "./StoreCard";

const useStyles = makeStyles((theme) => ({
  tabHeading: {
    fontSize: "clamp(12px, 1.042vw, 18px)",
    color: "#62ADDD",
    marginBottom: "0.1em",
  },

  tabSubHeading: {
    fontSize: "clamp(12px, 1.042vw, 18px)",
    color: "#aaa",
    marginBottom: "0.1em",
    "& span": {
      color: "#555",
    },
  },

  tab: {
    fontSize: "clamp(14px, 1.6vw, 18px)",
    textTransform: "none",
    maxWidth: "125px",
    textAlign: "left",
    opacity: 1,

    "&:hover": {
      backgroundColor: "#fafafa",
      opacity: 0.4,
    },

    "& > span": {
      alignItems: "flex-start",
    },
  },

  tabs: {
    "& .MuiTabs-scrollButtons > svg": {
      height: "1.5em",
      width: "auto",
    },
  },

  tabPanel: {
    backgroundColor: "#EBEEF2",
    marginBottom: "1.3em",
    border: "1px solid #D9DAE3",
    borderTop: "none",
    maxHeight: "400px",
    overflow: "auto",
  },

  cardGridWrap: {
    width: "auto",
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

type StoreTabsType = {
  category: any;
  onServiceClick: Function;
  categoryIndex: number;
  urlHashData?: any;
};

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const StoreTabs = (props: StoreTabsType) => {
  const { category, onServiceClick, categoryIndex, urlHashData } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!urlHashData) return;

    if (
      urlHashData.category === category.serviceDomainName &&
      urlHashData.tab
    ) {
      const index = category.subServiceDomains.findIndex(
        (ssDomain: any) => ssDomain.subServiceDomainName === urlHashData.tab
      );

      setValue(index);
    }
  }, [category, urlHashData]);

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        className={classes.tabPanel}
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Fade in={true} timeout={500}>
            <Box p={3}>{children}</Box>
          </Fade>
        )}
      </div>
    );
  };

  return (
    <>
      <Grid container data-category-name={category.serviceDomainName}>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            component="h3"
            className={classes.tabHeading}
          >
            {category.serviceDomainName === "Irish Fund Vehicles"
              ? "VEHICLES (AMX Irish regulated fund vehicles)"
              : category.serviceDomainName.toUpperCase()}
          </Typography>
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end">
          <Typography
            variant="h6"
            component="h3"
            className={classes.tabSubHeading}
          >
            Services & Products Available:
            <span>
              {" "}
              {category.subServiceDomains.reduce(
                (acc: any, val: any) => acc + val.providers.length,
                0
              )}
            </span>
          </Typography>
        </Grid>
      </Grid>

      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        classes={{
          root: classes.tabs,
        }}
      >
        {category.subServiceDomains.map((ssDomain: any, ssIndex: number) => {
          return (
            <Tab
              key={`${ssDomain}-${ssIndex}`}
              label={ssDomain.subServiceDomainName}
              data-trackable="Category Item"
              classes={{
                root: classes.tab,
              }}
              data-trackable-params-sub-service-domain={
                ssDomain.subServiceDomainName
              }
              data-trackable-params-category={category.serviceDomainName}
              {...a11yProps(ssIndex)}
            />
          );
        })}
      </Tabs>
      {category.subServiceDomains.map((ssDomain: any, ssIndex: number) => {
        return (
          <TabPanel
            key={`${ssDomain.subServiceDomainName}-${ssIndex}`}
            value={value}
            index={ssIndex}
          >
            <Grid container spacing={4}>
              {ssDomain.providers
                .sort((a: any, b: any) => sortByKey(a, b, "itemName"))
                .map((item: any, providerIndex: number) => (
                  <Grid
                    key={`${ssDomain.subServiceDomainName}-${providerIndex}`}
                    container
                    item
                    xs={false}
                    justifyContent="center"
                    sm={false}
                    lg={2}
                    className={classes.cardGridWrap}
                  >
                    <StoreCard
                      item={item}
                      ssDomain={ssDomain.subServiceDomainName}
                      category={category.serviceDomainName}
                      providerIndex={providerIndex}
                      ssIndex={ssIndex}
                      categoryIndex={categoryIndex}
                      onServiceClick={onServiceClick}
                    />
                  </Grid>
                ))}
            </Grid>
          </TabPanel>
        );
      })}
    </>
  );
};

export default StoreTabs;
