import React, { MouseEvent, useEffect, useState } from "react";
import styled from "styled-components";
import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import { AMXToggleButtonGroup } from "@amx/component-library";

const RibbonBox = styled(Box)`
  -ms-flex: 0 0 auto;
`;
const CurrencySelect = styled(Select)`
  font-size: 2.4vw;
  font-weight: 400;
  line-height: 1.235;

  &:before {
    display: none;
  }
`;
const TotalMarketValueHeader = styled(Typography)`
  display: inline-flex;
  align-items: center;
`;

const Ribbon = (props: {
  currencyList: string[];
  preferredCcy: string;
  marketValue: number;
  rowCount: number;
  showZeroBalance: boolean;
  onZeroBalanceChange: (value: boolean) => void;
  onPreferredCcyChange: (navType: string, preferredCcy?: string) => void;
}) => {
  const {
    currencyList,
    marketValue,
    preferredCcy,
    rowCount,
    onZeroBalanceChange,
    onPreferredCcyChange,
    showZeroBalance,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const [showZero, setShowZero] = useState<boolean>(showZeroBalance);
  const [selectedCcy, setSelectedCcy] = useState<string>(preferredCcy);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const selectedView =
    location.pathname === "/dashboard/last-dealing"
      ? "last-dealing"
      : "available";

  const handleTabChange = (
    event: MouseEvent<HTMLElement>,
    newValue: string
  ) => {
    if (newValue !== null) {
      history.push(
        newValue === "available"
          ? "/dashboard/available"
          : "/dashboard/last-dealing"
      );
    }
  };

  const handleShowZeroChange = () => {
    onZeroBalanceChange(!showZero);
    setShowZero(!showZero);
  };

  useEffect(() => {
    setSelectedCcy(preferredCcy);
  }, [preferredCcy]);

  useEffect(() => {
    setIsLoading(false);
  }, [marketValue]);

  return (
    <RibbonBox>
      <Grid container justifyContent="space-between">
        <Grid item>
          <AMXToggleButtonGroup
            muiProps={{
              exclusive: true,
              size: "large",
              color: "primary",
              value: selectedView,
              onChange: handleTabChange,
            }}
            toggles={["Available", "Last Dealing"]}
          />
        </Grid>
        <Grid>
          <h5 className="text-color-tertiary-grey no-margin text-thin-font">
            {"Total Market Value:"}
          </h5>
          <TotalMarketValueHeader
            variant="h4"
            className="no-margin text-thin-font"
          >
            <FormControl variant="standard">
              <CurrencySelect
                value={selectedCcy}
                onChange={(event) => {
                  setIsLoading(true);
                  setSelectedCcy(`${event.target.value}`);
                  onPreferredCcyChange(
                    selectedView === "available" ? "All" : "dealing",
                    `${event.target.value}`
                  );
                }}
              >
                {currencyList?.map((currency: string) => (
                  <MenuItem key={`ribbon-ccy-${currency}`} value={currency}>
                    {currency}
                  </MenuItem>
                ))}
              </CurrencySelect>
            </FormControl>
            {isLoading ? (
              <CircularProgress size={32} style={{ marginLeft: 8 }} />
            ) : (
              `${parseFloat(marketValue.toFixed(0)).toLocaleString()}*`
            )}
          </TotalMarketValueHeader>
          <h6 className="text-color-tertiary-grey no-margin text-thin-font">
            {"* Latest available Net Asset Value"}
          </h6>
          <h6 className="text-color-tertiary-grey no-margin text-thin-font">
            {"** Scoring based on available weighted scores"}
          </h6>
          <FormControlLabel
            control={<Checkbox />}
            checked={showZero}
            onChange={handleShowZeroChange}
            name="showZero"
            color="primary"
            label="Include Zero Balances"
          />
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom className="fund-count">
        {`My funds: ${rowCount}`}
      </Typography>
    </RibbonBox>
  );
};

export default Ribbon;
