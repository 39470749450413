import React, { useState } from "react";

import {
  FormControl,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  ListSubheader,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { SxProps, Theme } from "@mui/system";
import { useHistory } from "react-router-dom";
import {
  AccountsType,
  AccountType,
  UserProfileType,
} from "../root/dashboard/types";
import { UserBehaviour } from "../common/types";
import { amxPalette } from "@amx/component-library";

const UserSelectDropdownStyles: SxProps<Theme> = {
  display: "flex !important",
  flexDirection: "row !important",
  marginRight: "38px !important",

  "& .MuiOutlinedInput-root": {
    height: "32px",
  },

  "& .MuiButtonBase-root": {
    padding: 0,
  },

  "& .MuiTypography-root": {
    color: amxPalette.black_50,
    margin: "auto 8px",
  },

  "& .MuiSelect-icon, & .MuiSvgIcon-root": {
    color: amxPalette.black_30,
  },
};

const UserSelectDropdown = (props: {
  isGlobalUser?: boolean;
  accounts?: AccountsType;
  selectedProfile: any;
  loadingAccountsData: boolean;
  refreshAccountsData: Function;
  permissions?: Array<string>;
  updateUser?: (userState: Partial<UserProfileType>) => void;
  onProfileChange: (
    accountType: UserBehaviour,
    id?: number,
    tpaIds?: string,
    selectedValue?: string
  ) => void;
}) => {
  const [investorManager, setInvestorManager] = useState(0);

  const history = useHistory();

  const {
    isGlobalUser,
    accounts,
    selectedProfile,
    loadingAccountsData,
    permissions,
    refreshAccountsData,
    onProfileChange,
    updateUser,
  } = props;

  const handleAccountChange = (
    event: SelectChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const value: string = (event.target.value! as string) || "";
    const data = value.split("-");
    const id = data[1];

    switch (data[0]) {
      case "a":
        onProfileChange(UserBehaviour.advisor, undefined, id, value);
        break;
      case "m":
        onProfileChange(UserBehaviour.manager, undefined, id, value);
        break;
      case "t":
        onProfileChange(UserBehaviour.thirdParty, undefined, id, value);
        history.push("/documents");
        break;
      case "f":
        onProfileChange(UserBehaviour.factsheetQir, undefined, id, value);
        history.push("/documents");
        break;
      case "mu":
        onProfileChange(UserBehaviour.managerUser, undefined, id, value);
        break;
      case "iu":
        onProfileChange(
          UserBehaviour.investorUser,
          id ? parseInt(id) : undefined,
          undefined,
          value
        );
        break;
      case "sp":
        onProfileChange(
          UserBehaviour.serviceProvider,
          id ? parseInt(id) : undefined,
          undefined,
          value
        );
        break;
      default:
        onProfileChange(UserBehaviour.investor, id ? parseInt(id) : undefined, undefined, value);
    }
  };

  const renderDropDown = () => {
    return (
      <FormControl sx={UserSelectDropdownStyles}>
        <Typography variant="subtitle2">View as</Typography>
        <Select
          disabled={loadingAccountsData}
          value={selectedProfile}
          onChange={handleAccountChange}
        >
          <ListSubheader>Investors</ListSubheader>
          <MenuItem value="<AMX GLOBAL USER>">&lt;AMX GLOBAL USER&gt;</MenuItem>
          {accounts!.investors
            .sort((a: AccountType, b: AccountType) =>
              a.description.localeCompare(b.description)
            )
            .map((account: AccountType) => (
              <MenuItem
                key={`i-${account.id}-${account.description}`}
                value={`i-${account.id}-${account.description}`}
              >
                {account.description}
              </MenuItem>
            ))}

          <ListSubheader>Managers</ListSubheader>
          {accounts!.managers
            .sort((a: AccountType, b: AccountType) =>
              a.description.localeCompare(b.description)
            )
            .map((account: AccountType) => (
              <MenuItem
                key={`m-${account.id}-${account.description}`}
                value={`m-${account.id}-${account.description}`}
              >
                {account.description}
              </MenuItem>
            ))}

          <ListSubheader>Investor Users</ListSubheader>
          {accounts!.investorUsers.map((account: AccountType) => (
            <MenuItem
              key={`iu-${account.id}-${account.description}`}
              value={`iu-${account.id}-${account.description}`}
            >
              {account.description}
            </MenuItem>
          ))}

          <ListSubheader>Advisors</ListSubheader>
          {accounts!.advisors.map((account: AccountType) => (
            <MenuItem
              key={`a-${account.id}-${account.description}`}
              value={`a-${account.id}-${account.description}`}
            >
              {account.description}
            </MenuItem>
          ))}

          <ListSubheader>Manager Users</ListSubheader>
          {accounts!.managerUsers.map((account: AccountType) => (
            <MenuItem
              key={`mu-${account.id}-${account.description}`}
              value={`mu-${account.id}-${account.description}`}
            >
              {account.description}
            </MenuItem>
          ))}

          <ListSubheader>Third Parties</ListSubheader>
          {accounts!.thirdParties.map((account: AccountType) => (
            <MenuItem
              key={`t-${account.id}-${account.description}`}
              value={`t-${account.id}-${account.description}`}
            >
              {account.description}
            </MenuItem>
          ))}

          <ListSubheader>Factsheet QIRs</ListSubheader>
          {accounts!.factsheetQirs.map((account: AccountType) => (
            <MenuItem
              key={`f-${account.id}-${account.description}`}
              value={`f-${account.id}-${account.description}`}
            >
              {account.description}
            </MenuItem>
          ))}

          <ListSubheader>Service Providers</ListSubheader>
          {accounts!.serviceProviders.map((account: AccountType) => (
            <MenuItem key={`sp-${account.id}`} value={`sp-${account.id}`}>
              {account.description}
            </MenuItem>
          ))}
        </Select>
        {loadingAccountsData ? (
          <CircularProgress />
        ) : (
          <IconButton
            onClick={(event: any) => {
              refreshAccountsData();
            }}
            aria-label="refresh"
          >
            <RefreshIcon />
          </IconButton>
        )}
      </FormControl>
    );
  };

  // Move this up when done - here for testing
  const managerInvestorToggle = (e: any) => {
    if (e.target.value !== investorManager) {
      if (updateUser) {
        switch (e.target.value) {
          case 0:
            updateUser({ userType: UserBehaviour.investor });
            break;
          case 1:
            updateUser({ userType: UserBehaviour.manager });
            break;
          default:
            updateUser({ userType: UserBehaviour.investor });
        }
      }
      setInvestorManager(e.target.value);
    }
  };

  const renderInvestorManagerDropDown = () => {
    return (
      <FormControl sx={UserSelectDropdownStyles}>
        <Typography variant="subtitle2">User Type</Typography>
        <Select
          id="investor-manager-select"
          label="User Type"
          value={investorManager}
          onChange={managerInvestorToggle}
        >
          <MenuItem value={0}>Investor</MenuItem>
          <MenuItem value={1}>Manager</MenuItem>
        </Select>
      </FormControl>
    );
  };

  return (
    isGlobalUser ?
      renderDropDown()
      : permissions?.includes("investor-manager") ?
        renderInvestorManagerDropDown()
    : null
  );
};

export default UserSelectDropdown;
