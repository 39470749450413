import React from "react";
import { Link, Typography, Box } from "@mui/material";

import {
  amxPalette,
  ThingamabobOptions,
  View,
  ViewHeader,
} from "@amx/component-library";
import { CorkIcon, LondonIcon, NewYorkIcon } from "./Icons";

import * as styles from "./ContactUs.styles";

const colourScheme = {
  backgroundColor: amxPalette.pink,
  foregroundColor: amxPalette.white_100,
};

const colourSchemeInverse = {
  backgroundColor: amxPalette.white_100,
  foregroundColor: amxPalette.pink,
};

const thingamabobsConfig = [
  { type: ThingamabobOptions.head, ...colourScheme },
  { type: ThingamabobOptions.chest, ...colourScheme },
  { type: ThingamabobOptions.legs, ...colourSchemeInverse },
  { type: ThingamabobOptions.bum, ...colourSchemeInverse },
];

const ContactUs = () => {
  return (
    <View data-testid="contact-us">
      <ViewHeader
        title="Contact us"
        thingamabobsConfig={thingamabobsConfig}
        style={{ marginBottom: "40px", background: "#FF75A3" }}
        height={133}
        smallText={true}
      />

      {/* THREE EVENLY SPACED CONTAINERS*/}

      {/* COL ONE - CLIENT SERVICES */}
      <Box sx={styles.Container}>
        <Box sx={styles.FlexColumn}>
          <Typography sx={styles.TitleText}>Client Services</Typography>
          <Typography>
            If you have any questions, please contact us at
          </Typography>
          <Typography
            component={Link}
            href="mailto:amx.service@carnegroup.com"
            style={{ color: amxPalette.grey_60 }}
          >
            amx.service@carnegroup.com
          </Typography>
        </Box>

        {/* COL TWO - OFFICE LOCATIONS */}
        <Box sx={styles.FlexColumn}>
          <Typography sx={styles.TitleText}>Office Locations</Typography>
          <Box sx={styles.OfficeLocation}>
            <LondonIcon
              style={{
                width: "66px",
                height: "57px",
                marginRight: "30px",
              }}
            />
            <Box>
              <Typography sx={styles.OfficeText}>
                London <br />
                85 Gresham Street London, UK <br />
                EC2V 7NQ
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.OfficeLocation}>
            <CorkIcon
              style={{
                width: "66px",
                height: "57px",
                marginRight: "30px",
              }}
            />
            <Box>
              <Typography sx={styles.OfficeText}>
                Dublin <br />
                Iveagh Court, 2nd Floor Block E <br />
                Harcourt Rd Cork, Ireland, Dublin 2
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.OfficeLocation}>
            <NewYorkIcon
              style={{
                width: "66px",
                height: "57px",
                marginRight: "30px",
              }}
            />
            <Box>
              <Typography sx={styles.OfficeText}>
                New York <br />
                750 Lexington Ave New York, USA <br />
                NY 10022
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* COL THREE - USEFUL LINKS*/}
        <Box sx={{ flexGrow: 1 }}>
          <Typography sx={{ ...styles.TitleText, marginBottom: "30px" }}>
            Useful Links
          </Typography>
          <Box sx={styles.LinkContainer}>
            <Typography sx={styles.LinkText}>The AMX Story</Typography>
            <Link
              href="theamx.com/about/amx-story"
              sx={{ fontSize: "14px", color: amxPalette.grey_60 }}
              style={{ color: amxPalette.grey_60 }}
            >
              theamx.com/about/amx-story
            </Link>
          </Box>
          <Box sx={styles.LinkContainer}>
            <Typography sx={styles.LinkText}>Meet our team</Typography>
            <Link
              href="theamx.com/about/meet-our-team"
              sx={{ fontSize: "14px", color: amxPalette.grey_60 }}
              style={{ color: amxPalette.grey_60 }}
            >
              theamx.com/about/meet-our-team
            </Link>
          </Box>
          <Box sx={styles.LinkContainer}>
            <Typography sx={styles.LinkText}>Press Releases</Typography>
            <Link
              href="theamx.com/about/press-releases"
              sx={{ fontSize: "14px", color: amxPalette.grey_60 }}
              style={{ color: amxPalette.grey_60 }}
            >
              theamx.com/about/press-releases
            </Link>
          </Box>
        </Box>
      </Box>
    </View>
  );
};

export default ContactUs;
