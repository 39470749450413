import { euroFormat, toDate } from "./DateFormatter";

interface FormatDatePropTypes {
  locale?: "en-GB" | "en-US";
  date: Date;
}

interface FormatNumberPropTypes {
  locale?: "en-GB" | "en-US";
  currency?: "USD";
  number: number;
  notation?: "standard" | "scientific" | "engineering" | "compact";
  compactDisplay?: "short" | "long";
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

export const formatNumber = ({
  locale = "en-GB",
  currency = "USD",
  number,
  notation,
  compactDisplay,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
}: FormatNumberPropTypes) => {
  return typeof number === "number"
    ? new Intl.NumberFormat(locale, {
        currency,
        notation,
        compactDisplay,
        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits, // (causes 2500.99 to be printed as $2,501)
      }).format(number)
    : number;
};

export const formatDate = ({ date, locale = "en-GB" }: FormatDatePropTypes) => {
  return date instanceof Date
    ? new Intl.DateTimeFormat(locale).format(date)
    : date;
};

export const CellFormatters = {
  asEuroDate: ({ value }: { value: string }) =>
    value ? euroFormat(toDate(value)) : "",
  asDate: ({ value }: { value: Date | string }) =>
    formatDate({ date: new Date(value) }),
  asNumber: ({ value }: { value: number }) =>
    formatNumber({ number: value || 0 }),
  asFloatNumber: ({
    value,
    decimalPlaces,
  }: {
    value: number;
    decimalPlaces: number;
  }) =>
    formatNumber({
      number: value || 0,
      minimumFractionDigits: decimalPlaces || 0,
      maximumFractionDigits: decimalPlaces || 0,
    }),
  asRoundedNumber: ({ value }: { value: number }) =>
    formatNumber({
      number: value || 0,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
  asPercentNumber: ({ value }: { value: number }) =>
    formatNumber({ number: value * 100 }),
  asPercentString: ({ value }: { value: string }) =>
    value ? `${formatNumber({ number: parseFloat(value) })}%` : "",
};
