import React from "react";

import { Typography, Card, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

/**
 * AMX Connect AppPanel Component
 * @component
 */

export const StorePanelFeature = (props: any) => {
  const { link, title } = props;

  const useStyles = makeStyles((theme) => ({
    card: {
      boxShadow: "0 2px 2px -2px #555",
      padding: "0.8em 0.8em 3em",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      width: "100%",

      "&:hover $chip": {
        opacity: 1,
      },
    },

    wrap: {
      postion: "relative",
    },

    cardContent: {
      overflow: "auto",
      position: "relative",
    },

    title: {
      textTransform: "uppercase",
      color: "##919191",
    },

    chip: {
      bottom: "1em",
      fontSize: theme.typography.pxToRem(11),
      height: "26px",
      opacity: 0,
      position: "absolute",
      transition: "opacity 0.25s ease",
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  return (
    <Card
      className={classes.card}
      variant="outlined"
      data-tour="things-you-might-like"
      style={link ? { cursor: "pointer" } : {}}
      onClick={link ? () => history.push(link) : () => {}}
    >
      <Typography component="h4" variant="caption" className={classes.title}>
        THINGS YOU MIGHT LIKE
      </Typography>

      <Typography component="div" variant="subtitle2">
        {title}
      </Typography>
      {link ? (
        <Chip
          color="primary"
          label="Learn More"
          className={classes.chip}
        ></Chip>
      ) : null}
    </Card>
  );
};
