import React from "react";
import { Typography, Grid, Chip, Box } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";

import StoreCard from "./StoreCard";

const useStyles = makeStyles((theme) => ({
  tabPanelWrap: {
    marginTop: "1em",
    padding: "0 1em",
  },

  cardWrapper: {
    paddingBottom: "2.8em",
    marginBottom: "1em",
    borderBottom: "1px solid #cdcdcd",
  },

  cardInfo: {
    paddingBottom: "2em",
  },
}));

const StyleChip = withStyles({
  root: {
    backgroundColor: "#595E74",
    color: "#fff",
  },
})(Chip);

const StoreSeacrh = (props: any) => {
  const { onServiceClick, filteredResults } = props;

  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      {filteredResults.map((item: any, itemIndex: number) => {
        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={`filter-${itemIndex}-${item.provider}`}
          >
            <Box
              display="flex"
              justifyContent="center"
              className={classes.cardWrapper}
            >
              <StoreCard
                onServiceClick={onServiceClick}
                category={item.category}
                ssDomain={item.service}
                item={item.provider}
                categoryIndex={item.categoryIndex}
                ssIndex={item.serviceIndex}
                providerIndex={item.providerIndex}
              />
            </Box>

            <Grid container spacing={2} className={classes.cardInfo}>
              <Grid item>
                <Typography variant="subtitle1">{item.category}:</Typography>
              </Grid>

              <Grid item>
                <StyleChip label={item.service} />
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default StoreSeacrh;
