import React, { useEffect, useReducer } from "react";
import { PortfolioFundType } from "../../root/dashboard/types";
import { FilterDictionary } from "../../common/types";
import { createLastDealingData } from "../../helpers/FundData";
import { CellFormatters } from "../../helpers/TableHelpers";
import { FundLink } from "../../helpers/TableLinks";

import {
  Table,
  SelectColumnFilter,
  DateColumnFilter,
  TableActionsToolbar,
} from "@amx/component-library";

type StateType = {
  filters: FilterDictionary;
  filterText: string;
  rows: PortfolioFundTableData[];
  data: PortfolioFundType[];
};

type ActionType = {
  type: "toggleFilter" | "updateRows" | "initRows" | "changeFilterText";
  payload?: string | PortfolioFundType[];
};

export interface PortfolioFundTableData {
  id: string;
  fundId?: number;
  portfolioName?: string;
  fundCount?: number;
  fundSum?: number;
  fundStrategy?: string;
  expanded?: boolean;
  fundName?: string;
  valuationDate?: string;
  navPerShare?: number;
  fundNav?: number;
  fundCurrency?: string;
  classNav?: number;
  classUnits?: number;
  classCurrency?: string;
  investorTpaIdTotal?: number;
}

const initialState: StateType = {
  filters: {},
  filterText: "",
  rows: [],
  data: [],
};

const reducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
    case "initRows":
      let initialFilters: FilterDictionary = {};
      const rows = action.payload as PortfolioFundType[];
      rows.forEach((row) => {
        initialFilters[row.portfolioName!] = false;
      });
      return {
        ...state,
        filters: initialFilters,
        rows: createLastDealingData(
          action.payload as PortfolioFundType[],
          initialFilters
        ),
        data: action.payload as PortfolioFundType[],
      };
    case "updateRows":
      return {
        ...state,
        rows: createLastDealingData(
          action.payload as PortfolioFundType[],
          state.filters
        ),
      };
    case "toggleFilter":
      let clone = { ...state.filters };
      clone[action.payload as string] = !clone[action.payload as string];
      return { ...state, filters: clone };
    case "changeFilterText":
      return { ...state, filterText: action.payload as string };
  }
  return state;
};

const DashboardTableWrapper = (props: {
  esgReportStatus?: string; // "enabled" | "disabled"
  data: Array<PortfolioFundType>;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: "initRows", payload: props.data });
  }, [props.data]);

  const Cols = React.useMemo(() => {
    return [
      {
        Header: "Portfolio",
        accessor: "portfolioName",
        width: 200,
      },
      {
        Header: "Fund Name",
        accessor: "fundName",
        width: 200,
        Cell: ({ row, value }: { row: any; value: any }) =>
          FundLink(row.original.fundId, value),
      },
      {
        Header: "ISIN",
        accessor: "classIsin",
      },
      {
        Header: "NAV Type",
        accessor: "available",
        Cell: ({ value }: { value: any }) => (value ? "Estimate" : "Dealing"),
        getCellExportValue: ({ values }: { values: any }) => (values.available ? "Estimate" : "Dealing"),
        filter: "includes",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Valuation Date",
        accessor: "valuationDate",
        filter: "dateBetween",
        Filter: DateColumnFilter,
        Cell: CellFormatters.asEuroDate,
        getCellExportValue: ({ values }: { values: any }) => (CellFormatters.asEuroDate({ value: values["valuationDate"]}))
      },
      {
        Header: "NAV p/s",
        accessor: "navPerShare",
        disableFilters: true,
        Cell: ({ value }: { value: any }) =>
          CellFormatters.asFloatNumber({
            value: value,
            decimalPlaces: 4,
          }),
        getCellExportValue: ({ values }: { values: any }) =>
          CellFormatters.asFloatNumber({
            value: values["navPerShare"],
            decimalPlaces: 4,
          }),
      },
      {
        Header: "Fund NAV",
        accessor: "fundNav",
        aggregate: "sum",
        disableFilters: true,
        Cell: CellFormatters.asRoundedNumber,
        getCellExportValue: ({ values }: { values: any }) => (CellFormatters.asRoundedNumber({ value: values["fundNav"]}))
      },
      {
        Header: "CCY",
        accessor: "fundCurrency",
        filter: "includes",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Class NAV",
        accessor: "classNav",
        disableFilters: true,
        Cell: CellFormatters.asRoundedNumber,
        getCellExportValue: ({ values }: { values: any }) => (CellFormatters.asRoundedNumber({ value: values["classNav"]}))
      },
      {
        Header: "Class CCY",
        accessor: "classCurrency",
        filter: "includes",
        Filter: SelectColumnFilter,
      },

      {
        Header: "Units",
        accessor: "units",
        disableFilters: true,
        Cell: ({ value }: { value: any }) =>
          CellFormatters.asFloatNumber({
            value: value,
            decimalPlaces: 4,
          }),
        getCellExportValue: ({ values }: { values: any }) =>
          CellFormatters.asFloatNumber({
            value: values["units"],
            decimalPlaces: 4,
          }),
      },
      {
        Header: "% of Total",
        accessor: "percentageOfTotalAsset",
        disableFilters: true,
        Cell: CellFormatters.asPercentString,
        getCellExportValue: ({ values }: { values: any }) => (CellFormatters.asPercentString({ value: values["percentageOfTotalAsset"]}))
      },
    ];
  }, []);

  return (
    <React.Fragment>
      <Table
        tableData={props.data}
        tableColumns={Cols}
        enableFilters
        enableDraggableColumns
        enableResizeColumns
        enableSortBy
        expandAllRowsByDefault
        TableActionsComponent={<TableActionsToolbar />}
      />
    </React.Fragment>
  );
};

export default DashboardTableWrapper;
