import React from "react";
import { Typography, Card, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

/**
 * AMX Connect AppPanel Component
 * @component
 */

export const StorePanelSector = (props: any) => {
  const { type = "INVESTMENT OPERATIONS", logo } = props;

  const useStyles = makeStyles((theme) => ({
    card: {
      boxShadow: "0 2px 2px -2px #555",
      height: "100%",
      padding: "1em",
      display: "flex",
    },
    logo: {
      height: "30px",
      marginRight: "1em",
    },
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      <Card className={classes.card} variant="outlined">
        <Box display="flex" flexDirection="row" justifyContent="center">
          {logo ? <img alt="logo" src={logo} className={classes.logo} /> : null}

          <Typography component="h4" variant="subtitle1">
            {type}
          </Typography>
        </Box>
      </Card>
    </React.Fragment>
  );
};
