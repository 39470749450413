import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Paper, Tabs, Tab } from "@mui/material";

import {
  Link as ReactRouterLink,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import { useAuth } from "@amx/common-frontend";
import {
  amxPalette,
  ThingamabobOptions,
  View,
  ViewHeader,
} from "@amx/component-library";

import { queryStringToDictionary } from "../../helpers/QueryString";
import Admin from "./components/Admin";
import MyAMX from "./MyAMX";
import StoreContainer from "./StoreContainer";
import { sortByKey } from "../../helpers/sortingHelpers";
import { UserProfileType } from "../dashboard/types";

// CONFIG FOR THE BANNER
// FIRST SET THE COLOURS AND INVERSE COLOURS
const colourScheme = {
  backgroundColor: amxPalette.green,
  foregroundColor: amxPalette.white_100,
};

const colourSchemeInverse = {
  backgroundColor: amxPalette.white_100,
  foregroundColor: amxPalette.green,
};

// THEN ADD THE THINGAMABOBS
const thingamabobsConfig = [
  { type: ThingamabobOptions.head, ...colourScheme },
  { type: ThingamabobOptions.chest, ...colourScheme },
  { type: ThingamabobOptions.legs, ...colourSchemeInverse },
  { type: ThingamabobOptions.bum, ...colourSchemeInverse },
];

const Xchange = ({
  showMyAMX,
  user,
}: {
  showMyAMX: boolean;
  user?: UserProfileType;
}) => {
  const { authState, axiosWithAuth } = useAuth();
  const location = useLocation();
  const history = useHistory();
  // TODO: Add this back in when we have a new ESG provider.
  // const [preferences, setPreferences] = useState({
  //   allOwlSelected: false,
  //   fundLevelOwlSelected: false,
  // });
  const [summary, setSummary] = useState({
    ecosystemUsage: "0",
    investments: "0/0",
    totalAUM: "0",
  });

  const { section } = queryStringToDictionary(location.search);

  const title =
    section !== undefined
      ? section
          .split("-")
          .map(
            (word: string) => word.substr(0, 1).toUpperCase() + word.substr(1)
          )
          .join(" ")
      : "";

  useEffect(() => {
    if (
      !showMyAMX &&
      (location.pathname === "/store" || location.pathname === "/store/my-amx")
    ) {
      history.push("/store/services-and-products");
    }
  }, [location.pathname, user, showMyAMX, history]);

  const {
    data: serviceDomains,
    isLoading: serviceDomainsIsLoading,
    isFetching: serviceDomainsIsFetching,
  } = useQuery(
    "serviceDomains",
    async () => {
      const data = await axiosWithAuth!({
        url: "/xchange",
      });

      // TODO: Add this back in when we have a new ESG provider.
      // setPreferences(data.owlStatus);

      setSummary({
        ecosystemUsage: data.ecosystemUsage,
        investments: data.investments,
        totalAUM: data.totalAUM,
      });

      return data.domains
        .sort((a: any, b: any) => sortByKey(a, b, "serviceDomainName"))
        .map((domain: any) => ({
          ...domain,
          subServiceDomains: domain.subServiceDomains.sort((a: any, b: any) =>
            sortByKey(a, b, "subServiceDomainName")
          ),
          isExpanded:
            domain.serviceDomainName === title ? true : domain.isExpanded,
        }));
    },
    {
      onSuccess: (data: any) => {
        // TODO: Add this back in when we have a new ESG provider.
        // if (fund !== undefined) {
        //   const owlAnalytics = data.reduce((foundItem: any, domain: any) => {
        //     if (foundItem !== undefined) return foundItem;
        //     let found: any;
        //     domain.subServiceDomains.forEach((subServiceDomain: any) =>
        //       subServiceDomain.providers.forEach((provider: any) => {
        //         if (provider.interactionType === "activate") {
        //           found = provider;
        //         }
        //       })
        //     );
        //     return found;
        //   }, undefined);
        //   if (owlAnalytics === undefined) return;
        //   setPopUp((prev) => ({
        //     ...prev,
        //     bodyText: (
        //       <MarkdownPreview
        //         source={`${owlAnalytics.markdown}${
        //           fund !== undefined ? `\n### Fund\n${decodeURI(fund)}` : ""
        //         }`}
        //       />
        //     ),
        //   }));
        // }
      },
      refetchOnWindowFocus: false,
    }
  );

  const { fundId } = queryStringToDictionary(location.search);
  const fundSelected = fundId !== undefined;
  // TODO: Add this back in when we have a new ESG provider.
  // const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);
  // const [showAddOwlPopUp, setshowAddOwlPopUp] = useState(false);

  // TODO: Add this back in when we have a new ESG provider.
  // const handleDialogApprove = useMutation(
  //   (action: string) => {
  //     return axiosWithAuth!({
  //       url: "/preferences/services",
  //       method: "POST",
  //       data: {
  //         name:
  //           action === "addAll" || action === "removeAll"
  //             ? "owl-all"
  //             : `owl-${fundId}`,
  //         enabled: action === "addSingle" ? true : action === "addAll",
  //       },
  //     });
  //   },
  //   {
  //     onMutate: (action: string) => {
  //       queryClient.setQueryData("serviceDomains", popUp.proposedState);
  //       setPreferences({
  //         allOwlSelected:
  //           action === "addAll"
  //             ? true
  //             : action === "removeAll"
  //             ? false
  //             : preferences.allOwlSelected,
  //         fundLevelOwlSelected:
  //           action === "addSingle"
  //             ? true
  //             : action === "removeAll"
  //             ? false
  //             : preferences.fundLevelOwlSelected,
  //       });
  //       setShowConfirmPopUp(false);
  //       setPopUp({ ...popUp, show: false, proposedState: [] });

  //       if (action === "addAll") setshowAddOwlPopUp(true);

  //       if (returnUrl !== undefined) {
  //         history.push(returnUrl);
  //       }
  //     },
  //     onSuccess: (data: any, action: string) => {
  //       queryClient.invalidateQueries("serviceDomains");
  //     },
  //   }
  // );

  if (serviceDomainsIsLoading) {
    return (
      <View data-testid="contact-us" isLoading={true}>
        &nbsp;
      </View>
    );
  }

  return (
    <View data-testid="contact-us" isLoading={serviceDomains.length === 0}>
      {serviceDomains.length > 0 && (
        <Fragment>
          <ViewHeader
            title="Store"
            thingamabobsConfig={thingamabobsConfig}
            style={{ marginBottom: "40px", background: amxPalette.green }}
            height={138}
            smallText={true}
          />
          <Paper>
            <Tabs
              value={location.pathname}
              TabIndicatorProps={{ children: <div /> }}
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="secondary"
            >
              {showMyAMX && (
                <Tab
                  label="My AMX"
                  value={
                    location.pathname === "/store" ? "/store" : "/store/my-amx"
                  }
                  component={ReactRouterLink}
                  to="/store/my-amx"
                ></Tab>
              )}
              <Tab
                label="Services &amp; Products"
                value="/store/services-and-products"
                component={ReactRouterLink}
                to="/store/services-and-products"
              ></Tab>
              {authState.permissions && authState.permissions.indexOf("store-admin") !== -1 && (
                <Tab
                  label="Admin"
                  value="/store/admin"
                  component={ReactRouterLink}
                  to="/store/admin"
                ></Tab>
              )}
            </Tabs>

            <Switch>
              {showMyAMX ? (
                <Route
                  path={
                    location.pathname === "/store" ? "/store" : "/store/my-amx"
                  }
                  render={() => (
                    <MyAMX
                      categories={serviceDomains}
                      summary={summary}
                      user={user}
                    />
                  )}
                />
              ) : null}
              <Route
                path="/store/services-and-products"
                render={() => (
                  <StoreContainer
                    serviceDomains={serviceDomains}
                    fundSelected={fundSelected}
                    storeRef={null}
                  />
                )}
              />
              {authState.permissions && authState.permissions.indexOf("store-admin") !== -1 ? (
                <Route
                  path="/store/admin"
                  render={() => (
                    <Admin
                      serviceDomains={serviceDomains}
                      serviceDomainsIsFetching={serviceDomainsIsFetching}
                    />
                  )}
                />
              ) : null}
            </Switch>
          </Paper>
        </Fragment>
      )}
    </View>
  );
};

export default Xchange;
