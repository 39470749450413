import React, { useCallback } from "react";
import { Box } from "@material-ui/core";
import { useDocuments } from "../Documents";
import { ActionsCell } from "./ActionsCell";

import {
  Table,
  SelectColumnFilter,
  DateColumnFilter,
} from "@amx/component-library";

import { Tab, Tabs } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  docType: string;
}

export const DocumentsTableTabs = ({
  docTypes,
  hideClick,
  downloadDoc,
}: {
  docTypes: any;
  hideClick: (doc: any) => void;
  downloadDoc: Function;
}) => {
  const { allDocs, selectedDocType, updateSelectedDocType } = useDocuments();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    updateSelectedDocType(
      newValue === 0
        ? "Recent Documents"
        : newValue === 1
        ? "All Documents"
        : docTypes[newValue - 2]
    );
  };

  const cols = React.useMemo(() => {
    return [
      {
        Header: "Category",
        accessor: "documentTypeName",
        filter: "includes",
        width: 100,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Document Name",
        accessor: "documentName",
        width: 200,
        Cell: ({ row }: { row: any }) => {
          const rowDoc = row.original;
          return (
            <span>
              {rowDoc?.customDocumentName !== ""
                ? rowDoc.customDocumentName
                : selectedDocType === "All Documents" ||
                  selectedDocType === "Recent Documents" ||
                  selectedDocType === "Contract Notes" ||
                  selectedDocType === "Investment Statement"
                ? rowDoc.documentName
                : rowDoc.documentNameUi}
            </span>
          );
        },
      },
      {
        Header: "Portfolio Manager",
        accessor: "portfolioManagerShortName",
        width: 80,
        filter: "includes",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Investor Name",
        accessor: "investorName",
        filter: "includes",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Published",
        accessor: "documentActiveFromDate",
        width: 80,
        filter: "dateBetween",
        Filter: DateColumnFilter,
      },
      {
        id: "actions",
        width: 50,
        accessor: "actions",
        disableFilters: true,
        Cell: ({ row }: { row: any }) => {
          const doc = row.original;
          const fileExtension = doc.customDocumentName.includes(".")
            ? ""
            : `.${doc.documentName.split(".")[1]}`;

          const documentName = doc.customDocumentName
            ? `${doc.customDocumentName}${fileExtension}`
            : doc.documentName;
          return (
            <ActionsCell
              row={row.original}
              downloadOnClick={() => {
                downloadDoc(documentName, row.original.documentSk);
              }}
              handleDocVisible={() => hideClick(row.original)}
            />
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocType, allDocs]);

  const TabPanel = useCallback(
    (props: TabPanelProps) => {
      const { children, value, index, docType, ...other } = props;
      const docsTab = allDocs[docType];

      const hiddenCols = [];

      if (!allDocs[docType]?.some((o: any) => o.investorName !== "-UNKNOWN"))
        hiddenCols.push("investorName");

      const initialState = {
        sortBy: [
          {
            id: "documentActiveFromDate",
            desc: true,
          },
        ],
        hiddenColumns: hiddenCols,
      };

      return (
        <div role="tabpanel" hidden={value !== index} {...other}>
          {value === index && (
            <>
              <Table
                tableData={docsTab}
                tableColumns={cols}
                enableFilters
                enableSortBy
                enablePagination
                enableDraggableColumns
                initialState={initialState}
                stickyHeaders
              />
            </>
          )}
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allDocs, cols]
  );

  return (
    <>
      {allDocs["All Documents"].length ? (
        <Box
          sx={{
            marginTop: "10px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              marginBottom: "20px",
            }}
          >
            <Tabs
              onChange={handleChange}
              value={value}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label={"Recent"} data-trackable="Recent Documents" />
              <Tab label={"All"} data-trackable="All Documents" />
              {docTypes.map((docType: string, index: number) => {
                return (
                  <Tab
                    key={`documents-tab-${docType}`}
                    label={docType}
                    data-trackable="Doc Type"
                    data-trackable-params-doc-type={docType}
                  />
                );
              })}
            </Tabs>
          </Box>
          <TabPanel
            value={value}
            index={0}
            docType={"Recent Documents"}
          ></TabPanel>
          <TabPanel
            value={value}
            index={1}
            docType={"All Documents"}
          ></TabPanel>
          {docTypes.map((docType: string, index: number) => {
            return (
              <TabPanel
                key={`documents-tab-panel-${docType}`}
                value={value}
                index={index + 2}
                docType={docType}
              ></TabPanel>
            );
          })}
        </Box>
      ) : null}
    </>
  );
};
