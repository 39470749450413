import React, { useEffect, useReducer, useState, Fragment } from "react";
import {
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  Box,
} from "@mui/material";

import HighchartsReact from "highcharts-react-official";
import { Point } from "highcharts/highstock";
import {
  Table,
  chartPalette,
  View,
  amxPalette,
  SelectColumnFilter,
  DateColumnFilter,
  ThingamabobOptions,
  ViewHeader,
} from "@amx/component-library";

import { FundLink } from "../../helpers/TableLinks";
import { useAuth } from "@amx/common-frontend";
import { UserBehaviour } from "../../common/types";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useTelemetry } from "@amx/common-frontend";
import { CellFormatters } from "../../helpers/TableHelpers";

import * as styles from "./ManagerDashboard.styles";

type FundInfoType = {
  fundName: string;
  ccy: string;
  valDate: number;
  fundValue: number;
  fundAUMPreferredCCY: number;
};

type FundType = {
  fundInfo: FundInfoType;
  navs: any[];
  flows: any;
  comparison: any; // Type: Highcharts Options plus StockCharts
};

type StateType = {
  funds: FundType[];
  investors: any[];
  preferredCcy?: string;
  currencyList?: string[];
  totalAumPreferredCcy?: string;
};

type ActionType = {
  type: "setData";
  payload?: any;
};

const colourScheme = {
  backgroundColor: amxPalette.pink,
  foregroundColor: amxPalette.white_100,
};

const colourSchemeInverse = {
  backgroundColor: amxPalette.white_100,
  foregroundColor: amxPalette.pink,
};

const thingamabobsConfig = [
  { type: ThingamabobOptions.head, ...colourScheme },
  { type: ThingamabobOptions.chest, ...colourScheme },
  { type: ThingamabobOptions.legs, ...colourSchemeInverse },
  { type: ThingamabobOptions.bum, ...colourSchemeInverse },
];

const initialState: StateType = {
  funds: [
    {
      fundInfo: {
        fundName: "-",
        ccy: "-",
        valDate: 20200101,
        fundValue: 0,
        fundAUMPreferredCCY: 0,
      },
      navs: [],
      flows: {
        colors: chartPalette,
        credits: {
          enabled: false,
        },
        title: {
          text: "Flows (Monthly)",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          title: {
            text: "",
          },
        },
        series: [],
      },
      comparison: {
        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          title: {
            text: "",
          },
        },
        series: [],
      },
    },
  ],
  investors: [],
};

const parseComparisonData = (data: any) => {
  if (data === null || data.length === 0)
    return { name: "null", type: "line", data: [] };

  const fundChartSeries = Object.keys(data[0]).filter(
    (key: string) => key !== "date"
  );

  const comparisonData = data;
  return fundChartSeries.map((fundName: string, index: number) => {
    const data = comparisonData.map((item: any) => [
      new Date(item.date).getTime(),
      item[fundName],
    ]);
    return {
      name: fundName + (index === 0 ? " (index)" : ""),
      type: "line",
      data,
    };
  });
};

const reducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
    case "setData":
      return {
        ...state,
        funds: action.payload.funds.map((fund: FundType, index: number) => {
          return {
            fundInfo: fund.fundInfo,
            navs: fund.navs,
            flows: {
              colors: chartPalette,
              title: {
                text: `Inflows and Outflows for ${fund.fundInfo.fundName}`,
              },
              xAxis: {
                categories: fund.flows.map((item: any) => item.month),
              },
              tooltip: {
                formatter: function (): any {
                  const points = (this as any).points as Point[];
                  const x = (this as any).x as string;

                  return (
                    `<strong>${x}</strong><br/>` +
                    `Inflows: USD ${(
                      points[0].y as number
                    ).toLocaleString()}<br/>` +
                    `Outflows: USD ${(points[1].y as number).toLocaleString()}`
                  );
                },
                shared: true,
              },
              series: [
                {
                  name: "Inflows",
                  type: "column",
                  data: fund.flows.map((item: any) => item.subs),
                },
                {
                  name: "Outflows",
                  type: "column",
                  data: fund.flows.map((item: any) => item.reds),
                },
              ],
              credits: {
                enabled: false,
              },
            },
            comparison: {
              legend: {
                enabled: true,
              },
              tooltip: {
                valueDecimals: 2,
              },
              xAxis: {
                data: fund.comparison.data.map((fund: any) =>
                  new Date(fund.date).getTime()
                ),
              },
              series: parseComparisonData(fund.comparison.data),
            },
          };
        }),
        investors: action.payload.investors,
        currencyList: action.payload.currencyList,
        preferredCcy: action.payload.preferredCcy,
        totalAumPreferredCcy: action.payload.totalAumPreferredCcy
          ? parseFloat(
              action.payload.totalAumPreferredCcy.toFixed(2)
            ).toLocaleString()
          : "",
      };
  }
  return state;
};

const ManagerDashboard = (props: {
  fundIds: string;
  fundManagerSk?: string;
  userType: UserBehaviour;
}) => {
  const { axiosWithAuth } = useAuth();
  const appInsights = useAppInsightsContext();
  const { handleClickTracking } = useTelemetry(appInsights);
  const { fundIds, fundManagerSk, userType } = props;

  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(true);

  const navCols = React.useMemo(() => {
    return [
      {
        Header: "Fund Name",
        accessor: "fundName",
        Cell: ({ row, value }: { row: any; value: any }) =>
          FundLink(row.original.fundClassSk, value),
      },
      {
        Header: "Class",
        accessor: "classValueAugmented",
      },
      {
        Header: "ISIN",
        accessor: "fundIsin",
      },
      {
        Header: "Currency",
        accessor: "classCurrency",
        filter: "includes",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Val Date",
        accessor: "fundValDate",
        filter: "dateBetween",
        Filter: DateColumnFilter,
      },

      {
        Header: "NAV",
        accessor: "classNavps",
        disableFilters: true,
      },
      {
        Header: "Prev Val Date",
        accessor: "prevFundValDate",
        filter: "dateBetween",
        Filter: DateColumnFilter,
      },

      {
        Header: "Prev NAV",
        accessor: "classPriorNav",
        disableFilters: true,
      },
      {
        Header: "Change (%)",
        accessor: "percentageChange",
        disableFilters: true,
        Cell: ({ value }: { value: any }) =>
          CellFormatters.asFloatNumber({
            value: value,
            decimalPlaces: 4,
          }),
      },
    ];
  }, []);

  const investorCols = React.useMemo(() => {
    return [
      {
        Header: "Client",
        accessor: "client",
      },
      {
        Header: "Fund",
        accessor: "fund",
      },
      {
        Header: "Shareclass",
        accessor: "shareClass",
      },
      {
        Header: "Fund Val Date",
        accessor: "fundValDate",
        filter: "dateBetween",
        Filter: DateColumnFilter,
        Cell: CellFormatters.asEuroDate,
      },
      {
        Header: "CCY",
        accessor: "fundCCY",
        filter: "includes",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Class CCY",
        accessor: "classCCY",
        filter: "includes",
        Filter: SelectColumnFilter,
      },

      {
        Header: "Fund NAV",
        accessor: "fundAUM",
        disableFilters: true,
        Cell: CellFormatters.asRoundedNumber,
      },
      {
        Header: "Class NAV",
        accessor: "classAUM",
        disableFilters: true,
        Cell: CellFormatters.asRoundedNumber,
      },
    ];
  }, []);

  const fundInfoCols = React.useMemo(() => {
    return [
      {
        Header: "Fund Name",
        accessor: "fundName",
        disableFilters: true,
      },
      {
        Header: "Currency",
        accessor: "ccy",
        disableFilters: true,
      },
      {
        Header: "Date",
        accessor: "fundValDate",
        disableFilters: true,
        Cell: CellFormatters.asEuroDate,
      },
      {
        Header: "Fund Value",
        accessor: "fundValue",
        disableFilters: true,
        Cell: CellFormatters.asRoundedNumber,
      },
      {
        Header: "Fund Value Pref CCY",
        accessor: "fundAUMPreferredCCY",
        disableFilters: true,
        Cell: CellFormatters.asRoundedNumber,
      },
    ];
  }, []);

  const getInvestorData = async (preferredCcy?: string) => {
    if (axiosWithAuth === undefined) return;
    if (fundIds === undefined) return;

    const params = [];

    if (preferredCcy) {
      params.push(`preferredCcy=${preferredCcy}`);
    }

    // Prefer Manager SK if available.
    if (userType === UserBehaviour.manager && fundManagerSk) {
      params.push(`fundManagerSk=${fundManagerSk}`);
    } else {
      params.push(`fundIds=${fundIds}`);
    }

    const managerResponse = await axiosWithAuth({
      url: `/managerPortfolio?${params.join("&")}`,
    });

    if (managerResponse !== undefined) {
      setIsLoading(false);
      dispatch({
        type: "setData",
        payload: managerResponse,
      });
    } else {
      dispatch({
        type: "setData",
        payload: {
          currencyList: ["USD"],
          preferredCcy: "USD",
          funds: [
            {
              fundId: "--",
              fundSk: 0,
              fundName: "NO DATA",
              ccy: "--",
              valDate: "1970-01-01T00:00:00.000Z",
              fundValue: 0,
            },
          ],
          flows: {
            currency: "USD",
            data: [
              {
                date: "1970-01-01T00:00:00.000Z",
                value: 0,
                subs: 0,
                reds: 0,
              },
            ],
          },
          navs: [],
          comparison: {
            data: [
              {
                date: "1970-01-01T00:00:00.000Z",
                "No Data": 0,
              },
            ],
          },
          investors: [],
        },
      });
    }
  };

  useEffect(() => {
    getInvestorData();

    // TODO: Implement cleanup function
    // https://reactjs.org/docs/hooks-effect.html
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axiosWithAuth, fundIds]);

  const { currencyList, funds, investors, preferredCcy, totalAumPreferredCcy } =
    state;
  const [selectedCcy, setSelectedCcy] = useState<string>(preferredCcy || "USD");

  useEffect(() => {
    setSelectedCcy(preferredCcy || "USD");
  }, [preferredCcy]);

  return (
    <View isLoading={isLoading} onClick={handleClickTracking}>
      <Fragment>
        <ViewHeader
          subtitle="Dashboard"
          title="Manager"
          thingamabobsConfig={thingamabobsConfig}
          style={{ marginBottom: "40px", background: amxPalette.pink }}
          height={133}
          smallText={true}
        />

        {/* Funds Overview */}
        <Grid container spacing={4} marginBottom={3} alignItems="end">
          <Grid item xs={6}>
            <Typography
              sx={{
                fontSize: "24px",
              }}
            >
              My funds ({funds.length})
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                alignItems: "end",
                display: "flex",
                flexDirection: "column",
                position: "relative",
                top: "7px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: amxPalette.black_50,
                }}
              >
                Manager AUM
              </Typography>
              <Box sx={styles.AumContainer}>
                <FormControl variant="standard">
                  <Select
                    sx={styles.CurrencySelect}
                    value={selectedCcy}
                    onChange={(event) => {
                      setIsLoading(true);
                      setSelectedCcy(`${event.target.value}`);
                      getInvestorData(`${event.target.value}`);
                    }}
                  >
                    {currencyList?.map((currency: string) => (
                      <MenuItem
                        key={`preferred-ccy-${currency}`}
                        value={currency}
                      >
                        {currency}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {isLoading ? (
                  <CircularProgress size={32} style={{ marginLeft: 8 }} />
                ) : (
                  totalAumPreferredCcy
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Table
              tableData={funds.map((fund: FundType) => fund.fundInfo)}
              tableColumns={fundInfoCols}
              enableFilters
              enableDraggableColumns
              initialState={initialState}
            />
          </Grid>
        </Grid>

        {funds.map((fund: FundType) => (
          <Fragment key={fund.fundInfo.fundName}>
            <Grid container>
              <Grid item xs={12}>
                <Box sx={styles.FundHeaderContainer}>
                  <Typography variant="h5">{fund.fundInfo.fundName}</Typography>
                </Box>
              </Grid>
            </Grid>

            {/* Charts */}
            <Grid container spacing={4} marginBottom={3}>
              <Grid item xs={12}>
                <Box sx={styles.ChartContainer}>
                  <HighchartsReact options={fund.flows} />
                </Box>
              </Grid>
              {/* <Grid item xs={6}>
              <ChartContainer>
                <HighchartsReact
                  options={funds[0]..comparison}
                  highcharts={Highcharts}
                  constructorType="stockChart"
                />
              </ChartContainer>
            </Grid> */}
            </Grid>

            {/* Detailed Fund Classes */}
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Table
                  tableData={fund.navs}
                  tableColumns={navCols}
                  enableFilters
                  enableDraggableColumns
                  initialState={initialState}
                  enableSortBy
                />
              </Grid>
            </Grid>
          </Fragment>
        ))}

        <Grid container>
          <Grid item xs={12}>
            <Box sx={styles.FundHeaderContainer}>
              <Typography variant="h5">Investors</Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Investors Table */}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Table
              tableData={investors}
              tableColumns={investorCols}
              enableFilters
              enableDraggableColumns
              initialState={initialState}
              enableSortBy
            />
          </Grid>
        </Grid>
      </Fragment>
    </View>
  );
};

export default ManagerDashboard;
