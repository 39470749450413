import React from "react";
import AmxIcon from "./AmxIcon";
import "./LoadingScreen.css";

const LoadingScreen = () => {
  return (
    <div className="loadingScreenStyle">
      <div className="loadingScreenBoxStyle loadingScreenFadeIn">
        <AmxIcon style={{ opacity: 1 }} />
      </div>
    </div>
  );
};

export default LoadingScreen;
