export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const euroFormat = (dateIn: Date): string => {
  if (dateIn === null) return "00-00-0000";

  const date = typeof dateIn === "string" ? new Date(dateIn) : dateIn;
  let day = date.getDate();
  const dayStr = day < 10 ? "0" + day : day;
  const month = months[date.getMonth()];
  return `${dayStr}-${month}-${date.getFullYear()}`;
};

export const numberToDate = (numericalDate: Number): Date => {
  const dateString = `${numericalDate}`;

  if (dateString.length !== 8) return new Date();

  const year = dateString.substr(0, 4);
  const month = dateString.substr(4, 2);
  const day = dateString.substr(6, 2);

  return new Date(Date.parse(`${year}-${month}-${day}`));
};

export const toDate = (dateStr: string): Date => {
  let date = new Date("01 Jan 1970 00:00:00 UTC");
  date.setFullYear(parseInt(dateStr.substr(0, 4)));
  date.setMonth(parseInt(dateStr.substr(5, 2)) - 1);
  date.setDate(parseInt(dateStr.substr(8, 2)));
  return date;
};
