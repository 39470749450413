import React, { Fragment } from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { Options } from "highcharts/highstock";
import { amxPalette, chartPalette } from "@amx/component-library";

const useStyles = makeStyles((theme) => ({
  exposureGraph: {
    "& + &": {
      borderTop: `1px solid ${amxPalette.grey_100}`,
      marginTop: "16px",
      paddingTop: "16px",
    },
  },
}));

const convertDataToBarOptions = (
  data: any,
  section: string,
  titlePrefix: string
): Options => {
  const filteredData = data[section]
    ? data[section].sort((a: any, b: any) => {
        // Move any data points already grouped upstream as "other" to the end so they'll be lumped
        //  together with the rest of the data.
        if (a[section].toLowerCase() === "other") {
          return 1;
        }

        return b.fundPc - a.fundPc;
      })
    : [];

  let fundSeriesData: any[] = [];
  let benchmarkSeriesData: any[] = [];
  const NUM_DATAPOINTS = 10;

  // Only do the first 9 and then create an "Other" category that sums them up.
  filteredData.slice(0, NUM_DATAPOINTS - 1).forEach((category: any) => {
    fundSeriesData.push({
      name: category[section],
      value: parseFloat(category.fundPc),
    });
    benchmarkSeriesData.push({
      name: category[section],
      value: parseFloat(category.benchmarkPc),
    });
  });

  const otherData = filteredData.slice(NUM_DATAPOINTS - 1).reduce(
    (prev: any, current: any) => {
      return {
        fundPc: parseFloat(prev.fundPc) + parseFloat(current.fundPc),
        benchmarkPc:
          parseFloat(prev.benchmarkPc) + parseFloat(current.benchmarkPc),
      };
    },
    { fundPc: 0, benchmarkPc: 0 }
  );

  if (otherData.fundPc !== 0 || otherData.benchmarkPc !== 0) {
    fundSeriesData.push({
      name: "Other",
      value: otherData.fundPc,
    });
    benchmarkSeriesData.push({
      name: "Other",
      value: otherData.benchmarkPc,
    });
  }

  return {
    colors: [chartPalette[1], chartPalette[0]],
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
    },
    title: { text: `${titlePrefix} Breakdown (%)` },
    xAxis: {
      categories: fundSeriesData.map((item: any) => item.name),
    },
    yAxis: { title: { text: "" } },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          format: "{y:,.1f}", // format to 1 decimal places
          allowOverlap: true,
        },
      },
    },
    legend: {
      align: "right",
      verticalAlign: "top",
    },
    series: [
      {
        name: "Fund",
        type: "column",
        data: fundSeriesData.map((item: any) => item.value),
        showInLegend: true,
      },
      {
        name: "Index",
        type: "column",
        data: benchmarkSeriesData.map((item: any) => item.value),
        showInLegend: true,
      },
    ],
  };
};

const FundExposureTabContent = (props: { fundInfo: any }) => {
  const classes = useStyles();
  const {
    fundInfo,
    fundInfo: { valuationDate },
  } = props;

  const exposureData = {
    country: fundInfo.fundInfo.exposures.country,
    currency: fundInfo.fundInfo.exposures.currency,
    sectorBreakdown: fundInfo.fundInfo.exposures.sectorBreakdown,
  };

  const countryOptions =
    exposureData.country.length > 0
      ? convertDataToBarOptions(exposureData, "country", "Country")
      : null;

  const currencyOptions =
    exposureData.currency.length > 0
      ? convertDataToBarOptions(exposureData, "currency", "Currency")
      : null;

  const sectorOptions =
    exposureData.sectorBreakdown.length > 0
      ? convertDataToBarOptions(exposureData, "sectorBreakdown", "Sector")
      : null;

  return (
    <Fragment>
      <Grid container className="fund-exposure-tab-content">
        <Grid item xs={12}>
          <Typography style={{ margin: "20px 0 30px 0" }} variant="h5">
            Top Exposures (as at {valuationDate})
          </Typography>
        </Grid>
        {countryOptions === null ? null : (
          <Grid item xs={12} className={classes.exposureGraph}>
            <HighchartsReact highcharts={Highcharts} options={countryOptions} />
          </Grid>
        )}
        {currencyOptions === null ? null : (
          <Grid item xs={12} className={classes.exposureGraph}>
            <HighchartsReact
              highcharts={Highcharts}
              options={currencyOptions}
            />
          </Grid>
        )}
        {sectorOptions === null ? null : (
          <Grid item xs={12} className={classes.exposureGraph}>
            <HighchartsReact highcharts={Highcharts} options={sectorOptions} />
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default FundExposureTabContent;
