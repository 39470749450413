import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Grid, Typography, Button, Box } from "@mui/material";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import * as styles from "./AdvisorDashboard.styles";

import {
  amxPalette,
  ThingamabobOptions,
  View,
  ViewHeader,
  DocumentsIcon,
  DocumentIcon,
} from "@amx/component-library";
import { useAuth, useTelemetry } from "@amx/common-frontend";
import { UserBehaviour } from "../../common/types";

export type RawInvestor = {
  investorBusinessKey: number;
  investorName: string;
  portfolioName: string;
  portfolioTpaId: number;
  preferredCcy: string;
  preferredCcyTotal: number;
};

// BANNER CONFIG
const colourScheme = {
  backgroundColor: amxPalette.pink,
  foregroundColor: amxPalette.white_100,
};

const colourSchemeInverse = {
  backgroundColor: amxPalette.white_100,
  foregroundColor: amxPalette.pink,
};

const thingamabobsConfig = [
  { type: ThingamabobOptions.head, ...colourScheme },
  { type: ThingamabobOptions.chest, ...colourScheme },
  { type: ThingamabobOptions.legs, ...colourSchemeInverse },
  { type: ThingamabobOptions.bum, ...colourSchemeInverse },
];

const AdvisorDashboard = (props: {
  profileType: UserBehaviour;
  portfolioTpaIds?: string;
  onInvestorSelect: (investorId: number, tpaIds: string) => void;
}) => {
  const { axiosWithAuth } = useAuth();
  const appInsights = useAppInsightsContext();
  const { handleClickTracking } = useTelemetry(appInsights);

  const [investorNames, setInvestorNames] = useState<any>([]);
  const [investorData, setInvestorData] = useState<any>([]);
  const [marketValue, setMarketValue] = useState<number>(0);

  const { portfolioTpaIds, profileType, onInvestorSelect } = props;

  useEffect(() => {
    const getInvestors = async () => {
      if (axiosWithAuth === undefined) return;
      if (portfolioTpaIds === undefined) return;

      const response = await axiosWithAuth({
        url: `/investors?investorIds=${portfolioTpaIds}`,
      });

      if (response !== undefined && response.length > 0) {
        const distinctInvestorNames = response
          .map((s: any) => `${s.investorBusinessKey}~${s.investorName}`)
          .filter((v: any, i: any, a: any) => a.indexOf(v) === i);

        const marketValue = response.reduce((prev: number, cur: any) => {
          return prev + cur.preferredCcyTotal;
        }, 0);

        setInvestorNames(distinctInvestorNames);
        setInvestorData(response);
        setMarketValue(marketValue);
      } else {
        setInvestorNames([]);
        setInvestorData([]);
        setMarketValue(0);
      }
    };

    getInvestors();
  }, [axiosWithAuth, portfolioTpaIds]);

  return (
    <View onClick={handleClickTracking}>
      <ViewHeader
        subtitle="Dashboard"
        title="Advisor"
        thingamabobsConfig={thingamabobsConfig}
        style={{ marginBottom: "40px", background: amxPalette.pink }}
        height={133}
        smallText={true}
      />

      <Grid container spacing={4} marginBottom={5} alignItems="end">
        <Grid item xs={6}>
          <Typography
            sx={{
              fontSize: "24px",
            }}
          >
            My portfolios ({investorData?.length || 0})
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              alignItems: "end",
              display: "flex",
              flexDirection: "column",
              position: "relative",
              top: "7px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                color: amxPalette.black_50,
              }}
            >
              Manager AUM
            </Typography>
            <Box sx={styles.AumContainer}>
              {`USD ${parseFloat(marketValue.toFixed(0)).toLocaleString()}`}
            </Box>
            <Typography
              sx={{
                fontSize: "14px",
                color: amxPalette.black_50,
              }}
            >
              *Latest available Net Asset Value
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {investorNames.map((investorName: string) => {
        const isUnknown = investorName.split("~")[0] === "-1";
        const investorBusinessKey = investorName.split("~")[0];
        const investorGroup = investorName.split("~")[1];

        const filteredData = investorData.filter(
          (item: any) =>
            item.investorBusinessKey.toString() === investorBusinessKey
        );

        var currencyTotal = filteredData.reduce((prev: number, cur: any) => {
          return prev + cur.preferredCcyTotal;
        }, 0);

        const tpaIds = filteredData
          .map((data: any) => data.portfolioTpaId)
          .join(",");

        return (
          <Box sx={styles.InvestorContainer} key={investorBusinessKey}>
            <Box sx={styles.InvestorHeaderContainer}>
              <Grid item xs={7}>
                <Typography
                  sx={{
                    fontSize: "24px",
                  }}
                >
                  {isUnknown ? (
                    <pre>
                      {" "}
                      `** Unknown Investor Name for portfolioTpaId: $
                      {JSON.stringify(investorData, null, "   ")} ** `{" "}
                    </pre>
                  ) : (
                    investorGroup
                  )}
                </Typography>
              </Grid>
              <Grid item xs={5} container justifyContent="flex-end">
                <Typography
                  sx={{
                    fontSize: "24px",
                  }}
                >
                  {`USD ${parseFloat(
                    currencyTotal.toFixed(0)
                  ).toLocaleString()}`}
                </Typography>
              </Grid>
            </Box>

            {filteredData.map((row: any) => {
              return (
                <Fragment key={row.portfolioTpaId}>
                  <Box sx={styles.InvestorHeaderContainer}>
                    <Grid item xs={6}>
                      <Typography
                        variant="body1"
                        sx={{
                          color: amxPalette.grey_50,
                        }}
                      >
                        {row.portfolioName}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} container justifyContent="flex-end">
                      <Typography variant="body1">
                        {`USD ${
                          row.preferredCcyTotal === null
                            ? 0
                            : parseFloat(
                                row.preferredCcyTotal.toFixed(0)
                              ).toLocaleString()
                        }`}
                      </Typography>
                    </Grid>
                  </Box>
                </Fragment>
              );
            })}
            <Box sx={styles.ButtonsContainer}>
              <Button
                startIcon={<DocumentIcon />}
                sx={styles.ButtonLinks}
                component={Link}
                to={`/dashboard/available`}
                onClick={() => {
                  onInvestorSelect(parseInt(investorBusinessKey), tpaIds);
                }}
              >
                View Portfolio
              </Button>
              <Button
                startIcon={<DocumentsIcon />}
                sx={styles.ButtonLinks}
                component={Link}
                to={`/documents`}
                onClick={() => {
                  onInvestorSelect(parseInt(investorBusinessKey), tpaIds);
                }}
              >
                View Documents
              </Button>
            </Box>
          </Box>
        );
      })}
    </View>
  );
};

export default AdvisorDashboard;
