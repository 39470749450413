import { PortfolioFundType } from "./types/index.js";

export const convertToPortfolio = (
  data: PortfolioFundType[]
): PortfolioFundType[] => {
  if (!data) return [];

  return data.map(
    (data: PortfolioFundType): PortfolioFundType => ({
      ...data,
      // TODO: Add this back in when we have a new ESG provider.
      // esgScore: data.esg?.esgScore,
      valuationDate: data.valuationDate,
    })
  );
};
