import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useAuth } from "@amx/common-frontend";

import { PortfolioFundType } from "../types";
import DashboardTableWrapper from "../../../components/Dashboard/DashboardTableWrapper";

const LastDealing = (props: {
  handleFetchInvestorData: (navType?: string) => void;
  investorId?: number | string;
  advisorTpaIds?: string;
  showZeroBalance: boolean;
  dataState: any[];
}) => {
  const { axiosWithAuth } = useAuth();
  const {
    dataState,
    handleFetchInvestorData,
    investorId,
    advisorTpaIds,
    showZeroBalance,
  } = props;
  const [data] = dataState;

  useEffect(() => {
    const updateData = async () => {
      await handleFetchInvestorData("dealing");
    };

    updateData();

    // TODO: Implement cleanup function
    // https://reactjs.org/docs/hooks-effect.html
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axiosWithAuth, investorId, advisorTpaIds, showZeroBalance]);

  return (
    <Grid>
      <DashboardTableWrapper
        data={data.filter(
          (fund: PortfolioFundType) => fund.available === false
        )}
      />
    </Grid>
  );
};

export default LastDealing;
