import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close, Delete, Done, Edit, Help } from "@mui/icons-material";

import MarkdownPreview from "@uiw/react-markdown-preview";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "@amx/common-frontend";

import DeleteProviderDialog from "./DeleteProviderDialog";

const useStyles = makeStyles({
  thumbnail: {
    marginLeft: 15,
    marginRight: 25,
    width: 50,
    height: 50,
    padding: 5,
    border: "1px solid black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
      display: "block",
    },
  },
});

const Provider = ({
  provider,
  subServiceDomainId,
  domainId,
  serviceDomainsIsFetching,
  setMarkdownCheatSheet,
}: {
  provider: any;
  subServiceDomainId: string;
  domainId: string;
  serviceDomainsIsFetching: boolean;
  setMarkdownCheatSheet: Function;
}) => {
  const [internalState, setInternalState] = useState<any>(provider);
  const classes = useStyles();
  const { axiosWithAuth } = useAuth();
  const queryClient = useQueryClient();
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleProviderPatch = useMutation(
    () => {
      return axiosWithAuth!({
        url: `/store/${domainId}/${subServiceDomainId}/${internalState._id}`,
        method: "PATCH",
        data: {
          providerUpdates: {
            ...internalState,
          },
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("serviceDomains");
      },
    }
  );

  const handleDeleteProvider = useMutation(
    () => {
      return axiosWithAuth!({
        url: `/xchange/category/${domainId}/${subServiceDomainId}/${provider._id}`,
        method: "DELETE",
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("serviceDomains");
      },
    }
  );

  return (
    <React.Fragment>
      <Grid item xs={12} container justifyContent="space-between">
        <Box display="flex" alignItems="center" mb={1}>
          {editing ? (
            <TextField
              required
              multiline
              autoFocus
              size="small"
              maxRows={2}
              InputProps={{
                style: {
                  marginLeft: "10px",
                  marginBottom: "5px",
                  fontSize: "1rem",
                },
              }}
              value={internalState.itemName}
              defaultValue={internalState.itemName}
              onChange={(event) => {
                setInternalState({
                  ...internalState,
                  itemName: event.target.value,
                });
              }}
            />
          ) : (
            <Typography variant="caption" style={{ marginRight: 5 }}>
              {internalState.itemName}
            </Typography>
          )}
          {editing ? (
            <ButtonGroup size="small" style={{ marginLeft: 10 }}>
              <Button size="small" onClick={() => setEditing(false)}>
                <Done fontSize="small" />
              </Button>
              <Button
                size="small"
                onClick={() => {
                  setInternalState({
                    ...internalState,
                    itemName: internalState.itemName,
                  });
                  setEditing(false);
                }}
              >
                <Close fontSize="small" />
              </Button>
            </ButtonGroup>
          ) : (
            <Edit
              fontSize="small"
              onClick={() => setEditing(true)}
              style={{ cursor: "pointer" }}
            />
          )}
          <Delete
            fontSize="small"
            onClick={(event) => {
              event.stopPropagation();
              setDeleting(true);
            }}
            style={{ cursor: "pointer" }}
          />
        </Box>
        {(serviceDomainsIsFetching || handleProviderPatch.isLoading) && (
          <CircularProgress />
        )}
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={11}>
        <Box display="flex" alignItems="center">
          <Typography>Logo</Typography>
          <TextField
            style={{ marginLeft: 15 }}
            variant="outlined"
            size="small"
            value={internalState.image}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setInternalState({ ...internalState, image: event.target.value });
            }}
          />
          <div className={classes.thumbnail}>
            <img src={internalState.image} alt="" />
          </div>
        </Box>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={11}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Interaction</FormLabel>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            value={internalState.interactionType}
            onChange={(event) =>
              setInternalState({
                ...internalState,
                interactionType: event.target.value,
              })
            }
          >
            <FormControlLabel
              value="info"
              control={<Radio color="primary" />}
              label="This is part of the AMX package"
              disabled={internalState.interactionType === "activate"}
            />
            <FormControlLabel
              value="enquire"
              control={<Radio color="primary" />}
              label="Optional Service"
              disabled={internalState.interactionType === "activate"}
            />
            <FormControlLabel
              value="activate"
              control={<Radio color="primary" />}
              label="Integrated Service"
              disabled={true}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={11}>
        <Box display="flex">
          <FormControl fullWidth style={{ position: "relative" }}>
            <TextField
              fullWidth
              label="Markdown"
              value={internalState.markdown ?? ""}
              variant="outlined"
              inputProps={{
                maxLength: 10000,
              }}
              multiline
              minRows={8}
              maxRows={15}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setInternalState({
                  ...internalState,
                  markdown: event.target.value,
                });
              }}
            />
            <Help
              onClick={() => setMarkdownCheatSheet(true)}
              style={{
                position: "absolute",
                top: 5,
                right: 5,
                cursor: "pointer",
              }}
            />
          </FormControl>
          <div
            style={{
              border: "1px solid black",
              padding: "18.5px 14px",
              position: "relative",
              borderRadius: "4px",
              minHeight: 168,
              width: "100%",
              marginLeft: 15,
            }}
          >
            <MarkdownPreview source={internalState.markdown} />
          </div>
        </Box>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={11} container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Website"
            style={{ width: "100%" }}
            variant="outlined"
            value={internalState.website}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setInternalState({
                ...internalState,
                website: event.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Email"
            style={{ width: "100%" }}
            variant="outlined"
            value={(internalState.email?.split(",") || [])[0] || ""}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setInternalState({
                ...internalState,
                email: [
                  event.target.value,
                  (internalState.email?.split(",") || [])[1] ?? "",
                ]
                  // Filter out empty emails
                  .filter((email: string) => email)
                  .join(","),
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Email 2"
            style={{ width: "100%" }}
            variant="outlined"
            value={(internalState.email?.split(",") || [])[1] || ""}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setInternalState({
                ...internalState,
                email: [
                  (internalState.email?.split(",") || [])[0] ?? "",
                  event.target.value,
                ]
                  // Filter out empty emails
                  .filter((email: string) => email)
                  .join(","),
              });
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={11} container spacing={2}>
        <Grid item xs={4}>
          <TextField
            label="Phone 1"
            style={{ marginBottom: 15, width: "100%" }}
            variant="outlined"
            value={(internalState.telephone || [])[0]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setInternalState({
                ...internalState,
                telephone: [
                  event.target.value,
                  (internalState.telephone || [])[1] ?? "",
                  (internalState.telephone || [])[2] ?? "",
                ],
              });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Phone 2"
            style={{ marginBottom: 15, width: "100%" }}
            variant="outlined"
            value={(internalState.telephone || [])[1]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setInternalState({
                ...internalState,
                telephone: [
                  (internalState.telephone || [])[0] ?? "",
                  event.target.value,
                  (internalState.telephone || [])[2] ?? "",
                ],
              });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Phone 3"
            style={{ marginBottom: 15, width: "100%" }}
            variant="outlined"
            value={(internalState.telephone || [])[2]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setInternalState({
                ...internalState,
                telephone: [
                  (internalState.telephone || [])[0] ?? "",
                  (internalState.telephone || [])[1] ?? "",
                  event.target.value,
                ],
              });
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={11} container>
        <Button
          onClick={() => handleProviderPatch.mutate()}
          variant="outlined"
          disabled={
            !Object.keys(internalState).length || handleProviderPatch.isLoading
          }
        >
          Submit
        </Button>
      </Grid>
      <DeleteProviderDialog
        handleDeleteProvider={handleDeleteProvider}
        name={provider.itemName}
        open={deleting}
        onClose={() => setDeleting(false)}
      />
    </React.Fragment>
  );
};

export default Provider;
