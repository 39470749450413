import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Box, Tab, Tabs } from "@mui/material";
import { useAuth, useTelemetry } from "@amx/common-frontend";
import {
  amxPalette,
  ThingamabobOptions,
  View,
  ViewHeader,
} from "@amx/component-library";

import FundTable from "./FundTable";
import { FundClassType } from "./types";
import "./Funds.css";

// CONFIG FOR THE BANNER
const colourScheme = {
  backgroundColor: amxPalette.blue,
  foregroundColor: amxPalette.white_100,
};

const colourSchemeInverse = {
  backgroundColor: amxPalette.white_100,
  foregroundColor: amxPalette.blue,
};

// THEN ADD THE THINGAMABOBS
const thingamabobsConfig = [
  { type: ThingamabobOptions.head, ...colourScheme },
  { type: ThingamabobOptions.chest, ...colourScheme },
  { type: ThingamabobOptions.legs, ...colourSchemeInverse },
  { type: ThingamabobOptions.bum, ...colourSchemeInverse },
];

const Funds = (props: { showEsg: boolean }) => {
  const [tableData, setTableData] = useState<FundClassType[]>([]);
  const appInsights = useAppInsightsContext();
  const { handleClickTracking } = useTelemetry(appInsights);
  const location = useLocation();
  const history = useHistory();

  const [value, setValue] = React.useState(() => {
    return location.pathname === "/funds/performance" ? 1 : 0;
  });

  const { axiosWithAuth } = useAuth();

  const { data: funds, isLoading } = useQuery(
    ["funds"],
    async () => {
      if (axiosWithAuth === undefined) return;
      const response = await axiosWithAuth({
        url: "/getallfunds",
      });
      return response;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (funds !== undefined) {
      let fullArray: FundClassType[] = [];
      for (const fund of funds) {
        // pull out ClassDetails from object
        const fundInfo = (({ ClassDetails, ...object }) => {
          return object;
        })(fund);
        // create rows contain fund details and class details
        for (const classDetail of fund.ClassDetails) {
          const fullRow = { ...fundInfo, ...classDetail };
          fullArray.push(fullRow);
        }
      }
      setTableData(fullArray);
    }
  }, [funds]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    history.push(newValue === 0 ? "/funds/nav" : "/funds/performance");
    setValue(newValue);
  };

  return (
    <View
      isLoading={isLoading}
      data-testid="funds-table"
      data-trackable-context="Funds"
      onClick={handleClickTracking}
    >
      <ViewHeader
        title="AMX Funds"
        thingamabobsConfig={thingamabobsConfig}
        style={{ marginBottom: "40px", background: amxPalette.blue }}
        height={138}
        smallText={true}
      />

      <Tabs value={value} onChange={handleTabChange}>
        <Tab label="Fund NAV" />
        <Tab label="Fund Performance" />
      </Tabs>
      <Box sx={{ width: "fit-content" }}>
        <Switch>
          {tableData.length ? (
            <>
              <Route path="/funds/nav">
                <FundTable data={tableData} tab="nav" />
              </Route>
              <Route path="/funds/performance">
                <FundTable data={tableData} tab="performance" />
              </Route>
            </>
          ) : null}
        </Switch>
      </Box>
    </View>
  );
};

export default Funds;
