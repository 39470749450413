import React from "react";
import { makeStyles } from "@mui/styles";
import { Button, Card, CardContent, CardMedia, Typography } from "@mui/material";

import { useStore } from "../../providers";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "160px",
    height: "160px",
    padding: "20px",
    position: "relative",
    transition: "transform .2s",
    scrollMarginTop: "120px",

    "& button": {
      display: "none",
    },

    "@media (hover: hover)": {
      "&:hover": {
        transform: "scale(1.1)",

        "& button": {
          display: "block",
          pointerEvents: "auto",
        },

        "& img": {
          top: "-15px",
        },
      },
    },
  },

  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    "& button": {
      display: "none",
    },
    "@media (hover: hover)": {
      "&:hover": {
        "& button": {
          display: "block",
        },
      },
    },
  },

  cardContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    position: "relative",
    justifyContent: "center",
    "@media (hover: hover)": {
      "&:hover": {
        cursor: "pointer",
      },
    },

    "& img": {
      objectFit: "contain",
    },
  },

  cardButton: {
    textTransform: "none",
    position: "absolute",
    bottom: "10px",
    left: "0",
    right: "0",
    margin: "auto",
    fontSize: "10px",
    padding: "2px 10px",
    borderRadius: "20px",
    width: "fit-content",
    "@media (hover: hover)": {
      "&:hover": {
        backgroundColor: "#E4E5E9",
      },
    },
  },

  cardText: {
    fontSize: "clamp(12px, 1.042vw, 16px)",
  },

  image: {
    width: "100%",
    position: "relative",
  },
}));

const StoreCard = (props: {
  item: any;

  onServiceClick: Function;
  ssDomain?: any;
  category?: any;
  providerIndex?: number;
  categoryIndex?: number;
  ssIndex?: number;
}) => {
  const {
    item,

    onServiceClick,
    category,
    ssDomain,
    providerIndex,
    categoryIndex,
    ssIndex,
  } = props;
  const classes = useStyles();

  const { setStoreData, signedOut } = useStore();
  const cardRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const cardId = `card-${item._id}`;

  return (
    <>
      <Card
        className={classes.card}
        variant="outlined"
        data-trackable="Item"
        data-trackable-params-item={item.itemName}
        data-trackable-params-sub-service-domain={ssDomain}
        data-trackable-params-category={category}
        ref={cardRef}
      >
        <div
          className={classes.cardWrapper}
          id={cardId}
          onClick={() => {
            onServiceClick(
              providerIndex,
              ssIndex,
              categoryIndex,
              item,
              category
            );
            if (signedOut)
              setStoreData({
                cardId: cardId,
                cardItem: cardRef.current,
              });
          }}
        >
          <div className={classes.cardContainer}>
            {item.image ? (
              <CardMedia
                component="img"
                image={item.image}
                title={item.itemName}
                className={classes.image}
              />
            ) : (
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="h2"
                  align="center"
                  className={classes.cardText}
                >
                  {item.itemName}
                </Typography>
              </CardContent>
            )}
          </div>
          <Button className={classes.cardButton}>Learn More</Button>
        </div>
      </Card>
    </>
  );
};

export default StoreCard;
