import { amxPalette } from "@amx/component-library";
import { SxProps, Theme, keyframes } from "@mui/system";

export const actionIcon: SxProps<Theme> = {
  position: "absolute",
  top: 0,
  bottom: 0,
  fill: "transparent",
  "&:hover path": {
    stroke: amxPalette.grey_80,
  },

  "&:active path": {
    stroke: amxPalette.grey_100,
  },
};

export const actionDots: SxProps<Theme> = {
  ...actionIcon,
  left: "33px",
};

export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const gearIcon: SxProps<Theme> = {
  animation: `${spin} 1s linear infinite`,
};

export const popoverIcon: SxProps<Theme> = {
  fill: "transparent",
  fontSize: "16px",
};

export const listItemIcon: SxProps<Theme> = {
  minWidth: "40px",
};

export const innerWrap: SxProps<Theme> = {};

export const popover: SxProps<Theme> = {
  width: "400px",
  border: `1px solid ${amxPalette.grey_10}`,
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",

  "& ul": {
    padding: "8px",
    "& li": {
      color: amxPalette.black_50,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.5px",
      padding: "15px",
      overflow: "auto",
    },
  },
};

export const downloadIcon: SxProps<Theme> = {
  ...actionIcon,
  cursor: "pointer",
};
