import React from "react";

const AmxIcon = () => {
  return (
    <svg
      width="78"
      height="37"
      viewBox="0 0 78 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1130_843)">
        <path
          d="M12.9589 0.170796C12.912 0.0698712 12.8181 0 12.7085 0H9.57076C9.46121 0 9.35949 0.0621077 9.32036 0.163033L0.0244526 21.8852C-0.0146716 21.9706 -0.00684673 22.0715 0.0479271 22.1492C0.102701 22.2268 0.188774 22.2734 0.282672 22.2734H2.93529C3.05267 22.2734 3.16221 22.2035 3.20916 22.0948L5.72094 16.2256H16.5583L19.0701 22.0948C19.1171 22.2035 19.2266 22.2734 19.344 22.2734H21.9888C22.0827 22.2734 22.1766 22.2268 22.2235 22.1492C22.2783 22.0715 22.2861 21.9706 22.247 21.8852L12.9589 0.170796ZM15.2751 13.2445H6.99639L11.1357 3.57119L15.2751 13.2445Z"
          fill="#1D252D"
        />
        <path
          d="M49.7457 0H46.9913C46.8896 0 46.7879 0.0543442 46.7409 0.147506L46.7175 0.17856L38.8378 13.4541C38.8144 13.4851 38.7831 13.4929 38.7674 13.4929C38.7518 13.4929 38.7205 13.4929 38.697 13.4541L30.8017 0.147506C30.7548 0.0543442 30.6531 0 30.5513 0H27.797C27.6718 0 27.5623 0.100925 27.5623 0.232904V22.0482C27.5623 22.1724 27.664 22.2811 27.797 22.2811H30.3479C30.4731 22.2811 30.5826 22.1802 30.5826 22.0482V5.62075L36.7956 16.0859C36.8503 16.1713 36.9442 16.2334 37.0538 16.2334H40.5045C40.6063 16.2334 40.708 16.1791 40.7628 16.0859L46.9757 5.61298V22.0482C46.9757 22.1724 47.0774 22.2811 47.2104 22.2811H49.7613C49.8865 22.2811 49.9961 22.1802 49.9961 22.0482V0.232904C49.9804 0.108688 49.8787 0 49.7457 0Z"
          fill="#1D252D"
        />
        <path
          d="M65.2522 12.1426H63.3899C63.3273 12.1426 63.2647 12.1659 63.2178 12.2124L55.2443 20.0924C55.1973 20.1389 55.1738 20.2011 55.1738 20.2632V22.0488C55.1738 22.173 55.2756 22.2817 55.4086 22.2817H57.2083C57.2709 22.2817 57.3335 22.2584 57.3804 22.2118L65.3226 14.3319C65.3696 14.2853 65.3931 14.2232 65.3931 14.1611V12.2901C65.3931 12.2047 65.3305 12.1426 65.2522 12.1426Z"
          fill="#1D252D"
        />
        <path
          d="M69.6187 12.2124C69.5718 12.1659 69.5092 12.1426 69.4466 12.1426H67.5608C67.4825 12.1426 67.4199 12.2047 67.4199 12.2823V14.13C67.4199 14.1921 67.4434 14.2542 67.4903 14.3008L75.4247 22.2118C75.4717 22.2584 75.5343 22.2817 75.5969 22.2817H77.3966C77.5218 22.2817 77.6313 22.1807 77.6313 22.0488V20.2632C77.6313 20.2011 77.6079 20.1389 77.5609 20.0924L69.6187 12.2124Z"
          fill="#1D252D"
        />
        <path
          d="M57.3785 0.0698712C57.3315 0.0232904 57.2689 0 57.2063 0H55.4144C55.2892 0 55.1797 0.100925 55.1797 0.232904V1.98745C55.1797 2.04955 55.2032 2.11166 55.2501 2.15824L63.1845 10.0692C63.2314 10.1158 63.294 10.1391 63.3566 10.1391H65.2503C65.3285 10.1391 65.3911 10.077 65.3911 9.99934V8.12058C65.3911 8.05847 65.3676 7.99637 65.3207 7.94979L57.3785 0.0698712Z"
          fill="#1D252D"
        />
        <path
          d="M77.3966 0H75.5969C75.5343 0 75.4717 0.0232904 75.4247 0.0698712L67.4903 7.95755C67.4434 8.00413 67.4199 8.06624 67.4199 8.12835V9.99934C67.4199 10.077 67.4825 10.1391 67.5608 10.1391H69.4231C69.4857 10.1391 69.5483 10.1158 69.5952 10.0692L77.5609 2.1893C77.6079 2.14272 77.6313 2.08061 77.6313 2.0185V0.232904C77.6313 0.108688 77.5296 0 77.3966 0Z"
          fill="#1D252D"
        />
        <path
          d="M1.4274 33.1783V34.2263H0.402344V25.2207H1.4274V28.8773C1.81082 28.1941 2.6559 27.7361 3.55575 27.7361C5.55891 27.7361 6.65439 29.1645 6.65439 31.0278C6.65439 32.891 5.55891 34.3195 3.55575 34.3195C2.64807 34.3272 1.81082 33.8692 1.4274 33.1783ZM3.46968 33.4189C4.76078 33.4189 5.61369 32.4795 5.61369 31.0355C5.61369 29.5915 4.76078 28.6522 3.46968 28.6522C2.17858 28.6522 1.34132 29.5915 1.34132 31.0355C1.34132 32.4795 2.17858 33.4189 3.46968 33.4189Z"
          fill="#1D252D"
        />
        <path
          d="M9.4784 34.3262L7.07617 27.8438H8.14035L9.96354 32.9055L11.7867 27.8438H12.8587L10.8164 33.348L9.64272 36.8028H8.65679L9.4784 34.3262Z"
          fill="#1D252D"
        />
        <path
          d="M16.5996 29.7936C16.5996 26.7271 18.681 25.2598 20.9033 25.2598C22.8438 25.2598 24.4401 26.4631 24.6827 28.1245H23.5324C23.3759 27.0376 22.2726 26.238 20.9033 26.238C19.041 26.238 17.7264 27.4879 17.7264 29.7936C17.7264 32.0994 19.041 33.3493 20.9033 33.3493C22.2961 33.3493 23.415 32.5497 23.5481 31.4628H24.6748C24.4792 33.1242 22.8908 34.3275 20.9111 34.3275C18.6654 34.3275 16.5996 32.8447 16.5996 29.7936Z"
          fill="#1D252D"
        />
        <path
          d="M28.4066 28.6078C27.4598 28.6078 26.7243 29.1901 26.6695 29.8888H25.6992C25.8166 28.6622 26.9903 27.7461 28.4301 27.7461C30.1046 27.7461 31.1453 28.6932 31.1453 30.1139V34.2286H30.1281V33.1262C29.7838 33.8637 28.9309 34.3295 27.9293 34.3295C26.5756 34.3295 25.6992 33.5687 25.6992 32.4042C25.6992 31.1853 26.7086 30.4167 28.2658 30.4167H29.7212C30.0029 30.4167 30.1281 30.277 30.1281 30.0751C30.1281 29.2056 29.5021 28.6078 28.4066 28.6078ZM28.0858 33.4678C29.3769 33.4678 30.1281 32.6681 30.1281 31.3561V31.1931H28.3284C27.3737 31.1931 26.7477 31.6511 26.7477 32.3964C26.7477 33.0485 27.272 33.4678 28.0858 33.4678Z"
          fill="#1D252D"
        />
        <path
          d="M33.6754 31.4149V34.2253H32.6582V27.8438H33.6754V29.1325C33.9415 28.2552 34.4814 27.8438 35.4204 27.8438H36.2342V28.7443H35.3421C34.051 28.7443 33.6754 29.9088 33.6754 31.4149Z"
          fill="#1D252D"
        />
        <path
          d="M37.3281 27.847H38.3454V29.0736C38.674 28.1886 39.48 27.7461 40.4659 27.7461C41.9213 27.7461 42.8681 28.7709 42.8681 30.3313V34.2286H41.8509V30.4944C41.8509 29.4075 41.2092 28.6699 40.239 28.6699C39.1435 28.6699 38.3532 29.5472 38.3532 30.7816V34.2286H37.336V27.847H37.3281Z"
          fill="#1D252D"
        />
        <path
          d="M47.2431 27.7461C49.1211 27.7461 50.24 29.0348 50.24 30.735C50.24 30.8748 50.2244 31.0921 50.2166 31.1775H45.1852C45.2634 32.4973 46.1398 33.4289 47.3527 33.4289C48.4012 33.4289 49.0663 32.9709 49.2698 32.179H50.3026C50.0835 33.4833 48.9489 34.3295 47.3527 34.3295C45.4512 34.3295 44.1758 32.8234 44.1758 30.999C44.1836 29.128 45.3573 27.7461 47.2431 27.7461ZM49.1993 30.3701C49.1211 29.291 48.3934 28.6078 47.2431 28.6078C46.1555 28.6078 45.4278 29.2444 45.24 30.3701H49.1993Z"
          fill="#1D252D"
        />
      </g>
      <defs>
        <clipPath id="clip0_1130_843">
          <rect width="77.6302" height="36.8143" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AmxIcon;
