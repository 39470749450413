import { FundNavType, FundPerformanceType } from "../root/funds/types";
import { PortfolioFundType } from "../root/dashboard/types";
import { PortfolioFundTableData } from "../components/Dashboard/DashboardTableWrapper";
import {
  FilterDictionary,
  FundPerformanceChartData,
  FundNavChartData,
} from "../common/types";
import { euroFormat, toDate } from "./DateFormatter";

// function currently not used within app
export const createNavData = (
  navData: FundNavType[],
  filters: FilterDictionary,
  addStrategyHeader: boolean = false
): FundNavChartData[] => {
  let counter = 0;
  let strategyMap: { [strategy: string]: FundNavChartData[] } = {};

  navData.forEach((fund, i) => {
    const data = navData[i];
    const target = strategyMap[fund.fundStrategy] || [];

    if (filters[fund.fundStrategy] === false) {
      target.push({
        ...data,
        fundValDate: euroFormat(data.fundValDate),
        id: `${data.fundName}:${counter}`,
      });
    }

    strategyMap[fund.fundStrategy] = target;
    counter++;
  });

  let rows: FundNavChartData[] = [];
  const keys = Object.keys(strategyMap);

  keys.sort().forEach((strategy) => {
    if (addStrategyHeader) {
      rows.push({
        id: strategy,
        fundStrategy: strategy,
        expanded: filters[strategy],
      });
    }
    const dataForStrategy = strategyMap[strategy];

    dataForStrategy.forEach((fund) => {
      rows.push({
        ...fund,
        id: fund.id,
        fundStrategy: undefined,
      });
    });
  });

  return rows;
};

// function currently not used within app
export const createPerformanceData = (
  navData: FundPerformanceType[],
  filters: FilterDictionary
): FundPerformanceChartData[] => {
  let counter = 0;
  let strategyMap: { [strategy: string]: FundPerformanceChartData[] } = {};

  navData.forEach((fund, i) => {
    const data = navData[i];
    const target = strategyMap[fund.fundStrategy] || [];

    if (filters[fund.fundStrategy] === false) {
      target.push({
        ...data,
        classLaunchDate: euroFormat(data.classLaunchDate),
        id: `${data.fundName}:${counter}`,
      });
    }

    strategyMap[fund.fundStrategy] = target;
    counter++;
  });

  let rows: FundPerformanceChartData[] = [];
  const keys = Object.keys(strategyMap);

  keys.sort().forEach((strategy) => {
    rows.push({
      id: strategy,
      fundStrategy: strategy,
      expanded: filters[strategy],
    });
    const dataForStrategy = strategyMap[strategy];

    dataForStrategy.forEach((fund) => {
      rows.push({
        ...fund,
        id: fund.id,
        fundStrategy: undefined,
      });
    });
  });

  return rows;
};

export const createLastDealingData = (
  ldData: PortfolioFundType[],
  filters: FilterDictionary
): PortfolioFundTableData[] => {
  let counter = 0;
  let portfolioMap: { [portfolio: string]: PortfolioFundTableData[] } = {};

  ldData
    .sort((a, b) =>
      a.fundNav < b.fundNav ? 1 : a.fundNav === b.fundNav ? 0 : -1
    )
    .forEach((fund, i) => {
      const data = ldData[i];
      const target = portfolioMap[fund.portfolioName] || [];

      if (filters[fund.portfolioName] === false) {
        target.push({
          ...data,
          valuationDate: data.valuationDate
            ? euroFormat(toDate(data.valuationDate))
            : "",
          id: `${data.fundName}:${counter}`,
        });
      }

      portfolioMap[fund.portfolioName] = target;
      counter++;
    });

  let rows: PortfolioFundTableData[] = [];
  const keys = Object.keys(portfolioMap);

  keys.sort().forEach((portfolio) => {
    const filtered = ldData.filter((fund) => fund.portfolioName === portfolio);
    const fundSum = filtered.reduce(
      (prev, current) => prev + current.systemCurrencyAum,
      0
    );
    rows.push({
      id: portfolio,
      portfolioName: portfolio,
      fundCount: filtered.length,
      fundSum,
      expanded: filters[portfolio],
    });
    const dataForStrategy = portfolioMap[portfolio];

    dataForStrategy
      .sort((a: any, b: any) => {
        if (a.fundName < b.fundName) {
          return 1;
        } else if (a.fundName > b.fundName) {
          return -1;
        }

        return 0;
      })
      .forEach((fund) => {
        rows.push({
          ...fund,
          id: fund.id,
          portfolioName: undefined,
          fundCount: undefined,
        });
      });
  });

  return rows;
};
