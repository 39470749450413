import React, { useCallback } from "react";
import { Box } from "@material-ui/core";
import { useAuth } from "@amx/common-frontend";
import { AMXButton, AddIcon } from "@amx/component-library";

import { UserBehaviour } from "../../../common/types";
import { useDocuments } from "../Documents";
import { DocumentsTableTabs } from "./DocumentsTableTabs";
import { downloadFile } from "../../../helpers/downloadFile";
import { useDialogToggle } from "../../../helpers/useDialogToggle";

import { DocumentUpload } from "./DocumentUpload";

export const DocumentsMain = ({
  fundManagerSk,
  investorId,
  userType,
  docTypes,
}: {
  fundManagerSk?: string;
  investorId: number | string | undefined;
  userType: UserBehaviour | undefined;
  docTypes: any;
}) => {
  const { axiosWithAuth } = useAuth();

  const {
    selectedDocType,
    updateDocument,
    setDocumentDownloading,
    setDocumentHasUploaded,
    userGroups,
  } = useDocuments();

  const {
    isOpen: isDialogOpen,
    onClose: onDialogClose,
    onOpen: onDialogOpen,
  } = useDialogToggle();

  const dialogCloseClick = () => {
    onDialogClose();
    setDocumentHasUploaded(false);
  };

  const handleDocVisibleClick = useCallback(async (doc: any) => {
      if (axiosWithAuth === undefined) return;

      const { documentSk, visibleFlag } = doc;
      const updateVisibleFlag = visibleFlag === "T" ? "F" : "T";

      updateDocument(
        { ...doc, visibleFlag: updateVisibleFlag },
        selectedDocType,
      );

      await axiosWithAuth({
        url: "/documents/update",
        method: "POST",
        data: {
          documentSk,
          visibleFlag: updateVisibleFlag,
          customDocName: doc.customDocumentName || doc.documentName,
        },
      });
    },
    [axiosWithAuth, selectedDocType, updateDocument]
  );

  const uploadDocumentClick = useCallback(() => {
    setDocumentHasUploaded(false);
    onDialogOpen();
  }, [setDocumentHasUploaded, onDialogOpen]);

  const handleDownloadClick = async (
    documentName: string,
    documentSk: number
  ) => {
    if (axiosWithAuth === undefined) return;

    setDocumentDownloading(documentSk);

    const params: {
      documentSk: number;
      investorId: string | number | undefined;
      userType?: UserBehaviour;
      fundManagerSk?: string;
    } = { documentSk, userType, investorId };

    if (userType === UserBehaviour.manager && fundManagerSk) {
      params.fundManagerSk = fundManagerSk;
    }

    const data = await axiosWithAuth({
      url: "/documents/",
      method: "GET",
      responseType: "blob",
      params,
    });

    setDocumentDownloading(null);
    downloadFile(data, documentName);
  };

  return (
    <>
      {userGroups.includes("documents-upload") ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          <AMXButton
            sx={{ minWidth: "fit-content", display: "flex" }}
            onClickCallback={uploadDocumentClick}
            endIcon={<AddIcon />}
          >
            Upload Document
          </AMXButton>
        </Box>
      ) : null}
      <Box>
        <DocumentsTableTabs
          docTypes={docTypes}
          hideClick={handleDocVisibleClick}
          downloadDoc={handleDownloadClick}
        />
      </Box>

      <DocumentUpload
        isDialogOpen={isDialogOpen}
        handleCloseDialog={dialogCloseClick}
      />
    </>
  );
};
