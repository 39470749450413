import React, { Fragment } from "react";
import { Grid, Typography } from "@mui/material";

import GenericTable, { Column } from "../../../components/Table/GenericTable";

const getMetadata = (showMaturity: boolean): Column[] => {
  return [
    {
      id: "rank",
      label: "Rank",
    },
    {
      id: "stock",
      label: "Stock",
    },
  ].concat(
    showMaturity
      ? [
          {
            id: "maturity",
            label: "Maturity",
          },
        ]
      : [],
    [
      {
        id: "sector",
        label: "Sector",
      },
      {
        id: "country",
        label: "Country",
      },
      {
        id: "fundPc",
        label: "Fund (%)",
      },
    ]
  );
};

const FundHoldingsTabContent = (props: { fundInfo: any }) => {
  const { fundInfo } = props;
  const holdingsData = fundInfo.fundInfo.holdings.holdings;
  const holdingsSummary = {
    total: fundInfo.fundInfo.holdings.holdingsTotal,
    count: fundInfo.fundInfo.holdings.holdingsSecuritiesCount,
    asAt: fundInfo.fundInfo.holdings.valuationDate,
    productCode: fundInfo.productId,
  };

  return (
    <Fragment>
      <Grid container className="FundHoldingsTabContent">
        <Grid item xs={12}>
          <Typography variant="h5" className="title-padding">
            Largest Holdings{" "}
            {holdingsSummary.asAt !== null
              ? `(as at ${holdingsSummary.asAt})`
              : ""}
          </Typography>
          <GenericTable
            data={holdingsData}
            metadata={getMetadata(
              holdingsSummary.productCode === "LOC" ||
                holdingsSummary.productCode === "LTC"
            )}
          />
        </Grid>
        <Grid container>
          <Grid item xs={7}>
            <Typography variant="h6" align="right" className="title-padding">
              Number of Securities - Fund:
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="h6" align="center" className="title-padding">
              {holdingsSummary.count}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6" align="right" className="title-padding">
              Top Total Holdings:
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="h6" align="center" className="title-padding">
              {holdingsSummary.total}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default FundHoldingsTabContent;
