import React, { useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Add, Close, Done, Edit, Loop } from "@mui/icons-material";

import { useAuth } from "@amx/common-frontend";

import SubDomain from "./SubDomain";
import AddSubDomainDialog from "./AddSubDomainDialog";
import DeleteCategoryDialog from "./DeleteCategoryDialog";

const useStyles = makeStyles({
  "@keyframes spin": {
    "0%": { transform: "rotate(360deg)" },
    "100%": { transform: "rotate(0deg)" },
  },
  spinner: {
    animation: "$spin 1s linear infinite",
  },
});

const Domain = ({
  domain,
  serviceDomainsIsFetching,
  setMarkdownCheatSheet,
}: {
  domain: any;
  serviceDomainsIsFetching: boolean;
  setMarkdownCheatSheet: Function;
}) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const classes = useStyles();
  const handleChange = useMemo(
    () =>
      (panel: string) =>
      (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
      },
    []
  );

  const [addSubdomainDialog, setAddSubdomainDialog] = useState<boolean>(false);
  const [deleteCategoryDialog, setDeleteCategoryDialog] =
    useState<boolean>(false);
  const [serviceDomainName, setServiceDomainName] = useState<string>();
  const [editing, setEditing] = useState(false);

  const queryClient = useQueryClient();
  const { axiosWithAuth } = useAuth();

  const handleAddSubDomain = useMutation(
    (subDomainFormState: any) => {
      return axiosWithAuth!({
        url: `/store/${domain._id}/subServiceDomain`,
        method: "POST",
        data: {
          ...subDomainFormState,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("serviceDomains");
      },
    }
  );
  const handleDeleteCategory = useMutation(
    () => {
      return axiosWithAuth!({
        url: `/xchange/category/${domain._id}`,
        method: "DELETE",
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("serviceDomains");
      },
    }
  );

  const handleServiceDomainUpdate = useMutation(
    () => {
      return axiosWithAuth!({
        url: `/store/${domain._id}`,
        method: "PATCH",
        data: {
          serviceDomainName,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("serviceDomains");
      },
      onError: () => {
        setServiceDomainName(undefined);
      },
    }
  );

  return (
    <>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" mb={1}>
          {editing ? (
            <TextField
              required
              multiline
              autoFocus
              size="small"
              maxRows={2}
              InputProps={{
                style: {
                  marginLeft: "10px",
                  marginBottom: "5px",
                  fontSize: "1rem",
                },
              }}
              value={serviceDomainName ?? domain.serviceDomainName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                event.stopPropagation();
                setServiceDomainName(event.target.value);
              }}
            />
          ) : (
            <Typography variant="subtitle1" style={{ marginRight: 5 }}>
              {serviceDomainName ?? domain.serviceDomainName}
            </Typography>
          )}
          {editing ? (
            <ButtonGroup size="small" style={{ marginLeft: 10 }}>
              <Button
                type="submit"
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  handleServiceDomainUpdate.mutate();
                  setEditing(false);
                }}
              >
                <Done fontSize="small" />
              </Button>
              <Button
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  setServiceDomainName(domain.serviceDomainName);
                  setEditing(false);
                }}
              >
                <Close fontSize="small" />
              </Button>
            </ButtonGroup>
          ) : (
            <Edit
              fontSize="small"
              onClick={(event) => {
                event.stopPropagation();
                setEditing(true);
              }}
              style={{ cursor: "pointer" }}
            />
          )}
          <Button
            style={{ marginLeft: 10 }}
            variant="outlined"
            size="small"
            startIcon={<Add />}
            disabled={handleAddSubDomain.isLoading}
            onClick={() => setAddSubdomainDialog(true)}
          >
            {handleAddSubDomain.isLoading ? (
              <Loop className={classes.spinner} />
            ) : (
              "Add Sub Service"
            )}
          </Button>
          <Button
            style={{ marginLeft: "auto" }}
            variant="outlined"
            size="small"
            startIcon={<Close />}
            disabled={handleDeleteCategory.isLoading}
            onClick={() => setDeleteCategoryDialog(true)}
          >
            {handleDeleteCategory.isLoading ? (
              <Loop className={classes.spinner} />
            ) : (
              "Delete Category"
            )}
          </Button>
        </Box>
        {domain.subServiceDomains.map((subServiceDomain: any) => {
          return (
            <SubDomain
              setMarkdownCheatSheet={setMarkdownCheatSheet}
              key={subServiceDomain._id}
              subServiceDomain={subServiceDomain}
              domainId={domain._id}
              expanded={expanded}
              handleChange={handleChange}
              serviceDomainsIsFetching={serviceDomainsIsFetching}
            />
          );
        })}
      </Grid>
      <AddSubDomainDialog
        handleAddSubDomain={handleAddSubDomain}
        onClose={() => {
          setAddSubdomainDialog(false);
        }}
        open={addSubdomainDialog}
      />
      <DeleteCategoryDialog
        handleDeleteCategory={handleDeleteCategory}
        name={serviceDomainName ?? domain.serviceDomainName}
        onClose={() => {
          setDeleteCategoryDialog(false);
        }}
        open={deleteCategoryDialog}
      />
    </>
  );
};

export default Domain;
