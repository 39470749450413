import React, { useEffect } from "react";
import {
  Modal,
  Fade,
  Paper,
  Typography,
  Grid,
  Button,
  Divider,
} from "@mui/material";
import styled from "styled-components";

const TimeoutModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200;
  min-height: 200;
`;

const ModalContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  max-width: 70%;
  height: 628px%;
  padding: 30px;

  &:focus {
    outline: none;
  }
`;

const Timeout = (props: any) => {
  const { logout, setShowTimeout } = props;
  const signoutTime = 900000;

  useEffect(() => {
    const logoutTimeout = setTimeout(logout, signoutTime);

    return () => clearTimeout(logoutTimeout);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRefresh = () => {
    setShowTimeout(false);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    // TODO: Research if Modal is the right component to use here. Seems overkill as we only want the styles.
    <TimeoutModal open closeAfterTransition>
      <Fade in>
        <ModalContainer>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} container justifyContent="center">
              <Typography variant="h5">Idle Timeout</Typography>
            </Grid>
            <Divider />
            <Grid item xs={12} container justifyContent="center">
              <Typography variant="body1">
                The session has been idle for 1 hour and 45 minutes. You will be
                logged out in 15 minutes.
              </Typography>
            </Grid>
            <Grid item xs={4} container justifyContent="space-between">
              <Button onClick={handleRefresh}>Stay logged in</Button>
              <Button onClick={handleLogout}>Logout</Button>
            </Grid>
          </Grid>
        </ModalContainer>
      </Fade>
    </TimeoutModal>
  );
};

export default Timeout;
