import React from "react";
import Highcharts, { Options } from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { chartPalette } from "@amx/component-library";

import { PortfolioFundType } from "../../root/dashboard/types";

// TODO: TypeScript conversion - Figure out how to use keyOf to make
//       groupBy use a valid key of PortfolioFundType
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-1.html
const generateData = (
  rows: PortfolioFundType[],
  groupBy: string,
  title?: string,
  onSegmentClick?: (selectedSegment: string) => void
): Options | false => {
  if (rows.length === 0) return {};

  const categoryCounts = rows.reduce((previous: any, current: any) => {
    if (previous[current[groupBy]] === undefined) {
      return { ...previous, [current[groupBy]]: current.systemCurrencyAum };
    }

    return {
      ...previous,
      [current[groupBy]]:
        previous[current[groupBy]] + current.systemCurrencyAum,
    };
  }, {});

  const seriesData = Object.keys(categoryCounts).map((key, index) => ({
    name: key,
    color: chartPalette[index],
    y: categoryCounts[key],
  }));

  if (seriesData.length === 1 && seriesData[0].y === 0) return false;

  return {
    colors: chartPalette,
    credits: {
      enabled: false,
    },
    chart: {
      height: 220,
    },
    plotOptions: {
      pie: {
        size: "55%",
      },
      series:
        onSegmentClick !== undefined && seriesData.length > 1
          ? {
              cursor: "pointer",
              allowPointSelect: true,
              events: {
                click: (event) => {
                  onSegmentClick(event.point.name);
                },
              },
            }
          : undefined,
    },
    title: { text: title || "" },
    tooltip: {
      formatter: function () {
        const key = this.key;
        const fundCount = rows.reduce((prev: any, current: any) => {
          if (current[groupBy] === key) {
            return prev + 1;
          }
          return prev;
        }, 0);
        const percentage = this.point.percentage?.toFixed(2);
        const value = parseFloat(
          this.point.y === undefined ? "0" : this.point.y.toFixed(0)
        );
        const total = parseFloat(
          this.point.total === undefined ? "0" : this.point.total.toFixed(0)
        );

        if (percentage === "100.00") {
          return `<b>${key}<b><br>${percentage} %<br>USD ${value?.toLocaleString()}<br>(${fundCount} funds)`;
        }

        return `<b>${key}<b><br>${percentage} %<br>USD ${value?.toLocaleString()} of USD ${total?.toLocaleString()}<br>(${fundCount} funds)`;
      },
    },
    series: [
      {
        name: "Count",
        type: "pie",

        data: seriesData,
      },
    ],
  };
};

const PieChart = (
  props: {
    data: PortfolioFundType[];
    title?: string;
    category: string;
    onSegmentClick?: (selectedSegment: string) => void;
  } = { data: [], title: "", category: "" }
) => {
  if (!props || props.data.length === 0 || props.category === "")
    return <div></div>;

  const chartData = generateData(
    props.data,
    props.category,
    props.title,
    props.onSegmentClick
  );

  if (chartData === false || chartData.series!.length === 0) return <div></div>;

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartData}
      containerProps={{ style: { overflow: "visible" } }}
    />
  );
};

export default PieChart;
