import { SxProps, Theme } from "@mui/system";

export const AumContainer: SxProps<Theme> = {
  display: "inline-flex",
  alignItems: "center",
  fontSize: "34px",
  position: "relative",

  "& .MuiInputBase-root": {
    fontSize: "inherit",
  },
};

export const CurrencySelect: SxProps<Theme> = {
  fontSize: "1.7vw",
  fontWeight: 400,
  lineHeight: "1.334",

  "&:before": {
    display: "none",
  },
};

export const ChartContainer: SxProps<Theme> = {
  padding: "10px 0 30px",
};

export const FundHeaderContainer: SxProps<Theme> = {
  margin: "40px 0 10px",
  padding: "10px 0",
};
