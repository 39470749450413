export const getHashLinkData = () => {
  return window.location.hash
    .slice(1)
    .split("&")
    .reduce((hashes: any, curr: string) => {
      const currSplit = curr.split("=");
      if (currSplit.length !== 2) return hashes;

      const currObj = { [currSplit[0]]: decodeURIComponent(currSplit[1]) };

      return {
        ...hashes,
        ...currObj,
      };
    }, {});
};
