import React, { useState, ReactNode } from "react";
import { useStore } from "../../providers";
import Store from "./Store";

import {
  Box,
  Button,
  Link,
  Dialog,
  Grid,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  Language as LanguageIcon,
  MailOutline as MailOutlineIcon,
  PhoneOutlined as PhoneOutlinedIcon,
} from "@mui/icons-material";

import MarkdownPreview from "@uiw/react-markdown-preview";

const titleTextInfo = "This is part of the AMX package";
const titleTextDeactivate = "Would You Like to Deactivate This Service";

const bodyTextInfo = `
This is a service that AMX provides to you as part of your package.`;
const bodyTextEnquire = `
AMX is working to provide this service to our clients.
Please contact Client Services for more information.`;
const bodyTextActivate = `
You do not currently have this service activated. If you would like to start using
it, please click the "Approve" button. You will be charged for this service.`;
const bodyTextDeactivate = `
This service is currently activated. If you would like to stop using
it, please click the "Approve" button. You will no longer be charged for this service.`;

export type PopUpConfig = {
  show: boolean;
  type: "info" | "enquire" | "activate";
  image: null;
  titleText: string;
  bodyText: string | ReactNode;
  website: string;
  email: string;
  telephone: any[];
  telephoneItems: boolean;
  serviceDomain: string;
  subServiceDomain: string;
  provider: string;
  selected: boolean;
  proposedState: any[];
};

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    backgroundColor: "#0498CE",
    color: "#fff",
  },

  showOwlPopUp: {
    padding: "5em 1em 1em",
  },

  owlPopUpBody: {
    padding: "0 6em 3em 6em",
  },

  dialogPaper: {
    minWidth: "80%",
  },

  dialogActions: {
    justifyContent: "space-between",
    flexDirection: "row-reverse",
    padding: "1em",
  },

  imageDescription: {
    padding: "2em",
    "& img": {
      width: "100%",
      maxHeight: "200px",
    },
  },

  contact: {
    "& ul": {
      marginRight: "10px",
      listStyleType: "none",
      padding: "0 0 0 5px",
      margin: 0,
    },
    "& p": {
      fontSize: "clamp(14px, 1.8vw, 1.3em)",
    },
  },

  tickIcon: {
    fill: "green",
    width: "1.5em",
    height: "1.5em",
    marginBottom: "1em",
  },

  contactIcon: {
    marginRight: "10px",
  },

  contactInfo: {
    paddingTop: "1em",
    justifyContent: "space-between",
  },

  dialogContent: {
    overflowY: "initial",
    fontSize: "clamp(14px, 1.6vw, 16px)",
  },

  dialogWrapper: {
    "& .wmde-markdown": {
      fontFamily: ["Roboto", "sans-serif"],
      fontSize: "inherit",
      color: "#757575",
    },

    "& .wmde-markdown h1": {
      fontSize: "clamp(18px, 1.6vw, 1.6em)",
      borderColor: "#9D9FB2",
    },

    "& .wmde-markdown h2": {
      paddingBottom: "1em",
      borderColor: "#9D9FB2",
      fontSize: "clamp(16px, 1.6vw, 1.3em)",
    },

    "& .wmde-markdown h3": {
      fontSize: "clamp(14px, 1.6vw, 1.2em)",
    },

    "& .wmde-markdown ul": {
      fontSize: "0.8em",
    },

    "& .wmde-markdown a": {
      color: "#757575",
    },
  },

  popUpContainer: {
    flexDirection: "column",

    "@media (min-width: 767px)": {
      flexDirection: "row",
    },
  },

  popUplogo: {
    justifyContent: "center",
    display: "flex",
    fontSize: "clamp(12px, 1.042vw, 16px)",
  },

  dialogTitle: {
    "& > h2": {
      fontSize: "clamp(12px, 1.042vw, 16px)",
    },
  },
}));

const StoreContainer = (props: {
  serviceDomains: any[];
  fundSelected: any;
  storeRef: any;
}) => {
  const { serviceDomains, fundSelected, storeRef } = props;
  const classes = useStyles();

  // TODO: Add this back in when we have a new ESG provider.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { storeData, setStoreData } = useStore();

  const [popUp, setPopUp] = useState<PopUpConfig>({
    show: fundSelected,
    type: "activate",
    image: null,
    website: "",
    email: "",
    telephone: [],
    telephoneItems: false,
    titleText: "",
    bodyText: bodyTextActivate,
    serviceDomain: "Sustainable Finance",
    subServiceDomain: "ESG Data",
    provider: "",
    selected: false,
    proposedState: [],
  });

  const handleServiceClick = (
    selectedProvider: number,
    selectedSubServiceDomain: number,
    selectedServiceDomain: number,
    item: any
  ) => {
    let titleText;
    let bodyText;

    if (item.interactionType === "info") {
      titleText = titleTextInfo;
    } else if (item.selected) {
      titleText = titleTextDeactivate;
    } else {
      titleText = "";
    }

    if (item?.markdown) {
      bodyText = <MarkdownPreview source={item.markdown} />;
    } else if (item.interactionType === "info") {
      bodyText = bodyTextInfo;
    } else if (item.interactionType === "enquire") {
      bodyText = bodyTextEnquire;
    } else if (item.selected) {
      bodyText = bodyTextDeactivate;
    } else {
      bodyText = bodyTextActivate;
    }

    if (
      serviceDomains[selectedServiceDomain]?.serviceDomainName ===
      "Irish Fund Vehicles"
    ) {
      bodyText = (
        <>
          {bodyText}
          <br />
          <br />
          <strong>
            For more information on this AMX Irish regulated fund vehicle please
            reach out to{" "}
            <a href="mailto:amx.service@carnegroup.com">
              amx.service@carnegroup.com
            </a>
          </strong>
        </>
      );
    }

    setPopUp({
      show: true,
      type: item.interactionType,
      image: item?.image ? <img src={item.image} alt="" /> : item.itemName,
      titleText,
      bodyText,
      website: item?.website ? item.website : null,
      email: item?.email ? item.email : null,
      telephone: item?.telephone ? item.telephone : null,
      telephoneItems: item?.telephone?.filter((item: any) => item).length > 0,
      serviceDomain: serviceDomains[selectedServiceDomain]?.serviceDomainName,
      subServiceDomain:
        serviceDomains[selectedServiceDomain]?.subServiceDomains[
          selectedSubServiceDomain
        ].subServiceDomainName,
      provider: item.itemName,
      selected: !item.selected,
      proposedState: serviceDomains.map(
        (serviceDomain: any, serviceDomainIndex: number) => ({
          ...serviceDomain,
          // TODO: Add this back in when we have a new ESG provider.
          using: serviceDomain.using,
          // using: serviceDomains[selectedServiceDomain].subServiceDomains[
          //     selectedSubServiceDomain
          //   ].providers[selectedProvider].itemName === "OWL Analytics" &&
          //   serviceDomain.serviceDomainName === "Sustainable Finance"
          //     ? preferences.allOwlSelected
          //       ? item.selected
          //         ? 1
          //         : 2
          //       : 1
          //     : serviceDomain.using,
          subServiceDomains: serviceDomain.subServiceDomains.map(
            (subService: any, subServiceDomainIndex: number) => ({
              ...subService,
              providers: subService.providers.map(
                (provider: any, providerIndex: number) => ({
                  ...provider,
                  selected:
                    serviceDomainIndex === selectedServiceDomain &&
                    subServiceDomainIndex === selectedSubServiceDomain &&
                    providerIndex === selectedProvider
                      ? !provider.selected
                      : provider.selected,
                })
              ),
            })
          ),
        })
      ),
    });

    if ("parentIFrame" in window) {
      (window as any).parentIFrame.scrollToOffset(0, 0);
    }
  };

  const handleDialogClose = () => {
    setPopUp({ ...popUp, show: false, proposedState: [] });
    if (storeRef) {
      const cardPosition = document
        ?.getElementById(storeData.cardId)
        ?.getBoundingClientRect().top;

      if ("parentIFrame" in window) {
        (window as any).parentIFrame.scrollToOffset(
          0,
          cardPosition ? cardPosition - 190 : 0
        );
      }
    }
  };

  return (
    <>
      <Store categories={serviceDomains} onServiceClick={handleServiceClick} />
      <Dialog
        open={popUp.show}
        onClose={handleDialogClose}
        PaperProps={{
          style: {
            position: storeRef ? "fixed" : "relative",
            top: storeRef ? 180 : "auto",
            margin: 0,
          },
        }}
        fullWidth
        classes={{ paperFullWidth: classes.dialogPaper }}
        className={classes.dialogWrapper}
      >
        <Grid container className={classes.popUpContainer}>
          <Grid item xs={12} md={3} className={classes.popUplogo}>
            <div className={classes.imageDescription}>{popUp.image}</div>
          </Grid>
          <Grid container item xs={12} md={9} direction="column">
            {popUp.titleText ? (
              <DialogTitle className={classes.dialogTitle}>
                {popUp.titleText}
              </DialogTitle>
            ) : null}
            <DialogContent className={classes.dialogContent}>
              {popUp.bodyText}
              <Grid
                container
                spacing={3}
                className={classes.contactInfo}
                alignItems="center"
              >
                {popUp.website !== null && (
                  <Grid item>
                    <Box
                      display="flex"
                      className={classes.contact}
                      alignItems="center"
                    >
                      <LanguageIcon className={classes.contactIcon} />
                      <Link href={`http://${popUp.website}`} target="_blank">
                        <Typography component="p">{popUp.website}</Typography>
                      </Link>
                    </Box>
                  </Grid>
                )}
                {popUp.email !== null && (
                  <Grid item>
                    <Box
                      display="flex"
                      className={classes.contact}
                      alignItems="center"
                    >
                      <MailOutlineIcon className={classes.contactIcon} />
                      <ul>
                        {popUp.email.split(",").map((email: string) => (
                          <li key={email}>
                            <Link href={`mailto://${email}`}>
                              <Typography component="p">{email}</Typography>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  </Grid>
                )}
                {popUp.telephone !== null && popUp.telephoneItems ? (
                  <Grid item>
                    <Box
                      display="flex"
                      className={classes.contact}
                      alignItems="center"
                    >
                      <PhoneOutlinedIcon className={classes.contactIcon} />
                      <ul>
                        {popUp.telephone.map((telNumber: any) => (
                          <li>
                            <Typography component="p">{telNumber}</Typography>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  </Grid>
                ) : null}
              </Grid>
            </DialogContent>
            <DialogActions classes={{ root: classes.dialogActions }}>
              <Button
                onClick={handleDialogClose}
                color="default"
                variant="contained"
              >
                {popUp.type === "activate" ? "Cancel" : "Close"}
              </Button>
              {/* // TODO: Add this back in when we have a new ESG provider. */}
              {/* {fund === undefined &&
                  popUp.type === "activate" &&
                  !preferences.allOwlSelected ? (
                    <Button
                      onClick={() => handleDialogApprove.mutate("addAll")}
                      autoFocus
                      variant="contained"
                      className={classes.primaryButton}
                      disabled={
                        user?.userType === UserBehaviour.serviceProvider
                      }
                    >
                      Add for all Funds
                    </Button>
                  ) : null}

                  {fund === undefined &&
                  popUp.type === "activate" &&
                  (preferences.allOwlSelected ||
                    preferences.fundLevelOwlSelected) ? (
                    <Button
                      onClick={() => setShowConfirmPopUp(true)}
                      autoFocus
                      variant="contained"
                      color="default"
                      disabled={
                        user?.userType === UserBehaviour.serviceProvider
                      }
                    >
                      Remove for all Funds
                    </Button>
                  ) : null}
                  {(fund !== undefined && popUp.type) === "activate" ? (
                    <Button
                      onClick={() => handleDialogApprove.mutate("addSingle")}
                      autoFocus
                      variant="contained"
                      className={classes.primaryButton}
                      disabled={
                        user?.userType === UserBehaviour.serviceProvider
                      }
                    >
                      Add for selected fund
                    </Button>
                  ) : null} */}
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
      {/* // TODO: Add this back in when we have a new ESG provider. */}
      {/* <Dialog open={showConfirmPopUp}>
            <DialogTitle>Delete OWL Analytics for All Funds</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to remove OWL Analytics reporting for all
                of your funds?
              </DialogContentText>
              <DialogContentText>
                You can add OWL reporting again at a later date, if you change
                your mind.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleDialogApprove.mutate("removeAll")}>
                Yes
              </Button>
              <Button
                color="primary"
                autoFocus
                onClick={() => setShowConfirmPopUp(false)}
              >
                No
              </Button>
            </DialogActions>
          </Dialog> */}

      {/* // TODO: Add this back in when we have a new ESG provider. */}
      {/* <Dialog open={showAddOwlPopUp}>
            <div className={classes.showOwlPopUp}>
              <Box justifyContent="center" display="flex">
                <CheckCircleRoundedIcon classes={{ root: classes.tickIcon }} />
              </Box>
              <Box
                justifyContent="center"
                display="flex"
                className={classes.owlPopUpBody}
              >
                <Typography variant="h6">
                  OWL ANALYTICS ESG DATA HAS BEEN ADDED
                </Typography>
              </Box>
              <DialogActions>
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => setshowAddOwlPopUp(false)}
                >
                  OK
                </Button>
              </DialogActions>
            </div>
          </Dialog> */}
    </>
  );
};

export default StoreContainer;
