import React, { useState } from "react";
import { useHistory, Switch, Route } from "react-router-dom";
import styled from "styled-components";

import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useAuth, useTelemetry } from "@amx/common-frontend";
import {
  amxPalette,
  ThingamabobOptions,
  View,
  ViewHeader,
} from "@amx/component-library";

import { Box, IconButton, Typography } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";

import LatestAvailable from "./available/LatestAvailable";
import LastDealing from "./last-dealing/LastDealing";
import Ribbon from "../../components/Dashboard/Ribbon";
import { PortfolioFundType } from "./types";
import { convertToPortfolio } from "./dashboard.api";

import "./Dashboard.css";

// BANNER CONFIG
const colourScheme = {
  backgroundColor: amxPalette.pink,
  foregroundColor: amxPalette.white_100,
};

const colourSchemeInverse = {
  backgroundColor: amxPalette.white_100,
  foregroundColor: amxPalette.pink,
};

const thingamabobsConfig = [
  { type: ThingamabobOptions.head, ...colourScheme },
  { type: ThingamabobOptions.chest, ...colourScheme },
  { type: ThingamabobOptions.legs, ...colourSchemeInverse },
  { type: ThingamabobOptions.bum, ...colourSchemeInverse },
];

const DashboardBox = styled(Box)`
  -ms-flex: 0 0 auto;
`;
const PageTitle = styled(Typography)`
  -ms-flex: 0 0 auto;
  display: flex;
`;

const StandardDashboard = (props: {
  investorName: string;
  investorId?: number | string;
  advisorTpaIds?: string;
  filteringFunds?: boolean;
  esgReportStatus?: string; // "enabled" | "disabled"
}) => {
  const { axiosWithAuth } = useAuth();
  const appInsights = useAppInsightsContext();
  const { handleClickTracking } = useTelemetry(appInsights);

  const [totals, setTotals] = useState({ marketValue: 0, rowCount: 0 });
  const [showZeroBalance, setShowZeroBalance] = useState<boolean>(false);
  const [currencyList, setCurrencyList] = useState<string[]>(["USD"]);
  const [preferredCcy, setPreferredCcy] = useState<string>("USD");
  const dashboardDataState = useState([] as PortfolioFundType[]);
  const setData = dashboardDataState[1];

  const { investorId, advisorTpaIds } = props;

  const handleMarketValueChange = (marketValue: number, rowCount: number) => {
    setTotals({ marketValue, rowCount });
  };

  const handleZeroBalanceChange = (isZero: boolean) => {
    setShowZeroBalance(isZero);
  };

  const handleFetchInvestorData = async (
    navType: string = "All",
    preferredCcy?: string
  ) => {
    if (axiosWithAuth === undefined) return;

    const responseData = await axiosWithAuth({
      url: "/investorPortfolio",
      params: { navType, investorId, tpaIds: advisorTpaIds, preferredCcy },
    });

    const response = convertToPortfolio(responseData.investments).filter(
      (record: PortfolioFundType) =>
        showZeroBalance || record.systemCurrencyAum > 0
    );

    if (response.length > 0) {
      handleMarketValueChange(responseData.totalMarketValue, response.length);
      setCurrencyList(responseData.currencyList);
      setPreferredCcy(responseData.preferredCcy || "USD");
    } else {
      handleMarketValueChange(0, response.length);
      setCurrencyList(["USD"]);
      setPreferredCcy("USD");
    }

    setData(response);
  };

  return (
    <View
      style={{ display: "flex", flexDirection: "column" }}
      className="Dashboard"
      data-trackable-context="Dashboard"
      onClick={handleClickTracking}
    >
      <ViewHeader
        subtitle="Dashboard"
        title="Investment Portfolio"
        thingamabobsConfig={thingamabobsConfig}
        style={{ marginBottom: "40px", background: amxPalette.pink }}
        height={133}
        smallText={true}
      />

      <Ribbon
        currencyList={currencyList}
        preferredCcy={preferredCcy}
        marketValue={totals.marketValue}
        rowCount={totals.rowCount}
        onZeroBalanceChange={handleZeroBalanceChange}
        showZeroBalance={showZeroBalance}
        onPreferredCcyChange={handleFetchInvestorData}
      />
      <DashboardBox>
        <Switch>
          <Route path="/dashboard/last-dealing">
            <LastDealing
              investorId={investorId}
              advisorTpaIds={advisorTpaIds}
              showZeroBalance={showZeroBalance}
              dataState={dashboardDataState}
              handleFetchInvestorData={handleFetchInvestorData}
            />
          </Route>
          <Route path={["/", "/dashboard", "dashboard/available"]}>
            <LatestAvailable
              investorId={investorId}
              advisorTpaIds={advisorTpaIds}
              showZeroBalance={showZeroBalance}
              esgReportStatus={props.esgReportStatus}
              dataState={dashboardDataState}
              handleFetchInvestorData={handleFetchInvestorData}
            />
          </Route>
        </Switch>
      </DashboardBox>
    </View>
  );
};

export default StandardDashboard;
