import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router";

import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Search as SearchIcon, Clear as ClearIcon } from "@mui/icons-material";

import { sortByKey } from "../../helpers/sortingHelpers";
import { getHashLinkData } from "../../helpers/urlHelpers";

import StoreTabs from "./StoreTabs";
import StoreSearch from "./StoreSearch";

const useStyles = makeStyles((theme) => ({
  tabPanelWrap: {
    marginTop: "1em",
    padding: "0 1em",
  },

  searchFilterWrap: {
    padding: "0 1em",
    position: "relative",
  },

  searchFilter: {
    marginTop: "2em",
    marginBottom: "2em",

    "& button": {
      alignSelf: "flex-start",
    },
  },

  searchLabelShow: {
    top: "10px",
    left: "32px",
    opacity: 1,
  },

  searchLabelHide: {
    top: "0",
    opacity: 0,
  },

  searchBar: {
    "& > label": {
      transition: "all .25s ease-in-out",
      fontSize: "16px",
      paddingRight: "2em",

      "@media (max-width: 480px)": {
        top: "-2px",
      },

      "&.Mui-focused": {
        color: "#5B5F76",
      },
    },
  },

  searchInput: {
    fontSize: "16px",
  },

  cardWrapper: {
    paddingBottom: "2.8em",
    marginBottom: "1em",
    borderBottom: "1px solid #cdcdcd",
  },

  cardInfo: {
    paddingBottom: "2em",
  },

  selectFilter: {
    minWidth: "100%",
    color: "#5B5F76",
  },

  selectInputLabel: {
    color: "#5B5F76",
    "&.Mui-focused": {
      color: "#5B5F76",
    },
    fontSize: "1em",
  },

  chipWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },

  chip: {
    margin: "0 2px 2px 0",
    backgroundColor: "#8B8C8C",
    color: "#fff",
    "& > span": {
      overflow: "unset",
    },
  },

  filterResetButton: {
    position: "absolute",
    right: 0,
    top: "70px",
  },

  selectList: {
    fontSize: "clamp(14px, 1.042vw, 16px)",
  },

  inputField: {
    fontSize: "16px",
  },
}));

const Store = (props: { categories: any[]; onServiceClick: Function }) => {
  const { categories, onServiceClick } = props;
  const classes = useStyles();
  const location = useLocation();

  const [urlHashData, setUrlHashData] = useState<any>();

  useEffect(() => {
    const urlHashData = getHashLinkData();

    if (urlHashData.category) {
      const categoryEl: HTMLElement | null = document.querySelector(
        `[data-category-name="${urlHashData.category}"]`
      );

      setTimeout(() => {
        categoryEl?.scrollIntoView({
          behavior: "smooth",
        });
      }, 0);
    }

    setUrlHashData(urlHashData);
  }, [location]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredResults, setFilteredResults] = useState<any>([]);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [serviceList, setServiceList] = useState<any>([]);
  const [selectedCategories, setSelectedCategories] = React.useState<string[]>(
    []
  );
  const [selectedServices, setSelectedServices] = React.useState<string[]>([]);
  const hideDefaultText = searchTerm.length > 0;

  let catList: any = [];
  let servList: any = [];
  let subFilteredList: any = [];

  const fullList = categories.map((service: any, categoryIndex: number) => {
    catList = [...catList, service.serviceDomainName];
    return service.subServiceDomains.map(
      (sbService: any, serviceIndex: number) => {
        servList = [...servList, sbService.subServiceDomainName];
        return sbService.providers.map(
          (provider: any, providerIndex: number) => {
            return {
              category: service.serviceDomainName,
              service: sbService.subServiceDomainName,
              provider: provider,
              categoryIndex: categoryIndex,
              serviceIndex: serviceIndex,
              providerIndex: providerIndex,
            };
          }
        );
      }
    );
  });

  const tempList = [].concat.apply([], fullList);
  const fullSearchList = [].concat.apply([], tempList);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    let searchValue = event.target.value.toLowerCase();

    if (searchValue === "") {
      if (!selectedCategories.length && !selectedServices.length) {
        setFilteredResults([]);
      } else {
        handleServiceChange(selectedServices);
      }
    } else {
      updateSearchFilterList(searchValue);
    }
  };

  const updateSearchFilterList = (searchValue: string = "") => {
    let categoryResult,
      serviceResult = [];

    if (!selectedCategories.length && !selectedServices.length) {
      categoryResult = fullSearchList.filter((data: any) => {
        return data!.category.toLowerCase().search(searchValue) !== -1;
      });
      serviceResult = fullSearchList.filter((data: any) => {
        return data!.service.toLowerCase().search(searchValue) !== -1;
      });
    } else {
      categoryResult = filteredResults.filter((data: any) => {
        return data!.category.toLowerCase().search(searchValue) !== -1;
      });
      serviceResult = filteredResults.filter((data: any) => {
        return data!.service.toLowerCase().search(searchValue) !== -1;
      });
    }

    const mergedResult = [...new Set([...categoryResult, ...serviceResult])];

    setFilteredResults(mergedResult);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    handleServiceChange(selectedServices);
  };

  /* update Service select list */
  const updateServiceList = (itemList: any) => {
    let updatedList: any = [];
    // selected categories
    if (itemList.length) {
      itemList.forEach((category: any) => {
        fullSearchList.forEach((item: any) => {
          if (item.category.toLowerCase() === category.toLowerCase()) {
            updatedList = [...updatedList, item.service];
          }
        });
      });
    } else {
      updatedList = servList.slice();
    }

    setServiceList([...new Set(updatedList)]);
  };

  const updateCategoryList = (itemList: any) => {
    let updatedList: any = [];

    if (itemList.length) {
      itemList.forEach((service: any) => {
        fullSearchList.forEach((item: any) => {
          if (item.service.toLowerCase() === service.toLowerCase()) {
            updatedList = [...updatedList, item.category];
          }
        });
      });
    } else {
      updatedList = catList.slice();
    }
    setCategoryList([...new Set(updatedList)]);
  };

  const filterByCategory = (categories: any) => {
    let updatedList: any = [];

    for (const cat of categories as string[]) {
      updatedList = [
        ...updatedList,
        fullSearchList.filter(
          (item: any) => item.category.toLowerCase() === cat.toLowerCase()
        ),
      ];
    }

    return updatedList;
  };

  const filterByService = (
    services: any,
    filteredByCategory: boolean = false
  ) => {
    let updatedList: any = [];

    for (const serv of services as string[]) {
      updatedList = [
        ...updatedList,
        filteredByCategory
          ? subFilteredList.filter(
              (item: any) => item.service.toLowerCase() === serv.toLowerCase()
            )
          : fullSearchList.filter(
              (item: any) => item.service.toLowerCase() === serv.toLowerCase()
            ),
      ];
    }
    return updatedList;
  };

  const handleCategoryChange = (categories: any) => {
    let updatedList: any = [];
    setSearchTerm("");
    setSelectedCategories(categories as string[]);

    // got through each category and filter on main result
    if (categories.length) {
      updateServiceList(categories);
      updatedList = filterByCategory(categories);
    } else {
      updatedList = filterByService(selectedServices, false);
    }

    setFilteredResults([].concat.apply([], updatedList));
    if (selectedServices.length) {
      subFilteredList = [].concat.apply([], updatedList);
      updatedList = filterByService(selectedServices, true);
      setFilteredResults([].concat.apply([], updatedList));
    }
  };

  const handleServiceChange = (services: any) => {
    let updatedList: any = [];
    setSearchTerm("");
    let filteredListFlag: boolean = false;
    setSelectedServices(services as string[]);

    if (selectedCategories.length) {
      // filter by category first
      filteredListFlag = true;
      updatedList = filterByCategory(selectedCategories);
      subFilteredList = [].concat.apply([], updatedList);
    }
    if (services.length) {
      updateCategoryList(services);
      updatedList = filterByService(services, filteredListFlag);
    }

    setFilteredResults([].concat.apply([], updatedList));
  };

  const handleCatgoryChipDelete = (chip: any) => {
    let newList = [...selectedCategories].filter(
      (category) => category !== chip
    );
    handleCategoryChange(newList);
  };

  const handleServiceChipDelete = (chip: any) => {
    let newList = [...selectedServices].filter((service) => service !== chip);
    handleServiceChange(newList);
  };

  const resetFilters = () => {
    setSelectedCategories([]);
    setSelectedServices([]);
    setFilteredResults([]);
  };

  useEffect(() => {
    setCategoryList(catList);
    setServiceList(servList);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedCategories.length) {
      setServiceList(servList);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategories]);

  useEffect(() => {
    if (!selectedServices.length) {
      setCategoryList(catList);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedServices]);

  return (
    <div data-trackable-context="Store">
      <Box className={classes.searchFilterWrap}>
        <Grid container spacing={4} className={classes.searchFilter}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                className={classes.searchBar}
                fullWidth={true}
                label="Type to search by Categories or Services &amp; Products"
                InputLabelProps={{
                  shrink: hideDefaultText,
                  className: hideDefaultText
                    ? classes.searchLabelHide
                    : classes.searchLabelShow,
                }}
                InputProps={{
                  classes: {
                    input: classes.searchInput,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton onClick={() => handleClearSearch()}>
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
                type="text"
                value={searchTerm}
                onChange={handleSearch}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            {selectedServices.length || selectedCategories.length ? (
              <Button
                className={classes.filterResetButton}
                onClick={(e) => {
                  resetFilters();
                }}
              >
                Reset
              </Button>
            ) : null}
            <FormControl className={classes.selectFilter}>
              <InputLabel
                className={classes.selectInputLabel}
                id="select-category-label"
              >
                Categories
              </InputLabel>
              <Select
                labelId="select-category-label"
                multiple
                id="select-category"
                classes={{
                  select: classes.inputField,
                }}
                value={selectedCategories}
                onChange={(event) => handleCategoryChange(event.target.value)}
                input={<Input id="select-category-input" />}
                renderValue={(selected) => (
                  <div className={classes.chipWrapper}>
                    {(selected as string[]).map((value, index) => (
                      <Chip
                        key={`chip-${value}-${index}`}
                        label={value}
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                        onDelete={() => handleCatgoryChipDelete(value)}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: "200px",
                      width: "auto",
                    },
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {categoryList.sort().map((name: any, index: number) => (
                  <MenuItem
                    key={`catselect-${name}-${index}`}
                    value={name}
                    className={classes.selectList}
                    classes={{
                      root: classes.selectList,
                    }}
                  >
                    <Checkbox checked={selectedCategories.includes(name)} />
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.selectFilter}>
              <InputLabel
                id="select-service-label"
                className={classes.selectInputLabel}
              >
                Services &amp; Products
              </InputLabel>
              <Select
                labelId="select-service-label"
                id="select-service"
                multiple
                value={selectedServices}
                classes={{
                  select: classes.inputField,
                }}
                onChange={(event) => handleServiceChange(event.target.value)}
                input={<Input id="select-service" />}
                renderValue={(selected) => (
                  <div className={classes.chipWrapper}>
                    {(selected as string[]).map((value, index) => (
                      <Chip
                        key={`chip-${value}-${index}`}
                        label={value}
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                        onDelete={() => handleServiceChipDelete(value)}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: "200px",
                      width: "auto",
                    },
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {serviceList.sort().map((name: any, index: number) => (
                  <MenuItem
                    key={`serviceselect-${name}-${index}`}
                    value={name}
                    classes={{
                      root: classes.selectList,
                    }}
                  >
                    <Checkbox checked={selectedServices.includes(name)} />
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      {filteredResults.length ? (
        <Box className={classes.searchFilterWrap}>
          <StoreSearch
            filteredResults={filteredResults}
            onServiceClick={onServiceClick}
          />
        </Box>
      ) : (
        categories
          .sort((a: any, b: any) => sortByKey(a, b, "serviceDomainName"))
          .map((category, categoryIndex) => {
            return (
              <div
                key={`${category}-${categoryIndex}`}
                className={classes.tabPanelWrap}
              >
                <StoreTabs
                  category={category}
                  onServiceClick={onServiceClick}
                  categoryIndex={categoryIndex}
                  urlHashData={urlHashData}
                />
              </div>
            );
          })
      )}
    </div>
  );
};

export default Store;
