import { SxProps, Theme } from "@mui/system";
import { amxPalette } from "@amx/component-library";

export const AumContainer: SxProps<Theme> = {
  display: "inline-flex",
  alignItems: "center",
  fontSize: "34px",
  position: "relative",

  "& .MuiInputBase-root": {
    fontSize: "inherit",
  },
};

export const CurrencySelect: SxProps<Theme> = {
  fontSize: "1.7vw",
  fontWeight: 400,
  lineHeight: "1.334",

  "&:before": {
    display: "none",
  },
};

export const ChartContainer: SxProps<Theme> = {
  padding: "10px 0 30px",
};

export const FundHeaderContainer: SxProps<Theme> = {
  margin: "40px 0 10px",
  padding: "10px 0",
};

export const InvestorHeaderContainer: SxProps<Theme> = {
  padding: "16px 20px",
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

export const InvestorContainer: SxProps<Theme> = {
  width: "100%",
  margin: "24px 0",
  background: "white",
  border: "1px solid",
  borderColor: amxPalette.grey_10,
};

export const ButtonsContainer: SxProps<Theme> = {
  width: "100%",
  padding: "16px 20px",
  display: "flex",
  justifyContent: "flex-end",
};

export const ButtonLinks: SxProps<Theme> = {
  padding: 0,
  fontSize: "16px",
  fontWeight: "normal",
  "&:first-child": {
    marginRight: "40px",
  },

  "&, &:-webkit-any-link": {
    color: amxPalette.grey_50,
  },

  "&:hover": {
    color: amxPalette.pink,
  },
};
