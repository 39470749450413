import { amxPalette } from "@amx/component-library";
import { SxProps, Theme, keyframes } from "@mui/system";

export const InputContainer: SxProps<Theme> = {
  display: "flex",
  flexFlow: "row wrap",
  alignItems: "center",
  width: "100%",

  marginBottom: "16px",
  color: amxPalette.black_80,
  transition: "all 0.3s",

  "&.has-upload": {
    height: "auto",
    marginBottom: 0,
  },

  "&.error": {
    marginBottom: "24px",
  },

  "& .MuiFormControl-root": {
    height: "40px",

    "& .MuiOutlinedInput-root": {
      height: "100%",
    },
  },
  "& .MuiSelect-select": {
    padding: "0",
  },
  "& .MuiSvgIcon-root": {
    height: "24px",
    width: "16px",
    right: "16px",
    color: amxPalette.black_50,

    "& :hover": {
      backgroundColor: "none",
    },
  },

  "& .MuiAutocomplete-root": {
    "&.error": {
      border: `1px solid ${amxPalette.error}`,
      borderRadius: "3px",

      "& .MuiInputBase-root": {
        borderColor: amxPalette.error,
      },
    },
  },
  "& .MuiInputBase-root": {
    border: `1px solid ${amxPalette.grey_20}`,
    borderRadius: "3px",
    height: "40px",
    padding: 1.2,
    fontSize: "14px",
    lineHeight: "36px",

    "&.select": {
      border: "none",
    },

    "label + &": {
      marginTop: 3,
    },

    "& input": {
      fontSize: "14px",
      lineHeight: "36px",

      "&::placeholder": {
        color: amxPalette.black_30,
        fontStyle: "italic",
      },
    },

    "&.error.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: amxPalette.error,
    },
    "&.error .MuiOutlinedInput-notchedOutline": {
      borderColor: amxPalette.error,
    },
  },

  "&.assset-owner-error": {
    "& .MuiFormControl-root": {
      height: "auto",
      position: "relative",
      top: "-5px",
    },
  },

  "&.file-upload-error": {
    "& .MuiFormControl-root": {
      height: "auto",
      marginTop: "10px",
    },
  },
  "&.assset-owner-input .MuiInputBase-root": {
    border: "none",
  },
  "&.assset-owner-input": {
    marginBottom: 0,
  },
};

export const Autocomplete: SxProps<Theme> = {
  "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
    padding: 0,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
};

export const AutocompleteError: SxProps<Theme> = {
  ...Autocomplete,

  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: amxPalette.error,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: amxPalette.error,
  },
};

export const Select: SxProps<Theme> = {
  color: amxPalette.black_80,
  background: amxPalette.grey_02,

  "&.MuiOutlinedInput-root": {
    color: amxPalette.black_80,
  },
  "&.Mui-disabled": {
    color: amxPalette.black_80,
    WebkitTextFillColor: amxPalette.black_80,
  },

  "&.error.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: amxPalette.error,
  },
  "&.error .MuiOutlinedInput-notchedOutline": {
    borderColor: amxPalette.error,
  },
  "& .MuiSelect-select": {
    paddingLeft: "0",
  },
  "& .MuiSvgIcon-root": {
    fontSize: 16,
    top: "8px",
    right: "8px",
    color: amxPalette.black_50,

    "& :hover": {
      backgroundColor: "none",
    },
  },
};

export const Dropzone: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexFlow: "column",
};

export const DropzoneContainer: SxProps<Theme> = {
  width: "calc(75% - 16px)",
  position: "relative",
  height: "auto",
};

export const DropzoneHelperContainer: SxProps<Theme> = {
  marginTop: "8px",
};

export const DropzoneHelperText: SxProps<Theme> = {
  fontSize: "14px",
  fontStyle: "italic",
  lineHeight: "20px",
  color: amxPalette.black_30,
  marginTop: "8px",
};

export const Message: SxProps<Theme> = {
  fontSize: "14px",
  lineHeight: "36px",
  color: amxPalette.black_30,
  marginTop: "4px",
  span: {
    color: amxPalette.black_50,
    textDecoration: "underline",
    cursor: "pointer",
  },
};

export const InputLabel: SxProps<Theme> = {
  fontSize: "14px",
  lineHeight: "36px",
  fontWeight: "600",
  width: "25%",
  textAlign: "end",
  marginRight: "16px",
};

export const InlineFormInput: SxProps<Theme> = {
  width: "calc(75% - 16px)",
  position: "relative",
};

export const InputWrapper: SxProps<Theme> = {
  width: "calc(75% - 16px)",
  position: "relative",
};

export const FormControl: SxProps<Theme> = {
  position: "relative",
};

export const IconButton: SxProps<Theme> = {
  position: "absolute",
  " :hover": {
    backgroundColor: "transparent",
  },

  "& .MuiSvgIcon-root": {
    height: "16px",
    width: "16px",
  },
};

export const FormInput: SxProps<Theme> = {
  marginBottom: "16px",
  minWidth: 80,
  position: "relative",
};

export const ErrorContainer: SxProps<Theme> = {
  position: "relative",
  display: "flex",
  alignItems: "center",
};

export const ErrorIcon: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  marginRight: "16px",
};

export const ErrorText: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  color: amxPalette.error,
  fontSize: "12px",
  whiteSpace: "normal",

  "& a": {
    color: `${amxPalette.error} !important`,
    textDecoration: "underline !important",
  },
};

export const DialogUpload: SxProps<Theme> = {
  "& .MuiPaper-root": {
    width: "600px",

    maxWidth: "unset",
    "@media (max-width: 600px)": {
      width: "360px",
    },
    "& .MuiDialogTitle-root": {
      height: "50px",
    },
    "& .MuiDialogActions-root": {
      padding: "0",
    },
  },
};

export const FormContainer: SxProps<Theme> = {
  padding: "16px",
};

export const UploadButton: SxProps<Theme> = {
  minWidth: "100px",
  "&:disabled": {
    backgroundColor: amxPalette.grey_100,
    color: amxPalette.black_30,
  },
};

export const IconContainer: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  fontSize: "14px",
  marginBottom: "30px",

  color: amxPalette.black_100,

  "& .MuiSvgIcon-root": {
    height: "28px",
    width: "28px",

    path: {
      stroke: amxPalette.grey_100,
    },
  },
};

export const ConfirmDialog: SxProps<Theme> = {
  height: "325px",
  fontSize: "24px",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
};

export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const GearIcon: SxProps<Theme> = {
  animation: `${spin} 1s linear infinite`,
};
export const errorRed = "#FF7575";

export const UpLoadingContainer: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  position: "relative",
  top: "10px",
};

export const DialogFooter: SxProps<Theme> = {
  borderTop: `1px solid ${amxPalette.grey_05}`,

  width: "100%",
  padding: "10px 15px",
  display: "flex",
  justifyContent: "flex-end",
};
