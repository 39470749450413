import React from "react";
import Highcharts, { Options } from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { Grid, Typography } from "@mui/material";
import { chartPalette } from "@amx/component-library";

const composeChartOptions = (data: any): Options => {
  return {
    colors: chartPalette,
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    yAxis: {
      labels: {
        formatter: function () {
          return this.value + "   .."; // The yAxis sometimes gets hidden, this is a workaround to prevent that
        },
      },
    },
    series: data.chartSeries.map((line: any) => ({
      ...line,
      tooltip: {
        valueDecimals: 2,
      },
    })),
  };
};

const FundPerformanceTabContent = (props: { fundInfo: any }) => {
  const {
    fundInfo,
    fundInfo: { valuationDate },
  } = props;

  const chartData = composeChartOptions(fundInfo.fundInfo.performance);

  return (
    <Grid container className="FundPerformanceTabContent">
      <Grid item xs={12}>
        <Typography style={{ margin: "20px 0 30px 0" }} variant="h5">
          Performance (as at {valuationDate})
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartData}
          constructorType="stockChart"
        />
      </Grid>
    </Grid>
  );
};

export default FundPerformanceTabContent;
