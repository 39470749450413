import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

type DeleteCategoryDialogProps = {
  handleDeleteCategory: any;
  name?: string;
  open: boolean;
  onClose: any;
};

const DeleteCategoryDialog = ({
  handleDeleteCategory,
  name,
  onClose,
  open,
}: DeleteCategoryDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Are you sure you want to delete{" "}
        {name ? `"${name}"` : "this sub-service"} and all related providers (if
        any)?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>This action cannot be undone.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            onClose();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            handleDeleteCategory.mutate();
            onClose();
          }}
          color="primary"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCategoryDialog;
