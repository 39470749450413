import "react-app-polyfill/stable";
import "react-app-polyfill/ie9";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "typeface-roboto";
import App from "./root/App";
import SignedOutStore from "./root/SignedOutStore";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@amx/web-components";
import { AuthContextProvider } from "@amx/common-frontend";
import { StoreContextProvider } from "./providers";
import { QueryClient, QueryClientProvider } from "react-query";

// MUI X Pro License
import { LicenseInfo } from "@mui/x-license-pro";
import axios from "axios";
import { LinearProgress } from "@material-ui/core";

LicenseInfo.setLicenseKey(
  "26f5a919a5e631974a5ae58fc404f09dTz00NTg3NCxFPTE2ODcwODUxNTU4NTAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);
console.info("App: AMX Connect");

const url = document.location.toString().split("=");
const showStorePage = url[1] === "amxwebsite" ? true : false;

const queryClient = new QueryClient();

axios("/config.json").then(({ data: config }) => {
  const contextProviderConfig = {
    appAuthority: config.AMX_CONNECT_CLIENT_APP_AUTHORITY,
    baseUrl: config.AMX_CONNECT_API_URL,
    clientId: config.AMX_CONNECT_CLIENT_APP_ID,
    experimentalFeatures: config.EXPERIMENTAL_FEATURES,
    passwordResetUserFlow: config.passwordResetUserFlow,
    userFlow: config.AMX_CONNECT_CLIENT_APP_USER_FLOW,
  };

  if (!showStorePage) {
    ReactDOM.render(
      <AuthContextProvider
        config={contextProviderConfig}
        loadingComponent={<LinearProgress />}
      >
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <StoreContextProvider signedOut={false}>
              <App />
            </StoreContextProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </AuthContextProvider>,
      document.getElementById("root")
    );
  } else {
    ReactDOM.render(
      <BrowserRouter>
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
            <StoreContextProvider signedOut={true}>
              <SignedOutStore></SignedOutStore>
            </StoreContextProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </BrowserRouter>,
      document.getElementById("root")
    );
  }
});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
