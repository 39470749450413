import React, { ChangeEvent, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import {
  Typography,
  Tab,
  Tabs,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TableRow,
  Paper,
  Box,
} from "@mui/material";

import { useAuth, useTelemetry } from "@amx/common-frontend";
import { View } from "@amx/component-library";

import FundInfoTabContent from "./FundInfoTabContent";
import FundHoldingsTabContent from "./FundHoldingsTabContent";
import FundPerformanceTabContent from "./FundPerformanceTabContent";
import FundExposureTabContent from "./FundExposureTabContent";
// TODO: Add this back in when we have a new ESG provider.
// import EsgTabContent from "./EsgTabContent";
import NoFundInfoError from "./NoFundInfoError";

import "./FundDetail.css";

const handleDownload = async (token: string, fundClassSk: string) => {
  const response = await axios({
    url: "/api/fundDetail/download/history",
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { fundClassSk },
  });

  const filename = "nav_history.csv";

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(new Blob([response.data]), filename);
  } else {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    link.setAttribute("rel", "noopener noreferrer");
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }
};

const FundDetail = (props: { isAMXUser: boolean; esgReportStatus: string }) => {
  const appInsights = useAppInsightsContext();
  const { handleClickTracking } = useTelemetry(appInsights);
  const { axiosWithAuth, authState } = useAuth();
  const location = useLocation();

  // TODO: Add this back in when we have a new ESG provider.
  const [tab, setTab] = useState(() => ({ index: 0, name: "Information" }));
  const { accessToken } = authState;
  const { isAMXUser } = props;

  let fundIdFromUrl = location.pathname.match(/[0-9]+$/);

  if (fundIdFromUrl?.length > 0) {
    fundIdFromUrl = fundIdFromUrl[0];
  }

  const { data: info, isLoading } = useQuery(
    `fundInfo-${fundIdFromUrl}`,
    () => {
      if (axiosWithAuth === undefined) return;
      return axiosWithAuth({
        url: `/fundDetail/info?fundId=${fundIdFromUrl}`,
      });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000 * 60,
    }
  );

  const renderTabs = (tabName: string) => {
    const showChart = isAMXUser && info?.fundInfo.growthChartData !== undefined;

    switch (tabName.toLowerCase()) {
      case "performance":
        return <FundPerformanceTabContent fundInfo={info} />;
      case "holdings":
        return <FundHoldingsTabContent fundInfo={info} />;
      case "exposure":
        return <FundExposureTabContent fundInfo={info} />;
      // TODO: Add this back in when we have a new ESG provider.
      // case "ESG":
      //   return <EsgTabContent fund={info} />;
      default:
        return (
          <FundInfoTabContent
            fundInfo={info?.fundInfo}
            showChart={showChart}
            historyCallback={async () => {
              await handleDownload(accessToken, fundIdFromUrl);
            }}
          />
        );
    }
  };

  let canViewPerformance = info?.fundInfo.performance !== undefined;
  let canViewHoldings = info?.fundInfo.holdings !== undefined;
  let canViewExposures =
    info?.fundInfo.exposures !== undefined &&
    (info?.fundInfo.exposures.country.length > 0 ||
      info?.fundInfo.exposures.currency.length > 0 ||
      info?.fundInfo.exposures.sectorBreakdown.length > 0);

  return (
    <View
      isLoading={isLoading}
      style={{ backgroundColor: "rgba(99, 102, 106, 0.05)" }}
      data-trackable-context="Fund Detail"
      data-trackable-params-fund={info?.fundName}
      onClick={handleClickTracking}
    >
      {!info?.fundInfo ? (
        <Paper className="detail-paper-padding">
          <NoFundInfoError fundId={fundIdFromUrl} />
        </Paper>
      ) : null}
      {info?.fundInfo ? (
        <React.Fragment>
          <Typography variant="h5" gutterBottom>
            {info.fundName}
          </Typography>
          <Paper className="detail-paper-padding">
            <Box className="tab-content-area">
              <Table className="detail-header-table">
                <TableHead>
                  <TableRow>
                    <TableCell className="branded-alt-bg">Fund Name</TableCell>
                    <TableCell className="branded-alt-bg">
                      Share Class
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ fontWeight: 500 }}>
                      {info.fundName}
                    </TableCell>
                    <TableCell>{info.shareClassCcy}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Paper>

          <Paper className="detail-paper-padding">
            {canViewPerformance || canViewHoldings || canViewExposures ? (
              <Tabs
                onChange={(event: ChangeEvent<{}>, newValue: number) => {
                  setTab({
                    index: newValue,
                    name: (event.target as any).innerText,
                  });
                }}
                value={tab.index}
              >
                <Tab label="Information" />

                {/* // TODO: Add this back in when we have a new ESG provider. */}
                {/* {esgReportStatus === "enabled" &&
                info?.fundInfo.owl &&
                info?.fundInfo.owl.coveragePct !== "0.00" ? (
                  <Tab label="ESG" />
                ) : null} */}

                {canViewPerformance && <Tab label="Performance" />}
                {canViewHoldings && <Tab label="Holdings" />}
                {canViewExposures && <Tab label="Exposure" />}
              </Tabs>
            ) : null}

            <div className="tab-content-area">{renderTabs(tab.name)}</div>
          </Paper>
        </React.Fragment>
      ) : null}
    </View>
  );
};

export default FundDetail;
