import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { format } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { useAuth } from "@amx/common-frontend";
import {
  AMXDatePicker,
  AMXTextInput,
  AddCircleIcon,
  AMXDialog,
  AMXButton,
  AMXDropzone,
  AMXAutocomplete,
  InfoIcon,
  GearIcon,
} from "@amx/component-library";

import { Box, Typography, FormControl } from "@mui/material";
import { useDocuments } from "../Documents";

import * as styles from "./DocumentUpload.styles";

type DocumentParamater = {
  Format: string;
  Marker: string;
  PrettyName: string;
  Type?: string;
};

type DocumentTemplate = {
  DocumentType: string;
  FileNamePattern: string;
  Parameters: DocumentParamater[];
};

type FundClass = {
  AMXPRODUCT_NAME: string;
  ClassDetails: Array<ClassInfo>;
  FULL_FUND_NAME: string;
  FUND_ID: string;
  FUND_INACTIVE_DATE: string;
  FUND_LAUNCH_DATE: string;
  FUND_SK: number;
  FUND_STATUS: string;
};

type ClassInfo = {
  CLASS_ID: string;
  CLASS_INACTIVE_DATE: string;
  CLASS_LAUNCH_DATE: string;
  CLASS_STATUS: string;
  FUND_CLASS_SK: number;
  SHARE_CLASS_NAME: string;
};

type ClassObject = {
  className: string;
  classId: string;
};

export const DocumentUpload = (props: any) => {
  const { isDialogOpen, handleCloseDialog } = props;
  const { axiosWithAuth } = useAuth();
  const [docTypes, setDocTypes] = useState([]);
  const [paramList, setParamList] = useState<DocumentParamater[]>([]);
  const [fundNames, setFundNames] = useState([]);
  const [fundName, setFundName] = useState("");
  const [fundId, setFundId] = useState("");
  const [classId, setClassId] = useState("");

  const [classNames, setClassNames] = useState<ClassObject[]>([]);

  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [uploadedDoc, setUploadedDoc] = useState<File | null>(null);
  const [currentDocument, setCurrentDocument] =
    useState<DocumentTemplate | null>();
  const [documentType, setDocumentType] = React.useState<string | undefined>(
    undefined
  );

  const { documentHasUploaded, setDocumentHasUploaded } = useDocuments();

  const {
    control,
    clearErrors,
    reset,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({});

  const today = new Date();

  const formatDate = (selectedDate: Date, dateFormat: string) => {
    switch (dateFormat) {
      case "YYYYMMDD":
        return format(new Date(selectedDate), "yyyyMMdd");
      case "DD-MMM-YYYY":
        return format(new Date(selectedDate), "dd-MMM-yyyy");
      case "DDMMYYYY":
        return format(new Date(selectedDate), "ddMMyyyy");
      case "YYYYMMDDhhmmss":
        return format(new Date(selectedDate), "yyyyMMddHHmmss");
      default:
        return format(new Date(selectedDate), "yyyyMMdd");
    }
  };

  const { data: documentData } = useQuery(
    ["documentTemplates"],
    async () => {
      if (axiosWithAuth === undefined) return;
      const response = await axiosWithAuth({
        url: "/documentTemplates",
      });

      setDocTypes(
        response.response?.DocumentFileNameTemplates.flatMap(
          (x: DocumentTemplate) => [x.DocumentType]
        )
      );
      return response.response?.DocumentFileNameTemplates;
    },
    { refetchOnWindowFocus: false }
  );

  const { data: fundClasses, refetch: reFetchFunds } = useQuery(
    ["fundClasses"],
    async () => {
      if (axiosWithAuth === undefined) return;
      const response = await axiosWithAuth({
        url: "fundClasses",
      });
      setFundNames(
        response.data?.flatMap((x: FundClass) => [x.FULL_FUND_NAME])
      );

      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const resetForm = () => {
    setCurrentDocument(null);
    setDocumentType(undefined);
    setUploadedDoc(null);
    setClassId("");
    setFundId("");
    setFundName("");
    setFundNames([]);
    reset({});
  };

  const onDocumentTypeChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | null,
    reason: string
  ) => {
    if (value) setDocumentType(value);
    if (reason === "clear") {
      resetForm();
      return;
    }
    // reset form
    reset({});
    reFetchFunds();
  };

  const onFundChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | null,
    reason: string
  ) => {
    if (!value || value === "") {
      setFundId("");
      setClassId("");
      setClassNames([]);
      setFundName("");
      setValue("FULL_FUND_NAME", "");
      setValue("FUND_ID", "");
      return;
    }

    const fund = fundClasses.filter(
      (obj: FundClass) => obj.FULL_FUND_NAME === value
    );

    const fundId = fund[0].FUND_ID;
    setFundId(fundId);
    setFundName(value);
    setClassId("");
    setValue("FUND_ID", fundId);
    setValue("FULL_FUND_NAME", value, {
      shouldValidate: true,
      shouldDirty: true,
    });

    // get classes for selected fund
    const fClass = fundClasses?.find(
      (fund: FundClass) => fund.FULL_FUND_NAME === value
    );

    setClassNames(
      fClass?.ClassDetails.flatMap((x: ClassInfo) => [
        {
          className: x.SHARE_CLASS_NAME,
          classId: x.CLASS_ID.replaceAll("/", ""),
        },
      ])
    );
  };

  const onClassChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | null,
    reason: string
  ) => {
    if (!value || value === "") {
      setClassId("");
      setValue("CLASS_ID", "");
      return;
    }

    if (reason === "clear") {
      setClassId("");
      return;
    }
    setClassId(value);

    const selectedClassID: ClassObject | undefined = classNames.find(
      (item: ClassObject) => item.className === value
    );

    setValue("CLASS_ID", selectedClassID?.classId, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  useEffect(() => {
    if (documentType) {
      const dataRow = documentData.findIndex(
        (x: DocumentTemplate) => x.DocumentType === documentType
      );
      setCurrentDocument(documentData[dataRow]);
      const params = documentData[dataRow].Parameters;
      setParamList(params);
    }
  }, [documentType, documentData]);

  const handleUpload = async (newFileName: string) => {
    if (axiosWithAuth === undefined) {
      throw new Error("ERROR: Cannot call axiosWithAuth for file upload");
    }
    if (!uploadedDoc) {
      return;
    }
    const fileToString = uploadedDoc.name as String;
    const fileExtension = fileToString.substring(
      fileToString.lastIndexOf(".") + 1
    );

    let formStuff = new FormData();
    formStuff.append("file", uploadedDoc, `${newFileName}.${fileExtension}`);

    setIsUploading(true);

    try {
      const response = await axiosWithAuth({
        url: "/documents/upload",
        method: "POST",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: formStuff,
      });
      if (response !== undefined) {
        setDocumentHasUploaded(true);
        resetForm();
      }
    } catch (error) {
      console.error("Error uploading doc", error);
    }

    setIsUploading(false);
  };

  const onButtonClick = async (formData: any, event: any) => {
    event?.preventDefault();

    const formValues = getValues();
    let newFileName = currentDocument?.FileNamePattern;

    currentDocument?.Parameters.forEach((param: DocumentParamater) => {
      if (param.Type === "DATE") {
        const formattedDate = formatDate(
          formValues[param.Marker],
          param.Format
        );
        newFileName = newFileName?.replace(`%${param.Marker}%`, formattedDate);
      } else {
        newFileName = newFileName?.replace(
          `%${param.Marker}%`,
          formValues[param.Marker]
        );
      }
    });
    handleUpload(newFileName as string);
  };

  const handleSetFiles = (files: any) => {
    if (files.length) {
      setUploadedDoc(files[0]);
      clearErrors("file-uploader");
    } else setUploadedDoc(null);
  };

  const renderActionComponent = () => {
    return (
      <Box sx={styles.DialogFooter}>
        <AMXButton
          sx={styles.UploadButton}
          onClickCallback={handleSubmit(onButtonClick)}
          props={{
            disabled:
              documentType === null ||
              documentType === undefined ||
              isUploading,
          }}
        >
          Done
        </AMXButton>
      </Box>
    );
  };

  const uploadConfirm = (
    <>
      <Box sx={styles.ConfirmDialog}>
        <Box sx={styles.IconContainer}>
          <AddCircleIcon />
        </Box>
        <Typography sx={{ fontSize: "24px" }}>Added to My Documents</Typography>
      </Box>
    </>
  );

  const renderError = (marker: any, index: number) => (
    <Box
      sx={styles.InputContainer}
      key={`${documentType}-${marker}-${index}-"error"`}
    >
      <Typography sx={styles.InputLabel}></Typography>
      <FormControl sx={styles.InlineFormInput}>
        <Box
          sx={{
            ...styles.ErrorContainer,
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              ...styles.ErrorIcon,
              alignItems: "flex-start",
              marginTop: "-2px",
            }}
          >
            <InfoIcon color={styles.errorRed} />
          </Box>
          <Typography
            sx={{
              ...styles.ErrorText,
              fontSize: "14px",
              lineHeight: "20px",
              display: "block",
            }}
          >
            Required
          </Typography>
        </Box>
      </FormControl>
    </Box>
  );

  const renderFormFields = (
    <>
      {paramList.map((param: DocumentParamater, index: number) => {
        if (param.Marker === "FULL_FUND_NAME") return null;

        if (param.Marker === "FUND_ID") {
          return (
            <>
              <Box
                sx={styles.InputContainer}
                key={`${documentType}-${param.Marker}-${index}`}
              >
                <>
                  <Typography sx={styles.InputLabel}>Fund ID</Typography>
                  <FormControl
                    fullWidth
                    sx={styles.InlineFormInput}
                    defaultValue=""
                  >
                    <AMXTextInput
                      readOnly
                      value={fundId || ""}
                      defaultValue=""
                    />
                  </FormControl>
                </>
              </Box>
              {errors["FULL_FUND_NAME"]
                ? renderError(param.Marker, index)
                : null}
            </>
          );
        }
        if (param.Marker === "CLASS_ID") {
          return (
            <>
              <Box
                sx={styles.InputContainer}
                key={`${documentType}-${param.Marker}-${index}`}
              >
                <>
                  <Typography sx={styles.InputLabel}>Class ID</Typography>
                  <FormControl fullWidth sx={styles.InlineFormInput}>
                    <Controller
                      name="CLASS_ID"
                      control={control}
                      rules={{ required: true }}
                      render={({ field, field: { onChange, value } }) => (
                        <AMXAutocomplete
                          {...field}
                          id="classNameSelect"
                          defaultValue="Select class"
                          value={classId || ""}
                          sx={styles.Autocomplete}
                          onChange={onClassChange}
                          options={
                            classNames.length
                              ? classNames.map((a) => a.className)
                              : []
                          }
                          hasError={!!errors["CLASS_ID"]}
                        />
                      )}
                    />
                  </FormControl>
                </>
              </Box>
              {errors[param.Marker] ? renderError(param.Marker, index) : null}
            </>
          );
        }
        return (
          <>
            <Box
              sx={styles.InputContainer}
              key={`${documentType}-${param.Marker}-${index}`}
            >
              <>
                <Typography sx={styles.InputLabel}>
                  {param.PrettyName}
                </Typography>
                <FormControl fullWidth sx={styles.InlineFormInput}>
                  {param.Type === "DATE" ? (
                    <Controller
                      name={param.Marker}
                      control={control}
                      defaultValue={today}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <AMXDatePicker
                          value={value}
                          onChange={onChange}
                          defaultValue={today}
                          hasError={!!errors[param.Marker]}
                        />
                      )}
                    />
                  ) : (
                    <>
                      <Controller
                        name={param.Marker}
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <FormControl fullWidth sx={styles.InlineFormInput}>
                            <AMXTextInput
                              {...field}
                              hasError={!!errors[param.Marker]}
                            />
                          </FormControl>
                        )}
                      />
                    </>
                  )}
                </FormControl>
              </>
            </Box>
            {errors[param.Marker] ? renderError(param.Marker, index) : null}
          </>
        );
      })}
    </>
  );

  const renderContent = (
    <Box sx={styles.FormContainer}>
      <form onSubmit={handleSubmit(onButtonClick)}>
        <Box sx={styles.InputContainer}>
          <Typography sx={styles.InputLabel}>Document type</Typography>
          <FormControl fullWidth sx={styles.InlineFormInput}>
            {docTypes ? (
              <Controller
                name={"documentType"}
                control={control}
                defaultValue="Select document type"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <AMXAutocomplete
                    id="scheme-type"
                    defaultValue="Select document type"
                    sx={styles.Autocomplete}
                    options={docTypes}
                    onChange={onDocumentTypeChange}
                    hasError={!!errors.documentType}
                    errorMessage="Required"
                  />
                )}
              />
            ) : null}
          </FormControl>
        </Box>
        {paramList.find(
          (e: DocumentParamater) =>
            e.Marker === "FUND_ID" ||
            e.Marker === "CLASS_ID" ||
            e.Marker === "FULL_FUND_NAME"
        ) ? (
          <Box sx={styles.InputContainer}>
            <>
              <Typography sx={styles.InputLabel}>Full Fund Name</Typography>
              <FormControl fullWidth sx={styles.InlineFormInput}>
                <Controller
                  name="FULL_FUND_NAME"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, field: { onChange, value } }) => (
                    <AMXAutocomplete
                      {...field}
                      id="fundNameSelect"
                      defaultValue="Select fund"
                      onChange={onFundChange}
                      value={fundName || ""}
                      sx={styles.Autocomplete}
                      options={fundNames}
                      hasError={!!errors["FULL_FUND_NAME"]}
                    />
                  )}
                />
              </FormControl>
            </>
          </Box>
        ) : null}

        {renderFormFields}

        <Box sx={styles.InputContainer} className="has-upload">
          <Typography sx={styles.InputLabel}>Upload document</Typography>
          <Box sx={styles.DropzoneContainer}>
            <Controller
              name={"file-uploader"}
              control={control}
              rules={{ validate: () => uploadedDoc !== null }}
              render={({ field }) => (
                <AMXDropzone
                  acceptedFileTypes=".doc,.docx,application/pdf,text/plain,"
                  dropzoneElement={
                    <Typography sx={styles.Message}>
                      Drag and drop or <span>upload from computer</span>
                    </Typography>
                  }
                  handleSetFiles={handleSetFiles}
                  useDropzonePreview={true}
                />
              )}
            />
          </Box>
        </Box>
        {errors["file-uploader"] ? (
          <Box sx={styles.InputContainer} className="file-upload-error">
            <Typography sx={styles.InputLabel}></Typography>
            <FormControl sx={styles.InlineFormInput}>
              <Box
                sx={{
                  ...styles.ErrorContainer,
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    ...styles.ErrorIcon,
                    alignItems: "flex-start",
                    marginTop: "-2px",
                  }}
                >
                  <InfoIcon color={styles.errorRed} />
                </Box>
                <Typography
                  sx={{
                    ...styles.ErrorText,
                    fontSize: "14px",
                    lineHeight: "20px",
                    display: "block",
                  }}
                >
                  Upload a valid file
                </Typography>
              </Box>
            </FormControl>
          </Box>
        ) : null}
      </form>
      {isUploading ? (
        <Box sx={styles.UpLoadingContainer}>
          <GearIcon sx={styles.GearIcon} />
        </Box>
      ) : null}
    </Box>
  );

  return (
    <>
      <AMXDialog
        isOpen={isDialogOpen}
        handleCloseDialog={handleCloseDialog}
        dialogActionComponent={
          !documentHasUploaded ? renderActionComponent() : null
        }
        mainTitle={!documentHasUploaded ? "Upload Document" : ""}
        dialogSx={styles.DialogUpload}
      >
        {documentHasUploaded ? uploadConfirm : renderContent}
      </AMXDialog>
    </>
  );
};
