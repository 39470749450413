export const queryStringToDictionary = (queryString: string) =>
  queryString.length > 1
    ? queryString
        .substr(1)
        .split("&")
        .reduce((dict: any, current: string) => {
          const [key, value] = current.split("=");
          return { ...dict, [key]: value !== undefined ? value : "" };
        }, {})
    : {};
